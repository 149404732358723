const dragUpload = (element, fn) => {
  const dropHandler = (e) => {
    const { files } = e.dataTransfer;
    const file = files[0];
    if (files.length <= 0) {
      return;
    }
    if (files[0].type.indexOf('image') === -1) {
      return;
    }
    console.dir(file);
    const URLObject = window.URL || window.webkitURL || window.mozURL;
    const url = URLObject.createObjectURL(file);
    fn(file, url);
  };
  console.log('Drag');
  element.addEventListener('dragenter', (e) => {
    e.preventDefault();
  });
  element.addEventListener('dragleave', (e) => {
    e.preventDefault();
  });
  element.addEventListener('dragover', (e) => {
    e.preventDefault();
  });
  element.addEventListener('dragover', (e) => {
    e.preventDefault();
  });
  element.addEventListener('drop', (e) => {
    e.preventDefault();
    dropHandler(e);
  });
};
export default dragUpload;

export default {
  data() {
    return {
      chart_color_list: [
        'rgba(116, 129, 244, 1)',
        'rgba(51, 182, 149, 1)',
        'rgba(38, 171, 238, 1)',
        'rgba(250, 119, 146, 1)',
        'rgba(247, 153, 21, 1)',
      ],
      chart_tooltip_color_list: [
        'rgba(75, 92, 240, 1)',
        'rgba(33, 154, 123, 1)',
        'rgba(8, 143, 209, 1)',
        'rgba(244, 72, 108, 1)',
        'rgba(225, 118, 10, 1)',
      ],
      chart_mark_color_list: [
        'rgba(75, 92, 240, 1)',
        'rgba(33, 154, 123, 1)',
        'rgba(8, 143, 209, 1)',
        'rgba(244, 72, 108, 1)',
        'rgba(225, 118, 10, 1)',
      ],
      chart_area_color: 'rgba(116, 129, 244, 0.2)',
    };
  },
};

<template>
  <div class="jump"></div>
</template>

<script>
export default {
  name: 'jump',
  created() {
    const { targetPath } = this.$route.query;
    this.$router.push(targetPath);
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log('jump');
  //   const targetPath = from.path;
  //   next(targetPath);
  // },
};
</script>

<style scoped>

</style>

const ConfigMenu = () => import('@/views/home/sidebar/menu/ConfigMenu.vue');

export default [
  {
    path: '/config/project',
    component: () => import('@/views/home/Home.vue'),
    redirect: '/config/project/list',
    name: 'projectConfig',
    meta: {
      title: '项目设置',
    },
    children: [
      {
        path: 'list',
        name: 'projectList',
        components: {
          default: () => import('@/views/home/content/config/ProjectList.vue'),
          sidebar: ConfigMenu,
        },
        meta: {
          title: '项目列表',
        },
      },
      // {
      //   path: 'events',
      //   name: 'eventsConfig',
      //   components: {
      //     default: () => import('@/views/home/content/config/ProjectList'),
      //     sidebar: ConfigMenu,
      //   },
      //   meta: {
      //     title: '事件管理',
      //   },
      // },
      {
        path: 'create',
        name: 'projectCreate',
        components: {
          default: () => import('@/views/home/content/config/ProjectCreate.vue'),
          sidebar: ConfigMenu,
        },
        meta: {
          hidden: true,
        },
      },
    ],
  },
  // {
  //   path: '/config/user',
  //   component: () => import('@/views/home/Home.vue'),
  //   redirect: '/config/user/info',
  //   name: 'userConfig',
  //   meta: {
  //     title: '用户设置',
  //   },
  //   children: [
  //     {
  //       path: 'info',
  //       name: 'userInfo',
  //       components: {
  //         default: () => import('@/views/home/content/config/ProjectList'),
  //         sidebar: ConfigMenu,
  //       },
  //       meta: {
  //         title: '用户信息',
  //       },
  //     },
  //   ],
  // },
];

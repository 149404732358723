import adsURL from './ads/urls';

export default {
  loginURL: '/users/login',
  getProjectIDListURL: '/projects/id',
  getProjectListURL: '/projects',
  createProjectURL: '/projects',
  modifyProjectURL: '/projects/{project_id}',
  searchProjectURL: '/projects',
  deleteProjectURL: '/projects/{project_id}',
  getChartNameListURL: '',
  getOverviewChartDataURL: '/projects/{project_id}/report/overview/chart/{chart_name}',
  getOverviewTableDataURL: '/projects/{project_id}/report/overview/table',
  getKeepCountryListURL: '/projects/{project_id}/analytic/countries',
  getNetworkListURL: '/projects/{project_id}/analytic/networks',
  getCampaignListURL: '/projects/{project_id}/analytic/campaigns',
  getKeepDataURL: '/projects/{project_id}/report/retention',
  getKeepDetailDataURL: '/projects/{project_id}/report/retention/detail',
  getUninstallRateDataURL: '/projects/{project_id}/report/uninstall',
  getUninstallRateDetailDataURL: '/projects/{project_id}/report/uninstall/detail',
  getRevenueCountryListURL: '/projects/{project_id}/report/sales/countries',
  getRevenueDataURL: '/projects/{project_id}/report/sales/revenue',
  getRevenuePaymentDataURL: '/projects/{project_id}/report/sales/payment',
  getRevenueConversionRateDataURL: '/projects/{project_id}/report/sales/conversion_rate',
  // ABTest CURD
  getABTestListURL: '/projects/{project_id}/report/experiments',
  createABTestURL: '/projects/{project_id}/report/experiments',
  terminateABTestURL: '/projects/{project_id}/report/experiments/{experiment_id}',
  deleteABTestURL: '/projects/{project_id}/report/experiments/{experiment_id}',
  // ABTestOverview
  getABTestOverviewResultURL: '/projects/{project_id}/report/experiments/{experiment_id}/result',
  getABTestOverviewRetentionURL: '/projects/{project_id}/report/experiments/{experiment_id}/retention',
  getABTestOverviewUninstallRateURL: '/projects/{project_id}/report/experiments/{experiment_id}/uninstall_rate',
  getABTestOverviewInAppPurchaseURL: '/projects/{project_id}/report/experiments/{experiment_id}/sale',
  // ABTestDetail
  getABTestRetentionDetailURL: '/projects/{project_id}/report/experiments/{experiment_id}/retention/detail',
  getABTestUninstallDetailURL: '/projects/{project_id}/report/experiments/{experiment_id}/uninstall_rate/detail',
  getABTestInAppPurchaseDetailURL: '/projects/{project_id}/report/experiments/{experiment_id}/sale/detail',
  // Mark
  createMarkURL: '/projects/{project_id}/operation_marks',
  getMarkListURL: '/projects/{project_id}/operation_marks',
  editMarkURL: '/projects/{project_id}/operation_mark/{mark_id}',
  deleteMarkURL: '/projects/{project_id}/operation_mark/{mark_id}',
  // Config
  getConfigProjectsURL: '/config/projects',
  createConfigProjectURL: '/config/projects',
  updateAnalysisConfigURL: '/config/projects/{project_id}/analysis',
  updateGooglePlayConfigURL: '/config/projects/{project_id}/google_play',
  // AdsOverview
  ...adsURL,
};

<template>
  <div class="ab-test-overview-chart" ref="ab-test-overview-chart-ref">
    <h3 class="chart-name">{{ chart_data.name }}</h3>
    <img class="chart-option"
         src="../assets/image/ic_option.png"
         alt="Option"
         @click="showEditBox">
    <div class="result-container" v-show="result !== 2">
      <div class="chart-title-box" v-if="!is_null">
        <Tooltip :content="chart_data.result" placement="top" :delay="1000">
          <h2 class="chart-title">{{ chart_data.result }}</h2>
        </Tooltip>
      </div>
      <div class="chart" v-if="!is_null" :ref='chart_ref'></div>
      <div class="jump-box" @click="jumpToDetail">
        <span class="jump-text">查看详情</span>
        <img class="jump-img"
             src="../assets/image/ic_return@2x.png"
             alt="Jump">
      </div>
    </div>
    <div class="empty-result-container" v-if="result === 2">
      <Icon class="empty-icon" size="54" type="ios-folder-open-outline" color="#e1e1e1"/>
      <p class="empty-text">数据不足</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import getLineChartOption from '../utils/getLineChartOption';

export default {
  name: 'ABTestOverviewChart',
  props: {
    chart_data: Object,
    result: {
      type: Number,
      default: 2,
    },
    data_type: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {};
  },
  methods: {
    showEditBox() {
    },
    drawLineChart() {
      const chartElement = this.$refs[this.chart_ref];
      const chart = this.$echarts.init(chartElement);
      const chartData = this.chart_data;
      const option = getLineChartOption(chartData, this.chart_configuration);
      chart.setOption(option);
    },
    jumpToDetail() {
      this.$emit('jump-to-detail');
    },
  },
  computed: {
    is_null() {
      return this.$tools.isNullObj(this.chart_data.x_axis);
    },
    chart_ref() {
      return `chart-${this.chart_data.id}`;
    },
    chart_configuration() {
      return {
        x: {
          hideMaxLabel: true,
        },
        y: {
          margin: 8,
          splitLine: false,
          formatter: this.data_type,
          multiple: true,
        },
        series: {
          formatter: this.data_type,
          lineStyle: {
            width: 2,
          },
        },
        grid: {
          left: 36,
          top: 36,
          right: 12,
          bottom: 32,
        },
      };
    },
  },
  watch: {
    'chart_data.x_axis': function (newVal) {
      console.log('x_axis');
      console.dir(newVal);
      if (newVal) {
        this.$nextTick(() => {
          this.drawLineChart();
        });
      }
    },
  },
};
</script>
<style lang="less">
  @import url('../style/abTestOverviewTooltip.less');
</style>
<style scoped lang="less">
  @componentPadding: 24px;
  .chart-title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .chart-name {
    font-size: 14px;
    font-weight: 400;
    color: #545878;
    line-height: 20px;
  }
  .chart-option {
    position: absolute;
    width: 16px;
    top: 24px;
    right: 24px;
  }
  .ab-test-overview-chart {
    position: relative;
    width: 100%;
    height: 100%;
    padding: @componentPadding;
    .chart-name {
      margin-bottom: 16px;
      .chart-name();
    }
    .chart-option {
      .chart-option();
    }
    .result-container {
      height: calc(~'100% - 24px');
      .chart-title-box {
        display: flex;
        align-items: center;
        .ivu-tooltip {
          width: 100%;
        }
        .chart-title {
          width: calc(~'100% - 30px');
          .chart-title();
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .chart {
        width: 100%;
        height: calc(~'100% - 24px');
      }
      .jump-box {
        position: absolute;
        bottom: 8px;
        right: 10px;
        line-height: 24px;
        cursor: pointer;
        .jump-text, .jump-img {
          vertical-align: middle;
        }
        .jump-text {
          margin-right: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #282a3a;
        }
        .jump-img {
          width: 24px;
          height: 24px;
          transform: rotate(180deg);
          transition: transform 0.2s ease-in-out;
        }
        &:hover {
          .jump-img {
            transform: translateX(4px) rotate(180deg);
          }
        }
      }
    }
    .empty-result-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .empty-icon {
        margin-bottom: 12px;
      }
      .empty-text {
        font-size: 18px;
        font-weight: 400;
        color: #c1c1c1;
      }
    }
  }
</style>

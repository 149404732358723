export default {
  // Filter
  getAdsPlatformListURL: '/ads/networks/{project_id}',
  getAdsGroupListURL: '/ads/campaigns/{project_id}',
  getAdsPlanListURL: '/ads/ad_groups/{project_id}',
  // AdsOverview
  getAdsOverviewTableDataURL: '/ads/overview_report/total_table/{project_id}',
  getAdsProfitTableDataURL: '/ads/overview_report/profit_table/{project_id}',
  getAdsProfitLineDataURL: '/ads/overview_report/profit_line/{project_id}',
  getAdsRevenueCostLineDataURL: '/ads/overview_report/revenue_cost_line/{project_id}',
  getAdsRetentionLineDataURL: '/ads/overview_report/retention_d1_line/{project_id}',
  getAdsAnalysisDauLineDataURL: '/ads/overview_report/analysis_dau_line/{project_id}',
  // ROI
  getAdsROITableDataURL: '/ads/roi_report/total_table/{project_id}',
  // Retention
  getAdsRetentionDataURL: '/ads/retention/total_table/{project_id}',
  // Revenue
  getAdsRevenueTableDataURL: '/ads/revenue/total_table/{project_id}',
  getAdsRevenueBarDataURL: '/ads/revenue/revenue_column/{project_id}',
  getAdsAdsRevenueBarDataURL: '/ads/revenue/ads_revenue_column/{project_id}',
  getAdsSaleRevenueBarDataURL: '/ads/overview_report/sale_revenue_column/{project_id}',
  getAdsRevenueSaleLineDataURL: '/ads/overview_report/revenue_sale_ads_line/{project_id}',
  getAdsRevenueDistributePieDataURL: '/ads/overview_report/revenue_distribute_pie/{project_id}',
};

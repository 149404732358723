<template>
  <Dropdown class="mark" trigger="click" placement="bottom-end">
    <Icon type="ios-create-outline" size="24" color="#acaecd"/>
    <DropdownMenu slot="list">
      <DropdownItem>
        <div class="item" @click="createMark">
          <Icon type="ios-add" size="20"/>
          <span class="item-text">添加标注</span>
        </div>
      </DropdownItem>
      <DropdownItem>
        <div class="item" @click="editAll">
          <Icon type="ios-create-outline" size="20"/>
          <span class="item-text">编辑所有</span>
        </div>
      </DropdownItem>
    </DropdownMenu>
    <CreateMarkModal
      :modal_visible="create_mark_modal_visible"
      @on-create="createMarkSucceedHandler"
      @on-cancel="createMarkCancelHandler"></CreateMarkModal>
    <EditMarkModal
      :modal_visible="edit_all_modal_visible"
      @on-modify="editAllModifySucceedHandler"
      @on-delete="editAllDeleteSucceedHandler"
      @on-cancel="editAllCancelHandler"></EditMarkModal>
  </Dropdown>
</template>

<script>
import CreateMarkModal from './modal/CreateMarkModal.vue';
import EditMarkModal from './modal/EditMarkModal.vue';

export default {
  name: 'Mark',
  components: { EditMarkModal, CreateMarkModal },
  data() {
    return {
      create_mark_modal_visible: false,
      edit_all_modal_visible: false,
    };
  },
  methods: {
    createMark() {
      this.create_mark_modal_visible = true;
    },
    editAll() {
      this.edit_all_modal_visible = true;
    },
    createMarkSucceedHandler() {
      this.create_mark_modal_visible = false;
      this.$emit('on-create-succeed');
    },
    createMarkCancelHandler() {
      console.log('Mark canceled');
      this.create_mark_modal_visible = false;
    },
    editAllModifySucceedHandler() {
      // this.edit_all_modal_visible = false;
      this.$emit('on-edit-succeed');
    },
    editAllDeleteSucceedHandler() {
      // this.edit_all_modal_visible = false;
      this.$emit('on-delete-succeed');
    },
    editAllCancelHandler() {
      console.log('Edit all canceled');
      this.edit_all_modal_visible = false;
    },
  },
};
</script>

<style scoped lang="less">
  .mark {
    .ivu-dropdown-item {
      padding: 0;
      .item {
        padding: 7px 16px;
      }
    }
    .item-text {
      margin-left: 4px;
    }
  }
</style>

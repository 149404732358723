<template>
  <div class="project-keep">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title"
        @on-mark-create-succeed="markListRefreshHandler(true, null, keep_chart_data)"
        @on-mark-edit-succeed="markListRefreshHandler(true, null, keep_chart_data)"
        @on-mark-delete-succeed="
        markListRefreshHandler(true, null, keep_chart_data)
      "></content-header>
    </div>
    <div class="filter-box-wrap">
      <filter-box
        :button_loading="button_loading"
        :filter="filter"
        :country_list="country_list"
        :network_list="network_list"
        :campaign_list="campaign_list"
        @search="search"></filter-box>
    </div>
    <div class="chart-wrap">
      <h2 class="title">{{ chart_title }}</h2>
      <div class="chart" :ref="chart_ref"></div>
    </div>
    <div class="table-wrap">
      <div class="table-header">
        <h2 class="title">{{ table_title }}</h2>
        <table-operation :columns="xlsx_columns" :table_data="xlsx_table_data"></table-operation>
      </div>
      <div class="heat-map-filter-box-wrap">
        <heat-map-filter-box
          :filter="table_filter"
          :dimension_disabled="dimension_disabled"
          @on-dimension-change="dimensionChangeHandler"
          @on-show-number-change="showNumberChangeHandler"></heat-map-filter-box>
      </div>
      <div class="table" ref="table">
        <Table ref="keep_table"
               :columns="columns"
               :data="table_data"
               :row-class-name="rowsClassName"
               @on-cell-click="rowExpand"></Table>
      </div>
      <div class="page-box">
        <Page :total="total"
              :current="page_num"
              :page-size="page_size"
              @on-change="pageNumChange"
              @on-page-size-change="pageSizeChange"
              size="small" show-elevator show-sizer></Page>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '../../../components/ContentHeader.vue';
import routerTriggerMixin from './routerTriggerMixin';
import FilterBox from '../../../components/FilterBox.vue';
import HeatMapFilterBox from '../../../components/HeatMapFilterBox.vue';
import keepAndUninstallRateMixin from './Keep&UninstallRateMixin';
import markMixin from './markMixin';
import TableOperation from '../../../components/TableOperation.vue';

export default {
  name: 'ProjectKeep',
  components: {
    TableOperation,
    HeatMapFilterBox,
    FilterBox,
    ContentHeader,
  },
  data() {
    return {
      chart_data_name: 'keep_chart_data',
      chart_ref: 'keep-chart',
      header_title: '留存',
      chart_title: '留存趋势图',
      table_title: '留存热力图',
      table_cell_name_list: ['retention', 'retention_rate'],
      button_loading: false,
      country_list: [],
      network_list: [
        {
          network: '',
        },
      ],
      campaign_list: [
        {
          campaign: '',
        },
      ],
      filter: {
        time: [null, null],
        country: [],
        country_list_exclude: 0,
        network: [],
        campaign: [],
        dimension: 'network',
      },
      table_filter: {
        dimension: 'network',
        show_number: false,
      },
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      keep_chart_data: {
        name: '留存趋势',
        color: 'rgba(81, 145, 255, 1)',
        area_color: 'rgba(116, 129, 244, 0.2)',
        tooltip_color: 'rgba(75, 92, 240, 1)',
        mark_color: 'rgba(75, 92, 240, 1)',
        x_axis: [],
        y_axis: [],
        mark_lines: [],
      },
      total_key: 'new_users',
      expanded_rows: new Set(),
      expanded_rows_length_map: new Map(),
      expanded_loading_instance_map: new Map(),
    };
  },
  mixins: [routerTriggerMixin, keepAndUninstallRateMixin, markMixin],
  methods: {
    getChartElement() {
      return this.$refs[this.chart_ref];
    },
    getChartParent() {
      return this.$refs[this.chart_ref];
    },
    getTableParent() {
      return this.$refs.table;
    },
    fetchData() {
      this.fetchKeepData();
      this.fetchFilterList();
    },
    fetchKeepDataCallback(response) {
      const {
        header,
        result,
        // eslint-disable-next-line camelcase
        retention_reports,
        total,
      } = response.data;
      if (result === 'success') {
        if (this.columns.length > 1) {
          this.columns = [this.columns[0]];
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < header.length + 1; i++) {
          let obj;
          if (i === 1) {
            obj = {
              key: header[i - 1],
              title: '日期',
              width: 120,
              align: 'left',
              fixed: 'left',
            };
          } else if (i === 2) {
            obj = {
              key: header[i - 1],
              title: '新增用户',
              width: 96,
              align: 'left',
              fixed: 'left',
            };
          } else {
            obj = {
              key: header[i - 1],
              title: header[i - 1],
              minWidth: 96,
              align: 'center',
            };
          }
          this.columns.push(obj);
          const render = this.columnRender;
          this.columns.forEach((val) => {
            this.$set(val, 'render', render);
          });
        }
        retention_reports.forEach((val) => {
          // eslint-disable-next-line no-param-reassign
          val.empty = '.';
          // if (!this.table_filter.show_number) {
          //   this.$tools.formatChartData(val, 'retention_rate');
          // }
        });
        // Chart data can be changed, but table data can't.
        const tempObj = { ...retention_reports[0] };
        this.$tools.formatChartData(tempObj, 'retention_rate');
        const chartData = { ...tempObj };
        chartData['0'] = 1;
        delete chartData.date;
        delete chartData.empty;
        delete chartData.new_users;
        this.keep_chart_data.x_axis = Object.keys(chartData);
        this.keep_chart_data.y_axis = Object.values(chartData);
        if (this.table_filter.show_number) {
          this.keep_chart_data.y_axis = Object.values(chartData).map((val) => {
            const number = val.retention_rate;
            if (number < 0) {
              return 0;
            }
            return number;
          });
        } else {
          this.keep_chart_data.y_axis = Object.values(chartData).map((number) => {
            if (number < 0) {
              return 0;
            }
            return number;
          });
        }
        // eslint-disable-next-line camelcase
        this.table_data = [...retention_reports];
        this.total = total;
        this.configTable();
      }
    },
    /**
     * Fetch keep data
     * (Need modification)
     */
    async fetchKeepData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        country_list: this.filter.country,
        country_list_exclude: this.filter.country_list_exclude,
        network_list: this.filter.network,
        campaign_list: this.filter.campaign,
        page_size: this.page_size,
        page_num: this.page_num,
      };
      const instance1 = this.$loading(this.getChartParent());
      const instance2 = this.$loading(this.getTableParent());
      instance1.show();
      instance2.show();
      try {
        const keepDataResponse = await this.$api.getKeepData(this.project_id, params);
        this.fetchKeepDataCallback(keepDataResponse);
        this.refreshExpandedRows();
        await this.markListRefreshHandler(true, null, this.keep_chart_data);
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
      }
    },
    fetchKeepDetailData(dateStart, cb) {
      const params = {
        date_start: dateStart,
        network_list: this.filter.network,
        country_list: this.filter.country,
        country_list_exclude: this.filter.country_list_exclude,
        campaign_list: this.filter.campaign,
        divide_dimension: this.table_filter.dimension,
        page_size: this.page_size,
        page_num: this.page_num,
      };
      // const instance = this.$loading(this.getTableParent());
      // instance.show();
      this.$api.getKeepDetailData(this.project_id, params)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { result, retention_reports } = response.data;
          if (result === 'success') {
            return Promise.resolve(retention_reports);
          }
          return Promise.reject();
        })
        .then((reports) => {
          if (reports) {
            cb(reports);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          // instance.hide();
        });
    },
    rowExpand(row, column) {
      const { expanded, date } = row;
      const { key } = column;
      const idKey = 'date';
      if (expanded !== undefined) {
        // eslint-disable-next-line no-underscore-dangle
        const index = row._index;
        // Regard date as id
        const id = this.table_data[index][idKey];
        if (key === 'empty') {
          if (!expanded) {
            // Expandable
            this.table_data[index].expanded = true;
            this.expanded_rows.add(id);
            this.updateTableLoadingInstancePosition(index, 2);
            // Create loading row
            const tempObj = {};
            this.columns.forEach((val) => {
              // eslint-disable-next-line no-shadow
              const { key } = val;
              const numberKey = Number(key);
              let temp = null;
              if (Number.isNaN(numberKey)) {
                temp = '';
              } else {
                temp = {
                  retention: -1,
                  retention_rate: -1,
                };
              }
              tempObj[key] = temp;
            });
            const rowsData = [{ ...tempObj }, { ...tempObj }];
            this.table_data.splice(index + 1, 0, ...rowsData);
            let instance = null;
            this.$nextTick(() => {
              // After dom is updated
              const tableElement = this.$refs.table;
              console.log(tableElement);
              const selector = `.ivu-table-body .ivu-table-row:nth-child(${index + 1})`;
              const rowElement = document.querySelector(selector);
              const width = tableElement.clientWidth;
              const height = rowElement.clientHeight;
              const top = rowElement.offsetTop + height;
              instance = this.$loading(tableElement, '#f5f5f8');
              const instanceElement = instance.element();
              instanceElement.style.top = `${top + 48}px`;
              instanceElement.style.height = `${48 * 2}px`;
              instanceElement.style.width = `${width}px`;
              instance.show();
              this.expanded_loading_instance_map.set(instanceElement, index);
              this.expanded_loading_instance_map = new Map(this.expanded_loading_instance_map);
            });
            // Fetch detail data
            const callback = (reports) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const item of reports) {
              // eslint-disable-next-line no-restricted-syntax
                for (const k of Object.keys(item)) {
                  // const numberKey = Number(k);
                  // if (!Number.isNaN(numberKey)) {
                  //   item[k] = item[k].retention_rate;
                  // }
                  if (k === this.table_filter.dimension) {
                    item.date = item[k];
                  }
                  item.empty = '';
                }
              }
              this.rows_data = [...reports];
              instance.hide();
              this.expanded_loading_instance_map.delete(instance.element());
              this.expanded_loading_instance_map = new Map(this.expanded_loading_instance_map);
              const currentIndex = this.getTableDataIndexById(id);
              this.expanded_rows_length_map.set(id, this.rows_data.length);
              // Delete the loading row before inserting new rows into the table
              this.table_data.splice(currentIndex + 1, 2, ...this.rows_data);
              this.configExpandedCellBg(currentIndex + 1, this.rows_data.length);
              // eslint-disable-next-line no-param-reassign
              this.table_data[currentIndex].expanded = true;
              this.updateTableLoadingInstancePosition(index, this.rows_data.length - 2);
            };
            this.fetchKeepDetailData(date, callback);
          } else {
            // Not expandable
            const length = this.expanded_rows_length_map.get(id);
            console.log(`Expanded length: ${length}`);
            this.expanded_rows.delete(id);
            this.expanded_rows_length_map.delete(id);
            this.table_data.splice(index + 1, length);
            // eslint-disable-next-line no-param-reassign
            this.table_data[index].expanded = false;
          }
        }
      }
    },
    search() {
      this.button_loading = true;
      this.fetchKeepData();
    },
    pageNumChange(pageNum) {
      this.page_num = pageNum;
      this.fetchKeepData();
    },
    pageSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchKeepData();
    },
  },
  computed: {
    dimension_disabled() {
      return this.expanded_loading_instance_map.size !== 0;
    },
    chart_configuration() {
      return {
        y: {
          formatter: 'rate',
        },
        series: {
          formatter: 'rate',
        },
        grid: {
          top: '12px',
          bottom: '18px',
          left: '42px',
          right: '12px',
        },
      };
    },
    xlsx_columns() {
      const columns = [...this.columns];
      columns.shift();
      return columns;
    },
    xlsx_table_data() {
      const propertyName = this.table_cell_name_list[1];
      const tableData = JSON.parse(JSON.stringify(this.table_data));
      tableData.forEach((item) => {
        const arr = Object.keys(item);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of arr) {
          const number = Number(key);
          if (!Number.isNaN(number)) {
            // eslint-disable-next-line no-param-reassign
            item[key] = item[key][propertyName];
          }
        }
      });
      return tableData;
    },
  },
  mounted() {
    this.fetchKeepData();
  },
};
</script>
<style lang="less">
  @import '../../../style/gradientTable.less';
  @import '../../../style/input.less';
  .project-keep {
    .button-box {
      .search-button {
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style lang="less" scoped>
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @marginSide: 24px;
  @contentHeaderWrapMargin: @marginSide;
  @filterBoxWrapMargin: 0 @marginSide 20px;
  @wrapMargin: 0 @marginSide @marginSide;
  @wrapPadding: 20px 24px;
  @wrapWidth: calc(~'100% - 48px');
  @wrapHeight: 396px;
  @wrapBgColor: #ffffff;
  @wrapBorderRadius: @borderRadius;
  @wrapBoxSizing: border-box;
  @pageHeight: 48px;
  @titleMargin: 0 0 20px 0;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .project-keep {
    .content-header-wrap {
      margin:@contentHeaderWrapMargin;
    }
    .title {
      /* BFC */
      display: inline-block;
      width: 100%;
      margin: @titleMargin;
      .h2Title();
    }
    .filter-box-wrap {
      margin: @filterBoxWrapMargin;
      padding: @filterBoxWrapPadding;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #EAEBF7;
      .filter-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .select-box {
          width: 200px;
          margin: 0 1% 12px 0;
        }
        .button-box {
        }
      }
      .filter-box-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .select-box {
          width: 200px;
        }
      }
    }
    .chart-wrap {
      width: @wrapWidth;
      height: @wrapHeight;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @wrapBgColor;
      border: @boxBorder;
      border-radius: @wrapBorderRadius;
      box-sizing: @wrapBoxSizing;
      .chart {
        position: relative;
        width: 100%;
        height: calc(~'100% - 62px');
      }
    }
    .table-wrap {
      width: @wrapWidth;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @wrapBgColor;
      border: @boxBorder;
      border-radius: @wrapBorderRadius;
      box-sizing: @wrapBoxSizing;
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title {
          width: auto;
          margin: 0;
          line-height: 22px;
        }
      }
      .table {
        position: relative;
      }
      .page-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        height: @pageHeight;
      }
    }
  }
</style>

<template>
  <div class="mark-input-box">
    <div class="input-item">
      <p class="input-title">标注日期</p>
      <DatePicker
        class="input-date"
        type="date"
        placeholder="请选择日期"
        v-model="mark.date"></DatePicker>
    </div>
    <div class="input-item">
      <p class="input-title">标注类别</p>
      <Select
        class="input-select"
        v-model="mark.type"
        placeholder="请选择类别">
        <Option
          v-for="item in mark_types"
          :value="item.value"
          :key="item.value">
          {{ item.label }}
        </Option>
      </Select>
    </div>
    <div class="input-item">
      <p class="input-title">所有人可见</p>
      <i-switch class="input-switch" v-model="mark.visible_for_everyone" size="large"/>
    </div>
    <div class="input-item">
      <p class="input-title">标注内容</p>
      <Input class="input-textarea"
             type="textarea"
             placeholder="请填写内容"
             :rows="3"
             v-model="mark.content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarkInput',
  props: {
    mark: Object,
  },
  data() {
    return {
      mark_types: [
        {
          value: 'version_update',
          label: '版本更新',
        },
        {
          value: 'advertising_monetization',
          label: '广告变现',
        },
        {
          value: 'purchase_amount',
          label: '买量',
        },
        {
          value: 'others',
          label: '其它',
        },
      ],
    };
  },
};
</script>
<style lang="less">
  @inputHeight: 36px;
  .mark-input-box {
    .input-item {
      .input-date {
        .ivu-input {
          height: @inputHeight;
        }
        .ivu-icon {
          line-height: @inputHeight;
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .title {
    font-size: 12px;
    font-weight: 400;
    color: #545778;
    line-height: 17px;
  }
  .mark-input-box {
    .input-item {
      .input-title {
        .title();
        margin-bottom: 6px;
      }
      .input-text, .input-date, .input-select, .input-textarea, .input-switch {
        margin-bottom: 25px;
      }
      .input-date, .input-select {
        width: 240px;
      }
    }
  }
</style>

<template>
  <Modal
      class="confirm-modal"
      :value="modal_visible"
      :closable="false"
      width="400"
      @on-ok="okHandler"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title"></h1>
    </div>
    <h1 class="confirm-message">
      <img class="confirm-icon" src="../../assets/svg/exclamation-circle-solid.svg" alt="Warning">
      <span>{{ confirm_message }}</span>
    </h1>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    modal_visible: Boolean,
    confirm_message: String,
    button_loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    okHandler() {
      this.$emit('confirm');
      this.$emit('cancel');
    },
    cancelHandler() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="less" scoped>
  @paddingSide: 32px;
  @headerVerticalPadding: 20px;
  @headerPadding: @headerVerticalPadding @paddingSide;
  @footerVerticalPadding: 24px;
  @footerPadding: @footerVerticalPadding @paddingSide;
  @confirmMessageLineHeight: 24px;
  .message {
    font-size: 18px;
    font-weight: 400;
    color: #202444;
    line-height: 24px;
  }
  .confirm-modal {
    .header {
      padding: @headerPadding;
    }
    .confirm-message {
      padding: 0 @paddingSide;
      line-height: @confirmMessageLineHeight;
      span, img {
        vertical-align: middle;
      }
      span {
        .message();
      }
      img {
        width: 21px;
        margin-right: 12px;
      }
    }
    .footer {
      padding: @footerPadding;
    }
  }
</style>

<template>
  <div class="ads-retention">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title">
        <div></div>
      </content-header>
    </div>
    <div class="filter-box-wrap">
      <r-o-i-filter-box
        :button_loading="button_loading"
        :filter="filter"
        :platform_list="platform_list"
        :group_list="group_list"
        :plan_list="plan_list"
        @search="search"/>
    </div>
    <div class="content-box">
      <div class="chart-wrap">
        <h2 class="title">{{ retention_chart.title }}</h2>
        <div class="container">
          <div
            class="chart"
            :ref="retention_chart.name"
            v-show="retention_chart.chart_visible"></div>
          <div class="empty-result-container" v-show="!retention_chart.chart_visible">
            <Icon class="empty-icon" size="72" type="ios-folder-open-outline" color="#e1e1e1"/>
            <p class="empty-text">数据不足</p>
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <div class="table-header">
          <h2 class="title">{{ retention_table_title }}</h2>
          <table-operation
            :columns="retention_table_columns"
            :table_data="retention_table_data"></table-operation>
        </div>
        <div class="table-show-number">
          <Checkbox
            v-model="retention_show_number"
            size="large">显示人数</Checkbox>
        </div>
        <div class="table" ref="retention_table">
          <Table
            :columns="retention_table_columns"
            :data="retention_table_data"></Table>
        </div>
        <div class="page-box">
          <Page
            :total="retention_total"
            :current="retention_page_num"
            :page-size="retention_page_size"
            @on-change="retentionPageNumChange"
            @on-page-size-change="retentionPageSizeChange"
            size="small" show-elevator show-sizer/>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '../../../../components/ContentHeader.vue';
import ROIFilterBox from '../../../../components/ROIFilterBox.vue';
import TableOperation from '../../../../components/TableOperation.vue';
import ROIFilterMixin from '../ROIFilterMixin';
import routerTriggerMixin from '../routerTriggerMixin';
import paramNameJSON from './paramName.json';
import getLineChartOption from '../../../../utils/getLineChartOption';
import chartColorMixin from '../mixin/chartColorMixin';

export default {
  name: 'AdsRetention',
  components: { ROIFilterBox, ContentHeader, TableOperation },
  mixins: [routerTriggerMixin, ROIFilterMixin, chartColorMixin],
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    retention_render() {
      return (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        if (value === -1) {
          return h('span', '-');
        } if (key === 'LT') {
          const number = Number(value);
          return h('span', this.$tools.keepDecimals(number));
        } if (this.RetentionFormatCheck(key)) {
          // return h('span', this.$tools.percentageFormat(value));
          const isShowNumber = this.retention_show_number;
          const { amount, rate } = value;
          if (isShowNumber) {
            return h('div', [
              h('p', this.$tools.percentageFormat(rate)),
              h('p', this.$tools.keepDecimals(amount)),
            ]);
          }
          return h('span', this.$tools.percentageFormat(rate));
        }
        return h('span', value);
      };
    },
  },
  data() {
    return {
      header_title: '留存',
      button_loading: false,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
          {
            text: '最近60天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 60);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      retention_show_number: false,
      // Chart
      retention_chart: {
        name: 'retention_line_chart',
        title: '留存折线图',
        param_name: 'retention',
        chart_visible: true,
        chart: null,
      },
      retention_chart_data: [],
      // Table
      retention_table_title: '留存报表',
      retention_table_columns: [],
      retention_table_data: [],
      // Page
      retention_total: 0,
      retention_page_num: 1,
      retention_page_size: 10,
    };
  },
  methods: {
    RetentionFormatCheck(key) {
      const regx = /^retention[0-9]*/g;
      if (regx.test(key)) return true;
      return false;
    },
    RetentionTableFormat(key) {
      if (key === 'ads_cost') {
        return `${paramNameJSON[key]}（${this.currency}）`;
      }
      const regx = /^retention[0-9]*/g;
      if (regx.test(key)) {
        return key.replace(regx, (x) => `Retention ${x.slice(9)}`);
      }
      return paramNameJSON[key];
    },
    fetchData() {
      this.fetchRetentionData();
    },
    fetchRetentionData(page = false) {
      const instance1 = this.$loading(this.$refs.retention_table);
      let instance2;
      if (!page) {
        instance2 = this.$loading(this.$refs.retention_line_chart);
      }
      const promise1 = this.fetchRetentionTableAndChartData()
        .then((response) => {
          // eslint-disable-next-line camelcase
          const avg_retention = response.data?.avg_retention;
          this.tableDataCallback(response.data, 'retention');
          if (!page) {
            this.chartDataCallback(avg_retention);
          }
        })
        .finally(() => {
          this.button_loading = false;
          instance1.hide();
          if (!page) {
            instance2.hide();
          }
        });
      Promise.all([promise1])
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    fetchRetentionTableAndChartData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        page_num: this.retention_page_num,
        page_size: this.retention_page_size,
        network_list: this.filter.platform,
        campaign_list: this.filter.group,
        ad_group_list: this.filter.plan,
        creative_id: this.filter.creative_id,
      };
      return this.$api.getAdsRetentionData(this.project_id, params);
    },
    tableDataCallback(responseData, tableName) {
      if (!responseData) {
        this[`${tableName}_table_columns`] = [];
        this[`${tableName}_table_data`] = [];
        return;
      }
      this[`${tableName}_table_columns`] = [];
      this[`${tableName}_table_data`] = [];
      const columns = this[`${tableName}_table_columns`];
      const data = this[`${tableName}_table_data`];
      const { header, record, total } = responseData;
      this[`${tableName}_total`] = total;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of header) {
        let title = '';
        console.log('key', key);
        if (this.RetentionFormatCheck(key)) {
          title = this.RetentionTableFormat(key);
        } else {
          title = paramNameJSON[key];
        }
        const obj = {
          key,
          title,
          minWidth: 120,
          fixed: key === 'date' ? 'left' : null,
          render: this.retention_render,
        };
        columns.push(obj);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const rowData of record) {
        data.push(rowData);
      }
    },
    // fetchRetentionChartData() {
    //   const params = {
    //     date_start: this.$tools.dateFormat(this.filter.time[0]),
    //     date_end: this.$tools.dateFormat(this.filter.time[1]),
    //   };
    //   return this.$api.getAdsSaleRevenueBarData(this.project_id, params);
    // },
    chartDataCallback(responseData) {
      if (!responseData) {
        // eslint-disable-next-line no-param-reassign
        this.retention_chart.chart_visible = false;
        return;
      }
      // eslint-disable-next-line no-param-reassign
      this.retention_chart.chart_visible = true;
      this.$nextTick(() => {
        console.log('Chart data');
        console.log(responseData);
        this.drawChart(responseData, this.retention_chart);
      });
    },
    drawChart(data, chartItem) {
      const ref = chartItem.name;
      const chartElement = this.$refs[ref];
      if (!chartElement) return;
      const chartData = this.getPackedUpChartData(data, chartItem.param_name);
      const configuration = this.getPackedUpChartConfiguration(data);
      const chartInstance = chartItem.chart;
      if (chartInstance) chartInstance.clear();
      const chart = this.$echarts.init(chartElement);
      const option = getLineChartOption(chartData, configuration);
      chart.setOption(option);
      // eslint-disable-next-line no-param-reassign
      chartItem.chart = chart;
    },
    getPackedUpChartData(data, name) {
      const chartData = {};
      const { type } = data;
      if (type === 'line' || type === 'column') {
        chartData.name = paramNameJSON[name];
        chartData.x_axis = data.x_axis;
        chartData.y_axis = data.y_axis;
        // eslint-disable-next-line prefer-destructuring
        chartData.color = this.chart_color_list[0];
        chartData.area_color = this.chart_area_color;
        // eslint-disable-next-line prefer-destructuring
        chartData.tooltip_color = this.chart_tooltip_color_list[0];
        // eslint-disable-next-line prefer-destructuring
        chartData.mark_color = this.chart_mark_color_list[0];
        return chartData;
      } if (type === 'multi_line' || type === 'multi_column') {
        chartData.x_axis = data.x_axis;
        chartData.y_axis = [];
        chartData.name_list = [];
        chartData.color = [];
        chartData.tooltip_color = [];
        chartData.mark_color = [];
        let count = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of data.dimension) {
          const dimensionData = data[item];
          chartData.color.push(this.chart_color_list[count]);
          chartData.tooltip_color.push(this.chart_tooltip_color_list[count]);
          chartData.mark_color.push(this.chart_mark_color_list[count]);
          chartData.name_list.push(paramNameJSON[item]);
          chartData.y_axis.push(dimensionData);
          count += 1;
        }
        return chartData;
      } if (type === 'pie') {
        chartData.data = data.data || data.record;
        chartData.color = [];
        const { length } = chartData.data;
        for (let i = 0; i < length; i += 1) {
          const currentColor = this.chart_color_list[i % length];
          chartData.color.push(currentColor);
          chartData.data[i].name = paramNameJSON[chartData.data[i].name];
        }
        return chartData;
      }
      return null;
    },
    getPackedUpChartConfiguration(data) {
      const { type, unit } = data;
      const grid = {
        top: '12px',
        right: '12px',
        bottom: '24px',
        left: '40px',
      };
      if (type === 'line' || type === 'column') {
        const configuration = {
          grid,
          x: {
            hideMaxLabel: true,
          },
          y: {
            formatter: unit,
          },
          series: {
            formatter: unit,
          },
        };
        return configuration;
      } if (type === 'multi_line' || type === 'multi_column') {
        grid.top = '36px';
        const configuration = {
          grid,
          x: {
            hideMaxLabel: true,
          },
          y: {
            formatter: unit,
            multiple: true,
          },
          series: {
            formatter: unit,
            lineStyle: {
              width: 1.8,
            },
          },
        };
        return configuration;
      } if (type === 'pie') {
        const configuration = {
          series: {
            formatter: unit,
          },
        };
        return configuration;
      }
      return null;
    },
    changeChartWrapHeight() {
      const chartWrapElements = document.getElementsByClassName('chart-wrap');
      const chartWrapElement = chartWrapElements[0];
      const width = chartWrapElement.clientWidth;
      // eslint-disable-next-line no-restricted-syntax
      for (const element of chartWrapElements) {
        element.style.height = `${width * 0.36}px`;
      }
    },
    search() {
      this.button_loading = true;
      this.retention_page_num = 1;
      this.fetchRetentionData();
    },
    retentionPageNumChange(pageNumber) {
      this.retention_page_num = pageNumber;
      this.fetchRetentionData(true);
    },
    retentionPageSizeChange(pageSize) {
      this.retention_page_size = pageSize;
      this.retention_page_num = 1;
      this.fetchRetentionData(true);
    },
  },
  mounted() {
    this.fetchData();
    this.changeChartWrapHeight();
    window.addEventListener('resize', this.changeChartItemHeight);
  },
  created() {
    this.initTimeFilter();
  },
  destroyed() {
    window.removeEventListener('resize', this.changeChartItemHeight);
  },
};
</script>

<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 7px;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .ads-retention {
    .content-header-wrap {
      margin: 24px;
    }
    .title {
      display: inline-block;
      width: 100%;
      margin: 0 0 20px 0;
      .h2Title();
    }
    .filter-box-wrap {
      margin: 0 24px 20px;
      padding: 24px;
      background: #ffffff;
      border-radius: @borderRadius;
      border: 1px solid #EAEBF7;
    }
    .content-box {
      padding: 0 calc(~'24px - 1%');
      .chart-wrap {
        position: relative;
        width: 98%;
        margin: 0 1% 2%;
        padding: 18px;
        float: left;
        border: 1px solid #EAEBF7;
        border-radius: 7px;
        background-color: #ffffff;
        .container {
          position: relative;
          height: calc(~'100% - 42px');
          .chart {
            height: 100%;
          }
        }
        .empty-result-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .empty-icon {
            margin-bottom: 12px;
          }
          .empty-text {
            font-size: 20px;
            font-weight: 400;
            color: #c1c1c1;
          }
        }
      }
      .table-wrap {
        margin: 0 1% 2%;
        float: left;
      }
      .table-wrap {
        width: 98%;
        padding: 20px 24px;
        background-color: #ffffff;
        border-radius: @borderRadius;
        border: @boxBorder;
        box-sizing: border-box;
        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .title {
            width: auto;
            margin: 0;
            line-height: 22px;
          }
        }
        .table-show-number {
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 6px;
        }
        .table {
          position: relative;
          min-height: 120px;
        }
        .page-box {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          height: 48px;
        }
      }
    }
    .clear {
      clear: both;
    }
  }
</style>

const Mock = require('mockjs');

const { Random } = Mock;

const loginResult = () => {
  const b = Random.boolean();
  const result = b ? 'success' : 'failure';
  return {
    result,
  };
};
Mock.mock('/api/v1/login', 'post', loginResult);

// eslint-disable-next-line no-unused-vars
const projectIdListResult = () => ({
  project_id_list: [
    '1',
    '2',
    '3',
    '123',
    '234',
    '345',
    '456',
  ],
});
// Mock.mock('/api/v1/projects/id', 'get', projectIdListResult);

const projectList = [
  {
    project_id: '1',
    project_name: 'Paintist',
    project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
    total_user_number: 234234,
    total_user_rate: -0.15,
    new_user_number: 543345,
    new_user_rate: 0.12,
  },
  {
    project_id: '2',
    project_name: 'Paintist2',
    project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
    total_user_number: 234234,
    total_user_rate: 0.15,
    new_user_number: 543345,
    new_user_rate: -0.12,
  },
  {
    project_id: '3',
    project_name: 'Paintist3',
    project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
    total_user_number: 234234,
    total_user_rate: 0.15,
    new_user_number: 543345,
    new_user_rate: 0.12,
  },
];
// eslint-disable-next-line no-unused-vars
const projectListResult = () => ({
  project_list: projectList,
});
// Mock.mock('/api/v1/projects/', 'get', projectListResult);

// eslint-disable-next-line no-unused-vars
const createProjectResult = (options) => {
  const body = JSON.parse(options.body);
  console.dir(body);
  const project = {
    ...body,
    project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
    total_user_number: 234234,
    total_user_rate: -0.99,
    new_user_number: 543345,
    new_user_rate: 1.12,
  };
  projectList.push(project);
  return {
    result: 'success',
  };
};
// Mock.mock('/api/v1/projects/', 'post', createProjectResult);

// eslint-disable-next-line no-unused-vars
const modifyProjectResult = (options) => {
  const body = JSON.parse(options.body);
  const item = projectList.find((element) => element.project_id === body.project_id);
  item.project_name = body.project_name;
  if (body.project_logo !== null) {
    console.log('Logo changed');
  }
  return {
    result: 'success',
  };
};
// Mock.mock(/\/api\/v1\/projects\/.\//g, 'put', modifyProjectResult);

// eslint-disable-next-line no-unused-vars
const searchProjectResult = (options) => {
  const body = JSON.parse(options.body);
  // eslint-disable-next-line camelcase
  const { search_content } = body;
  const arr = projectList.filter((element) => element.project_name.indexOf(search_content) !== -1);
  return {
    projects: arr,
  };
};
// Mock.mock(/\/api\/v1\/projects\/.\//g, 'get', searchProjectResult);

// eslint-disable-next-line no-unused-vars
const getOverviewTableData = () => {
  const result = {
    records_aggregate:
      {
        date: '0',
        installed: '23324',
        dau: '434345',
        dnu: '544533',
        daily_install: '35545',
        new_uninstall_rate: '434365',
        retention: '435466',
      },
    records: [
      {
        date: '2021-1-21',
        installed: '23324',
        dau: '434345',
        dnu: '544533',
        daily_install: '35545',
        new_uninstall_rate: '434365',
        retention: '435466',
      },
      {
        date: '2021-1-21',
        installed: '23324',
        dau: '434345',
        dnu: '544533',
        daily_install: '35545',
        new_uninstall_rate: '434365',
        retention: '435466',
      },
    ],
    meta: {
      date: 'date',
      installed: 'dollar',
      dau: 'dollar',
      dnu: 'dollar',
      daily_install: 'dollar',
      new_uninstall_rate: 'dollar',
      retention: 'dollar',
    },
    total: 100,
  };
  return result;
};
// Mock.mock(/\/api\/v1\/projects\/.\/report\/overview\/table\//g, 'get', getOverviewTableData);

import getLineChartOption from '../../../utils/getLineChartOption';
import filterMixin from './filterMixin';

export default {
  data() {
    return {
      chart: null,
      // Page
      page_size: 20,
      page_num: 1,
      total: 0,
      // Table
      total_key: 'new_users',
      columns: [
        {
          title: ' ',
          key: 'empty',
          width: 30,
          fixed: 'left',
        },
      ],
      table_data: [
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
      ],
      rows_data: [
        {
          empty: '.',
          name: 'channel1',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          name: 'channel2',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
      ],
    };
  },
  mixins: [filterMixin],
  methods: {
    getChartElement() {
      return document.querySelector('.chart');
    },
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    drawChart() {
      if (this.chart) this.chart.clear();
      const chartElement = this.getChartElement();
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const option = getLineChartOption(this[this.chart_data_name], this.chart_configuration);
      chart.setOption(option);
      this.chart = chart;
    },
    configExpandedCellBg(index, rowNumber) {
      const startIndex = index;
      const endIndex = index + rowNumber;
      const arr = this.table_data.slice(startIndex, endIndex);
      let count = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of arr) {
        const cellClassName = {};
        // const totalValue = item[this.total_key];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(item)) {
          const numberKey = Number(key);
          if (!Number.isNaN(numberKey)) {
            const rateParamName = this.table_cell_name_list[1];
            const value = Number(item[key][rateParamName]);
            const number = value === 0 ? 0 : Math.floor(value * 10) + 1;
            cellClassName[key] = `channel-cell-${number}`;
          } else {
            cellClassName[key] = 'channel-cell-start';
          }
          if (count === 0) {
            cellClassName[key] += ' channel-first-row';
          }
          if (count === arr.length - 1) {
            cellClassName[key] += ' channel-last-row';
          }
        }
        this.$set(item, 'cellClassName', cellClassName);
        // eslint-disable-next-line no-plusplus
        count++;
      }
    },
    configTableCellData() {
      this.table_data.forEach((item) => {
        this.$set(item, 'expanded', false);
      });
    },
    configTableCellBg() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.table_data) {
        const cellClassName = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(item)) {
          const numberKey = Number(key);
          if (!Number.isNaN(numberKey)) {
            const rateParamName = this.table_cell_name_list[1];
            const value = Number(item[key][rateParamName]);
            const number = value === 0 ? 0 : Math.floor(value * 10) + 1;
            cellClassName[key] = `keep-cell-${number}`;
          }
        }
        this.$set(item, 'cellClassName', cellClassName);
      }
    },
    configTable() {
      this.configTableCellBg();
      this.configTableCellData();
    },
    refreshExpandedRows() {
      // Refresh expanded_rows
      this.expanded_rows.clear();
      // Refresh expanded_rows_length_map
      this.expanded_rows_length_map.clear();
      // Refresh expanded_loading_instance_map
      this.expanded_loading_instance_map.clear();
    },
    getTableDataIndexById(id) {
      const index = this.table_data.findIndex((item) => item.date === id);
      return index;
    },
    updateTableLoadingInstancePosition(index, rowCount) {
      const map = this.expanded_loading_instance_map;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of map) {
        if (index < value) {
          // Update loading position
          const newTop = 48 * rowCount + key.offsetTop;
          key.style.top = `${newTop}px`;
          map.set(key, value + rowCount);
        }
      }
    },
    dimensionChangeHandler() {
      this.table_data.forEach((item, index) => {
        if (item.expanded) {
          const length = this.expanded_rows_length_map.get(item.date);
          this.expanded_rows_length_map.delete(item.date);
          this.table_data.splice(index + 1, length);
          // eslint-disable-next-line no-param-reassign
          item.expanded = false;
        }
      });
      this.refreshExpandedRows();
    },
    showNumberChangeHandler() {
      this.columns.forEach((val) => {
        this.$set(val, 'render', this.columnRender);
      });
      console.log(this.columnRender);
      console.log('Column');
      console.dir(this.columns);
    },
  },
  computed: {
    component_name() {
      return this.$options.name;
    },
    project_id() {
      return this.$route.params.project_id;
    },
    rowsClassName() {
      const fn = (row) => {
        const { date } = row;
        if (this.expanded_rows.has(date)) {
          return 'arrow-drop-right arrow-drop-down';
        }
        return 'arrow-drop-right';
      };
      return fn;
    },
    /**
     * Rate rendering
     * @return {function(*, *): *}
     */
    render1() {
      const rateParamName = this.table_cell_name_list[1];
      const render = (h, p) => {
        const rowNumber = p.index;
        const { key } = p.column;
        if (key === 'empty' && p.row[key] === '.') {
          if ((this.component_name === 'ProjectKeep' && rowNumber !== 0)
          || this.component_name === 'ProjectUninstallRate') {
            const props = {
              type: 'md-arrow-dropright',
              size: 16,
              color: 'rgb(174, 176, 206)',
            };
            const classObj = {
              'arrow-drop-right': true,
              'arrow-drop-down': false,
            };
            // return h('span', 'empty');
            return h('Icon', {
              props,
              class: classObj,
            });
          }
          return h('span', '');
        }
        if (key === 'date' && p.row.empty === '') {
          const value = p.row[key];
          return h('Tooltip', {
            props: {
              content: value,
              placement: 'right',
              transfer: true,
            },
          }, `${this.$tools.tableCellValueEllipsis(value)}`);
        }
        const numberKey = Number(key);
        // eslint-disable-next-line no-shadow
        let result;
        if (Number.isNaN(numberKey)) {
          result = p.row[key];
        } else {
          result = p.row[key]
            ? this.$tools.percentageFormat(p.row[key][rateParamName])
            : undefined;
        }
        return h('span', result);
      };
      return render;
    },
    /**
     * Number of people rendering
     * @return {function(*, *): *}
     */
    render2() {
      const numberParamName = this.table_cell_name_list[0];
      const rateParamName = this.table_cell_name_list[1];
      const render = (h, p) => {
        const rowNumber = p.index;
        const { key } = p.column;
        if (key === 'empty' && p.row[key] === '.') {
          if ((this.component_name === 'ProjectKeep' && rowNumber !== 0)
            || this.component_name === 'ProjectUninstallRate') {
            const props = {
              type: 'md-arrow-dropright',
              size: 16,
              color: 'rgb(174, 176, 206)',
            };
            const classObj = {
              'arrow-drop-right': true,
              'arrow-drop-down': false,
            };
            // return h('span', 'empty');
            return h('Icon', {
              props,
              class: classObj,
            });
          }
          return h('span', '');
        }
        if (key === 'date' && p.row.empty === '') {
          const value = p.row[key];
          return h('Tooltip', {
            props: {
              content: value,
              placement: 'right',
            },
          }, `${this.$tools.tableCellValueEllipsis(value)}`);
        }
        const numberKey = Number(key);
        // eslint-disable-next-line no-shadow
        let result = null;
        if (Number.isNaN(numberKey)) {
          result = p.row[key];
          return h('span', result);
        }
        if (p.row[key]) {
          const value1 = p.row[key][numberParamName];
          let value2 = p.row[key][rateParamName];
          if (value1 === -1 && value2 === -1) {
            return h('span', '');
          }
          value2 = this.$tools.percentageFormat(value2);
          return h('div', [
            h('p', value1),
            h('p', value2),
          ]);
        }
        return h('span', '');
      };
      return render;
    },
    /**
     * Determined to {this.table_filter.show_number}
     */
    columnRender() {
      const show = this.table_filter.show_number;
      return !show ? this.render1 : this.render2;
    },
  },
  mounted() {
    this.initTimeFilter();
    this.fetchFilterList();
  },
};

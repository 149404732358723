<template>
  <div class="complex-multiple-select">
    <div class="multiple-select-trigger" tabIndex="0" hidefocus="true" @click="selectClick($event)">
      <Tooltip :content="checkbox_text"
               :disabled="checked_group.length === 0"
               placement="top"
               :delay="800">
        <span
          class="checkbox-text"
          :class="{'checkbox-empty-text': checked_group.length === 0}">
          {{ checkbox_text }}
        </span>
      </Tooltip>
      <img class="arrow-img"
           :class="expanded_arrow_class"
           src="../assets/image/ic_filter_arrow@2x.png"
           alt="Filter arrow">
    </div>
    <transition
      name="select-transition">
      <div  v-show="checkbox_visible"
            class="modal-wrap">
        <div  class="complex-multiple-select-modal"
              @click.stop="handler">
          <div class="modal-left">
            <div class="search-box">
              <Input v-model="search_content"
                     placeholder="搜索"
                     @on-enter="search"/>
            </div>
            <div class="select-options">
              <div>
                <RadioGroup v-if="option_enabled" v-model="check_option">
                  <Radio label="0">include</Radio>
                  <Radio label="1">exclude</Radio>
                </RadioGroup>
              </div>
              <span class="check-all" @click="checkAll">{{ check_all_text }}</span>
            </div>
            <div class="checkbox-box">
              <CheckboxGroup v-model="checked_group">
                <Checkbox class="checkbox"
                          v-for="item in search_items"
                          :key="`${item.value}-${item.label}`"
                          :label="value_param === 'value' ? item.value : item.label">
                  {{getItemLabel(item)}}
                </Checkbox>
              </CheckboxGroup>
            </div>
          </div>
          <div class="modal-right">
            <div class="checked-group-overview">
              <span class="text">
                已选择{{ checked_group.length }}项 (共{{ checkbox_items.length }}项)
              </span>
              <span class="clear-button" @click="clearCheckedGroup">清空</span>
            </div>
            <ul class="checked-group-list">
              <li v-for="item in checked_group"
                  :key="item"
                  class="checked-group-item">
                <div class="name">{{ item }}</div>
                <img class="delete-button"
                     src="../assets/image/ic_close.png"
                     alt="Close"
                     @click="deleteCheckedItem(item)">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ComplexMultipleSelect',
  props: {
    data: Array,
    option_enabled: Boolean,
    checkbox_items: Array,
    button_loading: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    value_param: {
      type: String,
      default: 'value',
    },
    label_param: {
      type: String,
      default: 'both',
    },
  },
  data() {
    return {
      search_content: '',
      search_items: [],
      checked_group: [],
      check_option: '0',
      check_all_text: '全选',
      checkbox_visible: false,
    };
  },
  methods: {
    selectClick(evt) {
      evt.stopPropagation();
      this.checkbox_visible = !this.checkbox_visible;
    },
    documentClickCallback() {
      if (this.checkbox_visible) {
        this.checkbox_visible = false;
      }
    },
    search() {
      if (this.search_content === '') {
        this.search_items = [...this.checkbox_items];
      } else {
        this.search_items = this.checkbox_items.filter((val) => {
          const contentInLowerCase = this.search_content.toLowerCase();
          // label & value
          return val.value.toLowerCase().indexOf(contentInLowerCase) !== -1
            || val.label.toLowerCase().indexOf(contentInLowerCase) !== -1;
        });
      }
    },
    checkAll() {
      if (this.check_all_text === '全选') {
        const arr = this.checkbox_items.map((val) => val.value);
        this.checked_group = [...arr];
      } else {
        this.checked_group = [];
      }
    },
    clearCheckedGroup() {
      this.checked_group = [];
    },
    deleteCheckedItem(item) {
      const index = this.checked_group.findIndex((element) => element === item);
      this.checked_group.splice(index, 1);
    },
    getItemLabel(item) {
      if (this.label_param === 'both') {
        return `${item.label} (${item.value})`;
      } if (this.label_param === 'value') {
        return `${item.value}`;
      }
      return `${item.label}`;
    },
    handler() {},
  },
  computed: {
    checkbox_text() {
      if (this.checked_group.length === 0) {
        return this.placeholder;
      }
      if (this.checked_group.length === 1) {
        const getLabelByValue = (value) => {
          console.log('value', value);
          console.log(this.checkbox_items);
          const targetItem = this.checkbox_items.find((item) => item.value === value);
          console.log('targetItem', targetItem);
          const { label } = targetItem;
          console.log('label', label);
          return label;
        };
        const value = this.checked_group[0];
        const label = getLabelByValue(value);
        const text = `${label}(${value})`;
        return text;
      }
      return `已选中${this.checked_group.length}个选项`;
    },
    expanded_arrow_class() {
      return this.checkbox_visible
        ? { 'expanded-arrow-img': true }
        : { 'expanded-arrow-img': false };
    },
  },
  watch: {
    data(newVal) {
      this.checked_group = newVal;
    },
    checkbox_items(val) {
      this.search_items = [...val];
    },
    'checked_group.length': function (val) {
      this.$emit('list-change', this.checked_group);
      const total = this.checkbox_items.length;
      if (val === total) {
        this.check_all_text = '全不选';
      } else {
        this.check_all_text = '全选';
      }
    },
    check_option(val) {
      const result = this.option_enabled ? val : null;
      this.$emit('option-change', result);
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClickCallback);
    this.checked_group = [...this.data];
  },
  created() {
    this.search_items = [...this.checkbox_items];
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClickCallback);
  },
};
</script>
<style lang="less">
  .complex-multiple-select {
    .ivu-checkbox-wrapper {
      margin-right: 0!important;
    }
    .ivu-radio-wrapper {
      margin-right: 0;
      border: none!important;
    }
    .ivu-radio-wrapper-checked {
      color: #2F54EB!important;
      background-color: #ffffff!important;
    }
    .select-options {
      .ivu-radio-wrapper {
        margin-right: 8px;
      }
    }
  }
</style>
<style scoped lang="less">
  @marginSide: 8px;
  @paddingSide: 8px;
  @inputBgColor: #FFFFFF;
  @inputColor: #545878;
  @inputPlaceholderColor: #C5C5C5;
  @inputActiveColor: #2978FF;
  .select-transition-enter, .select-transition-leave-to {
    opacity: 0;
    transform: scaleY(0.8);
  }
  .select-transition-enter-active, .select-transition-leave-active {
    transform-origin: top;
    transition: transform 0.24s ease-in-out, opacity 0.24s ease-in-out;
  }
  .select-transition-leave, .select-transition-enter-to {
    opacity: 1;
    transform: scaleY(1);
  }
  .complex-multiple-select {
    position: relative;
    .multiple-select-trigger {
      position: relative;
      width: 200px;
      height: 36px;
      line-height: 26px;
      padding: 4px 8px;
      background-color: @inputBgColor;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      color: #c1c1c1;
      cursor: pointer;
      transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      &:hover {
        border-color: @inputActiveColor;
      }
      &:focus {
        border-color: @inputActiveColor;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(41, 120, 255, 0.2);
      }
      .checkbox-text {
        display: inline-block;
        width: 160px;
        color: @inputColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .checkbox-empty-text {
        color: @inputPlaceholderColor;
      }
      .arrow-img {
        position: absolute;
        top: 50%;
        right: 12px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        transition: transform 0.2s ease-in-out;
      }
      .expanded-arrow-img {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .modal-wrap {
      position: absolute;
      top: 40px;
      left: 0;
      width: 480px;
      z-index: 99;
      .complex-multiple-select-modal {
        display: flex;
        width: 100%;
        background-color: #ffffff;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #dcdee2;

        .modal-left {
          width: 240px;
          height: 240px;
          border-right: 1px solid #dcdee2;
          .search-box {
            margin: @marginSide;
          }

          .select-options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0 @marginSide @marginSide;

            .check-all {
              color: #2F54EB;
              font-size: 12px;
              font-weight: 400;
              cursor: pointer;
            }
          }

          .checkbox-box {
            height: calc(~'100% - 82px');
            padding: 4px 0;
            border-top: 1px solid #d1d1d1;
            overflow-y: auto;
            white-space: pre;

            .checkbox {
              display: block;
              padding: @paddingSide;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
          }
        }

        .modal-right {
          width: 240px;
          height: 240px;

          .checked-group-overview {
            display: flex;
            justify-content: space-between;
            height: 40px;
            line-height: 40px;
            padding: 0 @paddingSide;

            .text, .clear-button {
              color: #999999;
              font-size: 12px;
              font-weight: 400;
            }

            .clear-button {
              color: #2F54EB;
              cursor: pointer;
              img {
                width: 12px;
              }
            }
          }

          .checked-group-list {
            width: 100%;
            height: calc(~'100% - 40px');
            padding: 4px 0;
            border-top: 1px solid #d1d1d1;
            overflow-y: auto;

            .checked-group-item {
              /*width: 100%;*/
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: @paddingSide;
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
              .name {
                flex: 0 0 90%;
                word-break: break-all;
              }
              .delete-button {
                flex: 0 0 10px;
                height: 10px;
                transform-origin: center;
                transition: transform 0.2s ease-in-out;
                &:hover {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
</style>

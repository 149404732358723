<template>
  <Modal
      class="overview-chart-modal"
      :value="modal_visible"
      :closable="false"
      :footer-hide="true"
      width="90"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
      <div class="header-right-box">
        <Mark
          @on-create-succeed="markListRefreshHandler"
          @on-edit-succeed="markListRefreshHandler"
          @on-delete-succeed="markListRefreshHandler"></Mark>
        <Icon
          class="close-icon"
          type="md-close"
          size="24"
          color="#cfd0e2"
          @click="cancelHandler"/>
      </div>
    </div>
    <div v-if="modal_visible" class="main">
      <div class="chart-wrap">
        <h2 class="title">{{ chart_title }}</h2>
        <div class="chart" ref="overview_detail_chart"></div>
      </div>
      <div class="table-wrap">
        <h2 class="title">{{ table_title }}</h2>
        <Table
          :columns="item.columns"
          :data="item.table_data"></Table>
      </div>
    </div>
  </Modal>
</template>

<script>
import Mark from '../Mark.vue';
import getLineChartOption from '../../utils/getLineChartOption';
import markMixin from '../../views/home/content/markMixin';

export default {
  name: 'OverviewChartModal',
  components: { Mark },
  props: {
    modal_visible: Boolean,
    item: Object,
  },
  data() {
    return {
      header_title: '详情',
    };
  },
  mixins: [markMixin],
  methods: {
    drawChart() {
      const chartElement = this.$refs.overview_detail_chart;
      const chart = this.$echarts.init(chartElement);
      const option = getLineChartOption(this.item.chart_data, this.chart_configuration);
      chart.setOption(option);
    },
    cancelHandler() {
      this.$emit('on-cancel');
    },
  },
  computed: {
    chart_configuration() {
      return {
        grid: {
          top: 18,
          right: 24,
          bottom: 28,
          left: 54,
        },
        x: {
          hideMaxLabel: true,
        },
        y: {
          formatter: 'normal',
        },
        series: {},
      };
    },
    chart_title() {
      return `${this.item.chart_data?.name}趋势图` || '趋势图';
    },
    table_title() {
      return `${this.item.chart_data?.name}数据表` || '数据表';
    },
    chart_data() {
      return this.item.chart_data;
    },
    filter() {
      return {
        time: this.item.time,
      };
    },
  },
  watch: {
    modal_visible(newVal) {
      if (newVal) {
        // Until dom rendering completes
        this.$nextTick(async () => {
          const loadingInstance = this.$loading(this.$refs.overview_detail_chart);
          await this.markListRefreshHandler();
          loadingInstance.hide();
        });
      }
    },
  },
};
</script>
<style lang="less">
  .overview-chart-modal {
    .ivu-modal {
      top: 60px;
    }
  }
</style>
<style scoped lang="less">
  @paddingSide: 32px;
  .overview-chart-modal {
    border-radius: 12px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px @paddingSide;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: #ffffff;
      /*box-shadow: 0 4px 4px rgba(0, 0, 0, 1);*/
      .header-title {
        font-size: 20px;
        font-weight: 600;
        color: #202444;
        line-height: 28px;
      }
      .header-right-box {
        & > * {
          cursor: pointer;
        }
        .close-icon {
          margin-left: 8px;
        }
      }
    }
    .main {
      height: calc(~'90vh - 92px');
      padding: 20px @paddingSide;
      overflow-y: scroll;
      background-color: #F2F3F7;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      .chart-wrap, .table-wrap {
        margin-bottom: 20px;
        padding: 20px 24px;
        background-color: #ffffff;
        border-radius: 12px;
        .title {
          margin-bottom: 12px;
          font-size: 20px;
          font-weight: 600;
          color: #202444;
          line-height: 28px;
        }
      }
      .chart-wrap {
        height: 50%;
        min-height: 320px;
        .chart {
          position: relative;
          width: 100%;
          height: calc(~'100% - 40px');
        }
      }
    }
  }
</style>

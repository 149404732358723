<template>
  <div class="project-list-menu menu">
    <div v-show="!is_expanded" class="unexpanded-menu">
      <li class="menu-item"
          :class="{ 'selected-item': selected_item_name === item.name }"
          v-for="item in menu_list"
          :key="item.name">
        <Dropdown trigger="hover" placement="right">
          <img class="item-icon"
               :src="selected_item_name === item.name ? item.selected_icon : item.icon"
               alt="Item Icon">
          <DropdownMenu slot="list">
            <DropdownItem :selected="selected_item_name === item.name">
              {{ item.label }}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
    </div>
    <div v-show="is_expanded" class="expanded-menu">
      <li class="menu-item"
          :class="{'selected-item': selected_item_name === item.name}"
          v-for="item in menu_list"
          :key="item.name"
          @click="selectItem(item)">
        <img class="item-icon"
             :src="selected_item_name === item.name ? item.selected_icon : item.icon"
             alt="Item Icon">
        <span class="item-name">{{ item.label }}</span>
      </li>
    </div>
  </div>
</template>

<script>
// WHY ???
import icon1 from '../../../../assets/image/ic_sidebar_project@2x.png';
import icon12 from '../../../../assets/image/ic_sidebar_selected_project@2x.png';
// import icon2 from '../../../../assets/image/ic_sidebar_config@2x.png';
import menuMixin from './menuMixin';
import isExpandedMixin from '../isExpandedMixin';

export default {
  name: 'ProjectListMenu',
  data() {
    return {
      selected_item_name: null,
      menu_list: [
        {
          name: 'project_list',
          icon: icon1,
          selected_icon: icon12,
          label: '项目',
        },
        // {
        //   name: 'configuration',
        //   icon: icon2,
        //   selected_icon: icon2,
        //   label: '配置',
        // },
      ],
    };
  },
  mixins: [menuMixin, isExpandedMixin],
  methods: {
    // selectItem(item) {
    //   this.selected_item_name = item.name;
    //   const route = {
    //     path: `/${item.name}`,
    //   };
    //   this.$router.push(route);
    // },
  },
};
</script>

<style scoped lang="less">
  @import "../../../../style/sidebarMenu.less";
</style>

<template>
  <div class="ads-overview">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title">
        <div></div>
      </content-header>
    </div>
    <div class="content-box">
      <div class="table-wrap">
        <div class="table-header">
          <h2 class="title">{{ overview_table_title }}</h2>
          <table-operation
            :columns="overview_table_columns"
            :table_data="overview_table_data"></table-operation>
        </div>
        <div class="filter-box">
          <div class="select-box">
            <DatePicker
              class="time-filter"
              type="daterange"
              :options="date_picker_option"
              :clearable="false"
              v-model="overview_filter.time"
              placeholder="请选择日期"
              style="width: 200px"
              @on-change="search('overview', 'table', 'fetchOverviewTableData')"/>
          </div>
        </div>
        <div class="table" ref="overview_table">
          <Table :columns="overview_table_columns"
                 :data="overview_table_data">
          </Table>
        </div>
        <div class="page-box">
          <Page :total="overview_total"
                :current="overview_page_num"
                :page-size="overview_page_size"
                @on-change="overviewPageNumChange"
                @on-page-size-change="overviewPageSizeChange"
                size="small" show-elevator show-sizer/>
        </div>
      </div>
      <div class="table-wrap">
        <div class="table-header">
          <h2 class="title">{{ profit_table_title }}</h2>
          <table-operation
            :columns="profit_table_columns"
            :table_data="profit_table_data"></table-operation>
        </div>
        <div class="filter-box">
          <div class="select-box">
            <DatePicker
              class="time-filter"
              type="daterange"
              :options="date_picker_option"
              :clearable="false"
              v-model="profit_filter.time"
              style="width: 200px"
              @on-change="search('profit', 'table', 'fetchProfitTableData')"/>
          </div>
        </div>
        <div class="table" ref="profit_table">
          <Table :columns="profit_table_columns"
                 :data="profit_table_data">
          </Table>
        </div>
        <div class="page-box">
          <Page :total="profit_total"
                :current="profit_page_num"
                :page-size="profit_page_size"
                @on-change="profitPageNumChange"
                @on-page-size-change="profitPageSizeChange"
                size="small" show-elevator show-sizer/>
        </div>
      </div>
      <div class="chart-wrap"
           v-for="(item) in chart_list"
           :key="item.name">
        <h2 class="title">{{ item.title }}</h2>
        <div class="container">
          <div class="chart" :ref="item.name" v-show="item.chart_visible"></div>
          <div class="empty-result-container" v-show="!item.chart_visible">
            <Icon class="empty-icon" size="72" type="ios-folder-open-outline" color="#e1e1e1"/>
            <p class="empty-text">数据不足</p>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */

import ContentHeader from '../../../../components/ContentHeader.vue';
import FilterBox from '../../../../components/FilterBox.vue';
import filterMixin from '../filterMixin';
import routerTriggerMixin from '../routerTriggerMixin';
import getLineChartOption from '../../../../utils/getLineChartOption';
import getPieChartOption from '../../../../utils/getPieChartOption';
import getBarChartOption from '../../../../utils/getBarChartOption';
import paramNameJSON from './paramName.json';
import TableOperation from '../../../../components/TableOperation.vue';
import chartColorMixin from '../mixin/chartColorMixin';

export default {
  name: 'AdsOverview',
  // eslint-disable-next-line vue/no-unused-components
  components: { TableOperation, FilterBox, ContentHeader },
  mixins: [routerTriggerMixin, filterMixin, chartColorMixin],
  data() {
    return {
      header_title: '广告概览',
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
          {
            text: '最近60天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 60);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      button_loading: false,
      overview_filter: {
        time: [],
      },
      profit_filter: {
        time: [],
      },
      filter_visible: {
        date: true,
        country: false,
        network: false,
        campaign: false,
      },
      // Total table
      overview_table_title: '概览报表',
      overview_table_columns: [],
      overview_table_data: [],
      profit_table_title: '每周利润报表',
      profit_table_columns: [],
      profit_table_data: [],
      // Chart list
      chart_list: [
        {
          name: 'profit_line_chart',
          title: '利润折线图',
          request_name: 'getAdsProfitLineData',
          param_name: 'profit',
          chart_visible: true,
          chart: null,
        },
        {
          name: 'revenue_cost_chart',
          title: '收入消耗对比折线图',
          request_name: 'getAdsRevenueCostLineData',
          param_name: 'ads_revenue',
          chart_visible: true,
          chart: null,
        },
        {
          name: 'retention_line_chart',
          title: '留存折线图',
          request_name: 'getAdsRetentionLineData',
          param_name: 'd1_retention',
          chart_visible: true,
          chart: null,
        },
        // {
        //   name: 'revenue_sale_ads_line_chart',
        //   title: '内购广告收入折线图',
        //   request_name: 'getAdsRevenueSaleLineData',
        //   param_name: 'ads_revenue',
        //   chart_visible: true,
        //   chart: null,
        // },
        {
          name: 'analysis_dau_line_chart',
          title: '活跃用户折线图',
          request_name: 'getAdsAnalysisDauLineData',
          param_name: 'dau',
          chart_visible: true,
          chart: null,
        },
        // {
        //   name: 'revenue_pie_chart',
        //   title: '收入饼状图',
        //   request_name: 'getAdsRevenueDistributePieData',
        //   param_name: 'revenue',
        //   chart_visible: true,
        //   chart: null,
        // },
      ],
      chart_data_list: [],
      chart_request_list: [
        'getAdsProfitLineData',
        'getAdsRevenueCostLineData',
        'getAdsRetentionLineData',
        'getAdsRevenueSaleLineData',
        'getAdsAnalysisDauLineData',
        'getAdsSaleRevenueBarData',
        'getAdsRevenueDistributePieData',
      ],
      // Page
      overview_total: 0,
      overview_page_num: 1,
      overview_page_size: 10,
      profit_total: 0,
      profit_page_num: 1,
      profit_page_size: 10,
      // Loading intance list
      loading_instance_list: [],
    };
  },
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    overview_render() {
      return (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        if (value === -1) {
          return h('span', '-');
        } if (key === 'roi') {
          return h('span', `${this.$tools.percentageFormat(value)}`);
        } if (key === 'd1_retention') {
          return h('span', `${this.$tools.percentageFormat(value)}`);
        }
        return h('span', this.$tools.keepDecimals(value));
      };
    },
    profit_render() {
      return (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        if (key === 'date' && Array.isArray(value)) {
          const getFormatDate = (dateStr) => {
            const arr = dateStr.split('-');
            const str = arr.slice(1).join('/');
            return str;
          };
          return h('span', `${getFormatDate(value[0])}-${getFormatDate(value[1])}`);
        } if (value === -1) {
          return h('span', '-');
        }
        return h('span', this.$tools.keepDecimals(value));
      };
    },
  },
  methods: {
    fetchData() {
      // this.fetchFilterList();
      this.fetchOverviewData();
    },
    fetchOverviewData() {
      this.fetchAllTablesData();
      this.fetchAllChartsData();
    },
    fetchSingleData(name, type, funcName) {
      if (type === 'table') {
        const ref = `${name}_table`;
        const instance = this.$loading(this.$refs[ref]);
        const promise = this[funcName]();
        promise.then((response) => {
          this.tableDataCallback(response.data, name);
        }).catch((error) => {
          this.$Message.error(error.message);
        }).finally(() => {
          instance.hide();
        });
      } else {
        const item = { ...name };
        const ref = item.name;
        const paramName = item.param_name;
        const instance = this.$loading(this.$refs[ref]);
        const promise = this.fetchSingleChartData(item.request_name);
        promise.then((response) => {
          this.fetchChartDataCallback(response.data, ref, paramName, item);
        }).catch((error) => {
          this.$Message.error(error.message);
        }).finally(() => {
          instance.hide();
        });
      }
    },
    async fetchAllTablesData() {
      const instance1 = this.$loading(this.$refs.overview_table);
      const instance2 = this.$loading(this.$refs.profit_table);
      this.loading_instance_list.push(instance1, instance2);
      try {
        const overviewTablePromise = this.fetchOverviewTableData();
        const profitTablePromise = this.fetchProfitTableData();
        const overviewTableResponse = await overviewTablePromise;
        const profitTableResponse = await profitTablePromise;
        this.tableDataCallback(overviewTableResponse?.data, 'overview');
        this.tableDataCallback(profitTableResponse?.data, 'profit');
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        this.loading_instance_list.forEach((instance) => {
          instance.hide();
        });
      }
    },
    tableDataCallback(responseData, tableName) {
      if (!responseData) {
        this[`${tableName}_table_columns`] = [];
        this[`${tableName}_table_data`] = [];
        return;
      }
      this[`${tableName}_table_columns`] = [];
      this[`${tableName}_table_data`] = [];
      const columns = this[`${tableName}_table_columns`];
      const data = this[`${tableName}_table_data`];
      const { header, record, total } = responseData;
      this[`${tableName}_total`] = total;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of header) {
        const obj = {
          key,
          title: paramNameJSON[key],
          minWidth: 120,
          fixed: key === 'date' ? 'left' : null,
        };
        if (tableName === 'overview') {
          obj.render = this.overview_render;
        }
        if (tableName === 'profit') {
          obj.render = this.profit_render;
        }
        columns.push(obj);
      }
      console.log('column');
      console.dir(columns);
      // eslint-disable-next-line no-restricted-syntax
      for (const rowData of record) {
        data.push(rowData);
      }
    },
    fetchOverviewTableData() {
      const params = {
        page_size: this.overview_page_size,
        page_num: this.overview_page_num,
        date_start: this.$tools.dateFormat(this.overview_filter.time[0]),
        date_end: this.$tools.dateFormat(this.overview_filter.time[1]),
      };
      return this.$api.getAdsOverviewTableData(this.project_id, params);
    },
    fetchProfitTableData() {
      const params = {
        page_size: this.profit_page_size,
        page_num: this.profit_page_num,
        date_start: this.$tools.dateFormat(this.profit_filter.time[0]),
        date_end: this.$tools.dateFormat(this.profit_filter.time[1]),
      };
      return this.$api.getAdsProfitTableData(this.project_id, params);
    },
    fetchChartDataCallback(responseData, chartRef, chartParamName, item) {
      if (!responseData) {
        // eslint-disable-next-line no-param-reassign
        item.chart_visible = false;
        return;
      }
      // eslint-disable-next-line no-param-reassign
      item.chart_visible = true;
      this.$nextTick(() => {
        this.drawChart(responseData, chartRef, chartParamName, item);
      });
    },
    fetchSingleChartData(requestName) {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
      };
      return this.$api[requestName](this.project_id, params);
    },
    fetchAllChartsData() {
      const promiseList = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.chart_list) {
        console.log(this.$refs[item.name][0]);
        const instance = this.$loading(this.$refs[item.name][0]);
        const requestName = item.request_name;
        const promise = this.fetchSingleChartData(requestName);
        promise.then((response) => {
          this.fetchChartDataCallback(response?.data, item.name, item.param_name, item);
        }).finally(() => {
          instance.hide();
        });
        promiseList.push(promise);
      }
      Promise.all(promiseList)
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    getPackedUpChartData(data, name) {
      const chartData = {};
      const { type } = data;
      if (type === 'line' || type === 'column') {
        chartData.name = paramNameJSON[name];
        chartData.x_axis = data.x_axis;
        chartData.y_axis = data.y_axis;
        // eslint-disable-next-line prefer-destructuring
        chartData.color = this.chart_color_list[0];
        chartData.area_color = this.chart_area_color;
        chartData.tooltip_color = this.chart_tooltip_color_list[0];
        chartData.mark_color = this.chart_mark_color_list[0];
        return chartData;
      } if (type === 'multi_line' || type === 'multi_column') {
        chartData.x_axis = data.x_axis;
        chartData.y_axis = [];
        chartData.name_list = [];
        chartData.color = [];
        chartData.tooltip_color = [];
        chartData.mark_color = [];
        let count = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of data.dimension) {
          const dimensionData = data[item];
          chartData.color.push(this.chart_color_list[count]);
          chartData.tooltip_color.push(this.chart_tooltip_color_list[count]);
          chartData.mark_color.push(this.chart_mark_color_list[count]);
          chartData.name_list.push(paramNameJSON[item]);
          chartData.y_axis.push(dimensionData);
          count += 1;
        }
        return chartData;
      } if (type === 'pie') {
        chartData.data = data.data || data.record;
        chartData.color = [];
        const { length } = chartData.data;
        for (let i = 0; i < length; i += 1) {
          const currentColor = this.chart_color_list[i % length];
          chartData.color.push(currentColor);
          chartData.data[i].name = paramNameJSON[chartData.data[i].name];
        }
        return chartData;
      }
      return null;
    },
    getPackedUpChartConfiguration(data) {
      const { type, unit } = data;
      const grid = {
        top: '12px',
        right: '12px',
        bottom: '24px',
        left: '40px',
      };
      if (type === 'line' || type === 'column') {
        const configuration = {
          grid,
          x: {
            hideMaxLabel: true,
          },
          y: {
            formatter: unit,
          },
          series: {
            formatter: unit,
          },
        };
        return configuration;
      } if (type === 'multi_line' || type === 'multi_column') {
        grid.top = '36px';
        const configuration = {
          grid,
          x: {
            hideMaxLabel: true,
          },
          y: {
            formatter: unit,
            multiple: true,
          },
          series: {
            formatter: unit,
            lineStyle: {
              width: 1.8,
            },
          },
        };
        return configuration;
      } if (type === 'pie') {
        const configuration = {
          series: {
            formatter: unit,
          },
        };
        return configuration;
      }
      return null;
    },
    drawChart(data, chartRef, chartParamName, item) {
      const chartElement = this.$refs[chartRef][0];
      if (!chartElement) return;
      const chartData = this.getPackedUpChartData(data, chartParamName);
      const configuration = this.getPackedUpChartConfiguration(data);
      const chartInstance = item.chart;
      if (chartInstance) chartInstance.clear();
      if (data.type === 'line' || data.type === 'multi_line') {
        const chart = this.$echarts.init(chartElement);
        const option = getLineChartOption(chartData, configuration);
        chart.setOption(option);
        // eslint-disable-next-line no-param-reassign
        item.chart = chart;
      } if (data.type === 'pie') {
        const chart = this.$echarts.init(chartElement);
        const option = getPieChartOption(chartData, configuration);
        chart.setOption(option);
        // eslint-disable-next-line no-param-reassign
        item.chart = chart;
      } if (data.type === 'column' || data.type === 'multi_column') {
        const chart = this.$echarts.init(chartElement);
        const option = getBarChartOption(chartData, configuration);
        chart.setOption(option);
        // eslint-disable-next-line no-param-reassign
        item.chart = chart;
      }
    },
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
      this.filter.time = [startDate, endDate];
      this.overview_filter.time = [startDate, endDate];
      this.profit_filter.time = [startDate, endDate];
    },
    search(name, type, funcName = null) {
      console.log(this[`${name}_filter`].time);
      this.fetchSingleData(name, type, funcName);
    },
    changeChartWrapHeight() {
      const chartWrapElements = document.getElementsByClassName('chart-wrap');
      const chartWrapElement = chartWrapElements[0];
      const width = chartWrapElement.clientWidth;
      // eslint-disable-next-line no-restricted-syntax
      for (const element of chartWrapElements) {
        element.style.height = `${width * 0.6}px`;
      }
    },
    overviewPageNumChange(pageNum) {
      this.overview_page_num = pageNum;
      this.fetchSingleData('overview', 'table', 'fetchOverviewTableData');
    },
    overviewPageSizeChange(pageSize) {
      this.overview_page_size = pageSize;
      this.overview_page_num = 1;
      this.fetchSingleData('overview', 'table', 'fetchOverviewTableData');
    },
    profitPageNumChange(pageNum) {
      this.profit_page_num = pageNum;
      this.fetchSingleData('profit', 'table', 'fetchProfitTableData');
    },
    profitPageSizeChange(pageSize) {
      this.profit_page_size = pageSize;
      this.profit_page_num = 1;
      this.fetchSingleData('profit', 'table', 'fetchProfitTableData');
    },
  },
  created() {
    this.initTimeFilter();
  },
  mounted() {
    console.log(this.$refs.overview_table);
    this.changeChartWrapHeight();
    this.fetchData();
    window.addEventListener('resize', this.changeChartItemHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.changeChartItemHeight);
  },
};
</script>
<style lang="less">
  .chart-wrap {
    @import url('../../../../style/adsOverviewTooltip.less');
  }
</style>
<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 7px;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .ads-overview {
    .content-header-wrap {
      margin: 24px;
    }
    .title {
      display: inline-block;
      width: 100%;
      margin: 0 0 20px 0;
      .h2Title();
    }
    .filter-box-wrap {
      margin: 0 20px 20px;
      padding: 24px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #EAEBF7;
    }
    .content-box {
      padding: 0 calc(~'24px - 1%');
      .table-wrap, .chart-wrap {
        margin: 0 1% 2%;
        float: left;
        border: @boxBorder;
        border-radius: @borderRadius;
      }
      .table-wrap {
        width: 98%;
        padding: 20px 24px;
        background-color: #ffffff;
        box-sizing: border-box;
        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .title {
            width: auto;
            margin: 0;
            line-height: 22px;
          }
        }
        .filter-box {
          margin-bottom: 20px;
        }
        .table {
          position: relative;
          min-height: 120px;
        }
        .page-box {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          height: 48px;
        }
      }
      .chart-wrap {
        position: relative;
        width: 48%;
        padding: 18px;
        background-color: #ffffff;
        .container {
          position: relative;
          height: calc(~'100% - 42px');
          .chart {
            height: 100%;
          }
        }
        .empty-result-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .empty-icon {
            margin-bottom: 12px;
          }
          .empty-text {
            font-size: 20px;
            font-weight: 400;
            color: #c1c1c1;
          }
        }
      }
      .clear {
        clear: both;
      }
    }
  }
</style>

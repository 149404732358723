import toolFunctions from './toolFunctions';

const defaultConfiguration = {
  y: {
    formatter: 'normal',
  },
  x: {},
  series: {
    formatter: 'normal',
  },
};
/**
 * Line chart option configuration
 * @param chartData {Object}
 * @param configuration {Object}
 * @return {Object}
 */
const getLineChartOption = (chartData, configuration = defaultConfiguration) => {
  const paramsCheck = () => {
    if (!configuration.y) {
      // eslint-disable-next-line no-param-reassign
      configuration.y = {
        formatter: 'normal',
      };
    } if (!configuration.x) {
      // eslint-disable-next-line no-param-reassign
      configuration.x = {
        formatter: 'normal',
      };
    } if (!configuration.series) {
      // eslint-disable-next-line no-param-reassign
      configuration.series = {
        formatter: 'normal',
      };
    }
  };
  const markLines = [];
  if (chartData.mark_lines) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of chartData.mark_lines) {
      const obj = {
        xAxis: String(item.xAxis),
      };
      markLines.push(obj);
    }
  }
  console.log('markLines');
  console.log(markLines);
  const series = [];
  if (configuration.y.multiple) {
    // eslint-disable-next-line no-restricted-syntax,no-plusplus
    for (let i = 0; i < chartData.y_axis.length; i++) {
      const item = chartData.y_axis[i];
      const temp = {
        name: chartData.name_list[i],
        type: 'line',
        // point
        itemStyle: {
          color: chartData?.color[i] || 'rgba(75, 92, 240, 1)',
        },
        lineStyle: {
          width: configuration.series?.lineStyle?.width || 3,
          color: chartData?.color[i] || 'rgba(75, 92, 240, 1)',
        },
        symbol: 'emptyCircle',
        showSymbol: false,
        showAllSymbol: false,
        data: item,
      };
      series.push(temp);
    }
    series[0].markLine = {
      symbol: ['none', 'none'],
      lineStyle: {
        width: 1,
        // color: 'rgba(75, 92, 240, 1)',
      },
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
        lineStyle: {
          type: 'dotted',
          width: 1,
        },
      },
      data: markLines,
    };
  } else {
    const temp = {
      name: chartData.name || 'data',
      type: 'line',
      // point
      itemStyle: {
        color: chartData.color || 'rgba(75, 92, 240, 1)',
      },
      lineStyle: {
        width: 2,
        color: chartData.color || 'rgba(75, 92, 240, 1)',
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: chartData.area_color || 'rgba(75, 92, 240, 1)',
            },
            {
              offset: 1,
              color: 'rgba(255, 255, 255, 1)',
            },
          ],
          global: false,
        },
      },
      symbol: 'emptyCircle',
      showSymbol: false,
      showAllSymbol: false,
      markLine: {
        symbol: ['none', 'none'],
        lineStyle: {
          width: 1,
          // color: 'rgba(75, 92, 240, 1)',
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
          lineStyle: {
            type: 'dotted',
            width: 1,
          },
        },
        data: markLines,
      },
      data: chartData.y_axis,
    };
    series.push(temp);
  }
  paramsCheck();
  const option = {
    title: {
      show: configuration.title,
      text: configuration.title,
      textStyle: {
        color: '#202444',
        fontWeight: 'bold',
        fontSize: 16,
      },
      top: '2%',
      left: '2%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      formatter(params) {
        const { name } = params[0];
        let str = `<div class="tooltip">
                    <h4 class="tooltip-title">${name}:</h4>`;
        let count = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of params) {
          const color = Array.isArray(chartData.tooltip_color)
            ? chartData.tooltip_color[count]
            : chartData.tooltip_color;
          const { seriesName, value } = item;
          const type = configuration.series.formatter;
          let result = value;
          if (type === 'rate') {
            result = toolFunctions.percentageFormat(result);
          } else {
            result = toolFunctions.keepDecimals(result);
          }
          str += `<p class="tooltip-content">
                        <span class="tooltip-text" style="color: ${color}!important;">${seriesName}:</span>
                        <span class="tooltip-text" style="color: ${color}!important;">${result}</span>
                    </p>`;
          count += 1;
        }
        // The loop below need modification
        const markList = chartData.mark_lines;
        if (Array.isArray(markList)) {
          let flag = false;
          const color = chartData.mark_color || 'rgba(75, 92, 240, 1)';
          // eslint-disable-next-line no-restricted-syntax
          for (const item of markList) {
            const { xAxis, text } = item;
            if (xAxis === name || Number(xAxis) === Number(name)) {
              if (!flag) {
                str += '<h4 class="tooltip-mark-title">标注：</h4>';
                flag = true;
              }
              str += `<p class="tooltip-content">
                        <span class="tooltip-text" style="color: ${color}!important;"></span>
                        <span class="tooltip-text" style="color: ${color}!important;">${text}</span>
                    </p>`;
            }
          }
        }
        str += '</div>';
        // const { name, seriesName, value } = params[0];
        // const type = configuration.series.formatter;
        // let result = value;
        // if (type === 'rate') {
        //   result = toolFunctions.percentageFormat(result);
        // }
        // const str = `<div class="tooltip">
        //             <h4 class="tooltip-title">${seriesName}</h4>
        //             <p class="tooltip-content">
        //                 <span class="tooltip-text">${name}:</span>
        //                 <span class="tooltip-text">${result}</span>
        //             </p>
        //     </div>`;
        return str;
      },
      renderMode: 'html',
      backgroundColor: 'opacity',
    },
    legend: {
      show: configuration.y.multiple,
      top: '0.5%',
      left: '0',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    grid: configuration.grid
      ? {
        top: configuration.grid.top,
        bottom: configuration.grid.bottom,
        left: configuration.grid.left,
        right: configuration.grid.right,
      } : {
        top: '12%',
        bottom: '8%',
        left: '6%',
        right: '3%',
      },
    xAxis: [
      {
        axisLabel: {
          margin: 8,
          color: 'rgba(84, 88, 120, 1)',
          showMaxLabel: !configuration.x.hideMaxLabel,
          formatter: typeof configuration.x.formatter === 'function'
            ? configuration.x.formatter
            : (value) => value,
          padding: configuration.x.padding
            ? configuration.x.padding
            : undefined,
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(233, 234, 247, 1)',
          },
        },
        axisPointer: {
          snap: true,
          type: 'line',
          lineStyle: {
            width: 2,
            color: '#E6E7F5',
          },
        },
        axisTick: {
          show: false,
        },
        boundaryGap: false,
        data: chartData.x_axis,
      },
    ],
    yAxis: [
      {
        axisLabel: {
          margin: configuration.y.margin
            ? configuration.y.margin
            : 16,
          fontSize: '12px',
          color: 'rgba(84, 88, 120, 1)',
          formatter: (value) => {
            const type = configuration.y.formatter;
            if (type === 'rate') {
              return toolFunctions.percentageFormat(value);
            } if (type === 'normal') {
              const numberVal = Number(value);
              let result = numberVal;
              const numberCount = toolFunctions.numberOfDigit(numberVal);
              if (!Number.isNaN(numberVal)) {
                if (numberCount > 6) {
                  result = `${numberVal / 1000000}M`;
                } else if (numberCount > 3) {
                  result = `${numberVal / 1000}K`;
                }
              }
              return result;
            }
            return value;
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: configuration.y.splitLine === false
          ? {
            show: false,
          }
          : {
            lineStyle: {
              type: 'dashed',
              color: 'rgb(245, 245, 252)',
            },
          },
      },
    ],
    series,
  };
  return option;
};
export default getLineChartOption;

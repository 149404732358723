<template>
  <div class="project-uninstall-rate">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title"
        @on-mark-create-succeed="markListRefreshHandler(false, null, uninstall_rate_chart_data)"
        @on-mark-edit-succeed="markListRefreshHandler(false, null, uninstall_rate_chart_data)"
        @on-mark-delete-succeed="
        markListRefreshHandler(false, null, uninstall_rate_chart_data)
      "></content-header>
    </div>
    <div class="filter-box-wrap">
      <filter-box
        :button_loading="button_loading"
        :filter="filter"
        :country_list="country_list"
        :network_list="network_list"
        :campaign_list="campaign_list"
        @search="search"></filter-box>
    </div>
    <div class="chart-wrap">
      <div class="title">{{ chart_title }}</div>
      <div class="chart" :ref="chart_ref"></div>
    </div>
    <div class="table-wrap">
      <div class="table-header">
        <h2 class="title">{{ table_title }}</h2>
        <table-operation :columns="xlsx_columns" :table_data="xlsx_table_data"></table-operation>
      </div>
      <div class="heat-map-filter-box-wrap">
        <heat-map-filter-box
          :filter="table_filter"
          :dimension_disabled="dimension_disabled"
          @on-dimension-change="dimensionChangeHandler"
          @on-show-number-change="showNumberChangeHandler"></heat-map-filter-box>
      </div>
      <div class="table" ref="table">
        <Table :columns="columns"
               :data="table_data"
               :row-class-name="rowsClassName"
               @on-cell-click="rowExpand"></Table>
      </div>
      <div class="page-box">
        <Page :total="total"
              :current="page_num"
              :page-size="page_size"
              @on-change="pageNumChange"
              @on-page-size-change="pageSizeChange"
              size="small" show-elevator show-sizer></Page>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '../../../components/ContentHeader.vue';
// import ComplexMultipleSelect from '../../../components/ComplexMultipleSelect.vue';
import keepAndUninstallRateMixin from './Keep&UninstallRateMixin';
// import toolFunctions from '../../../utils/toolFunctions';
import routerTriggerMixin from './routerTriggerMixin';
import FilterBox from '../../../components/FilterBox.vue';
import HeatMapFilterBox from '../../../components/HeatMapFilterBox.vue';
import markMixin from './markMixin';
import TableOperation from '../../../components/TableOperation.vue';
import exportMixin from './exportMixin';

export default {
  name: 'ProjectUninstallRate',
  components: {
    TableOperation,
    HeatMapFilterBox,
    FilterBox,
    ContentHeader,
  },
  data() {
    return {
      chart_data_name: 'uninstall_rate_chart_data',
      chart_ref: 'uninstall_rate_chart',
      header_title: '卸载率',
      chart_title: '卸载率趋势图',
      table_title: '卸载率热力图',
      table_cell_name_list: ['remove', 'remove_rate'],
      button_loading: false,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      country_list: [],
      network_list: [],
      campaign_list: [],
      filter: {
        time: [],
        country: [],
        country_exclude: 0,
        channel: [],
        dimension: 'network',
      },
      table_filter: {
        dimension: 'network',
        show_number: false,
      },
      uninstall_rate_chart_data: {
        name: '卸载率',
        color: 'rgba(81, 145, 255, 1)',
        area_color: 'rgba(116, 129, 244, 0.2)',
        tooltip_color: 'rgba(75, 92, 240, 1)',
        x_axis: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子', 'A', 'B', 'C', 'D', 'E', 'F'],
        y_axis: [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
      },
      page_size: 20,
      page_num: 1,
      total: 0,
      columns: [
        {
          title: ' ',
          key: 'empty',
          width: 30,
          fixed: 'left',
        },
      ],
      expanded_rows: new Set(),
      expanded_rows_length_map: new Map(),
      expanded_loading_instance_map: new Map(),
      table_data: [
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
      ],
      loading_param: 0,
      rows_data: [
        {
          empty: '.',
          name: 'channel1',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          name: 'channel2',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
      ],
    };
  },
  mixins: [
    routerTriggerMixin,
    keepAndUninstallRateMixin,
    markMixin,
    exportMixin,
  ],
  methods: {
    getChartElement() {
      return this.$refs[this.chart_ref];
    },
    getChartParent() {
      return this.$refs[this.chart_ref];
    },
    getTableParent() {
      return this.$refs.table;
    },
    fetchData() {
      this.fetchUninstallRateData();
      this.fetchFilterList();
    },
    fetchUninstallRateDataCallback(response) {
      const {
        // eslint-disable-next-line camelcase
        remove_daily_report,
        // eslint-disable-next-line camelcase
        remove_reports,
        header,
        result,
        total,
      } = response.data;
      if (result === 'success') {
        if (this.columns.length > 1) {
          this.columns = [this.columns[0]];
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < header.length + 1; i++) {
          let obj;
          if (i === 1) {
            obj = {
              key: header[i - 1],
              title: '日期',
              width: 120,
              align: 'left',
              fixed: 'left',
            };
          } else if (i === 2) {
            obj = {
              key: header[i - 1],
              title: '新增用户',
              width: 96,
              align: 'left',
              fixed: 'left',
            };
          } else {
            obj = {
              key: header[i - 1],
              title: header[i - 1],
              minWidth: 96,
              align: 'center',
            };
          }
          this.columns.push(obj);
          const render = this.columnRender;
          this.columns.forEach((val) => {
            this.$set(val, 'render', render);
          });
        }
        remove_reports.forEach((val) => {
          // eslint-disable-next-line no-param-reassign
          val.empty = '.';
          // this.$tools.formatChartData(val, 'remove_rate');
        });
        const xAxisData = remove_daily_report.map((val) => val.date);
        const yAxisData = remove_daily_report.map(
          (val) => val.remove_rate[this.table_cell_name_list[1]],
        );
        this.uninstall_rate_chart_data.x_axis = [...xAxisData];
        this.uninstall_rate_chart_data.y_axis = [...yAxisData];
        // eslint-disable-next-line camelcase
        this.table_data = [...remove_reports];
        this.total = total;
        this.configTable();
      }
    },
    /**
     * Fetch UninstallRate Data
     * (Need modification)
     */
    async fetchUninstallRateData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        country_list: this.filter.country,
        country_list_exclude: this.filter.country_list_exclude,
        network_list: this.filter.network,
        campaign_list: this.filter.campaign,
        page_num: this.page_num,
        page_size: this.page_size,
      };
      const instance1 = this.$loading(this.getChartParent());
      const instance2 = this.$loading(this.getTableParent());
      instance1.show();
      instance2.show();
      try {
        const uninstallRateResponse = await this.$api.getUninstallRateData(this.project_id, params);
        this.fetchUninstallRateDataCallback(uninstallRateResponse);
        this.refreshExpandedRows();
        await this.markListRefreshHandler(false, null, this.uninstall_rate_chart_data);
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
      }
    },
    fetchUninstallRateDetailData(dateStart, cb) {
      const params = {
        date_start: dateStart,
        network_list: this.filter.network,
        country_list: this.filter.country,
        country_list_exclude: this.filter.country_list_exclude,
        campaign_list: this.filter.campaign,
        divide_dimension: this.table_filter.dimension,
        page_size: this.page_size,
        page_num: this.page_num,
      };
      this.$api.getUninstallRateDetailData(this.project_id, params)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { result, remove_reports } = response.data;
          if (result === 'success') {
            console.dir(remove_reports);
            return Promise.resolve(remove_reports);
          }
          return Promise.reject();
        })
        .then((reports) => {
          if (reports) {
            cb(reports);
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.$Message.error(error.message);
        });
    },
    rowExpand(row, column) {
      const { expanded, date } = row;
      const { key } = column;
      const idKey = 'date';
      if (expanded !== undefined) {
        // eslint-disable-next-line no-underscore-dangle
        const index = row._index;
        // Regard date as id
        const id = this.table_data[index][idKey];
        if (key === 'empty') {
          if (!expanded) {
            // Expandable
            this.table_data[index].expanded = true;
            this.expanded_rows.add(id);
            this.updateTableLoadingInstancePosition(index, 2);
            // Fetch detail data
            const rowsData = [
              {
                empty: '.',
                name: 'channel1',
                date: '2020-1-08',
                new_users: '8943',
                1: '3445',
                2: '2343',
                3: '1564',
                4: '234',
                5: '32',
                6: '23',
              },
              {
                empty: '.',
                name: 'channel1',
                date: '2020-1-08',
                new_users: '8943',
                1: '3445',
                2: '2343',
                3: '1564',
                4: '234',
                5: '32',
                6: '23',
              },
            ];
            this.table_data.splice(index + 1, 0, ...rowsData);
            this.loading_param = index + 1;
            let instance = null;
            this.$nextTick(() => {
              const tableElement = this.getTableParent();
              const selector = `.ivu-table-body .ivu-table-row:nth-child(${index + 1})`;
              const rowElement = document.querySelector(selector);
              const width = tableElement.clientWidth;
              const height = rowElement.clientHeight;
              const top = rowElement.offsetTop + height;
              instance = this.$loading(tableElement, '#f5f5f8');
              const instanceElement = instance.element();
              instanceElement.style.top = `${top + 48}px`;
              instanceElement.style.height = `${48 * 2}px`;
              instanceElement.style.width = `${width}px`;
              instance.show();
              this.expanded_loading_instance_map.set(instanceElement, index);
              this.expanded_loading_instance_map = new Map(this.expanded_loading_instance_map);
            });
            const callback = (reports) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const item of reports) {
                // eslint-disable-next-line no-restricted-syntax
                for (const k of Object.keys(item)) {
                  // const numberKey = Number(k);
                  // if (!Number.isNaN(numberKey)) {
                  //   item[k] = item[k].remove_rate;
                  // }
                  if (k === this.table_filter.dimension) {
                    item.date = item[k];
                  }
                  item.empty = '';
                }
              }
              this.rows_data = [...reports];
              instance.hide();
              this.expanded_loading_instance_map.delete(instance.element());
              this.expanded_loading_instance_map = new Map(this.expanded_loading_instance_map);
              const currentIndex = this.getTableDataIndexById(id);
              this.expanded_rows_length_map.set(id, this.rows_data.length);
              this.loading_param = 0;
              this.table_data.splice(currentIndex + 1, 2, ...this.rows_data);
              this.configExpandedCellBg(currentIndex + 1, this.rows_data.length);
              // eslint-disable-next-line no-param-reassign
              this.table_data[currentIndex].expanded = true;
              this.updateTableLoadingInstancePosition(index, this.rows_data.length - 2);
            };
            this.fetchUninstallRateDetailData(date, callback);
          } else {
            // Not expandable
            const length = this.expanded_rows_length_map.get(id);
            this.expanded_rows.delete(id);
            this.expanded_rows_length_map.delete(id);
            console.log(`Fold: ${length}`);
            this.table_data.splice(index + 1, length);
            // eslint-disable-next-line no-param-reassign
            this.table_data[index].expanded = false;
          }
        }
      }
    },
    search() {
      this.button_loading = true;
      this.fetchUninstallRateData();
    },
    pageNumChange(pageNum) {
      this.page_num = pageNum;
      this.fetchUninstallRateData();
    },
    pageSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchUninstallRateData();
    },
  },
  computed: {
    dimension_disabled() {
      return this.expanded_loading_instance_map.size !== 0;
    },
    chart_configuration() {
      return {
        x: {
          hideMaxLabel: true,
        },
        y: {
          formatter: 'rate',
        },
        series: {
          formatter: 'rate',
        },
        grid: {
          top: '12px',
          bottom: '18px',
          left: '42px',
          right: '12px',
        },
      };
    },
  },
  mounted() {
    this.fetchUninstallRateData();
    // this.drawChart();
  },
};
</script>
<style lang="less">
  @import '../../../style/gradientTable.less';
  @import '../../../style/input.less';
  @import '../../../style/tooltip.less';
  .tooltip-text {
    color: #8590F5!important;
  }
  .project-uninstall-rate {
    .button-box {
      .search-button {
        height: 36px;
        line-height: 32px;
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @marginSide: 24px;
  @marginVertical: 24px;
  @projectUninstallRateMargin: @marginSide 0;
  @wrapMargin: 0 @marginSide @marginVertical;
  @wrapPadding: 20px 24px;
  @filterBoxWidthWrapMargin: 0 @marginSide 20px;
  /* Need modification */
  @chartWrapWidth: calc(~'100% - 48px');
  @chartWrapHeight: 396px;
  @chartWrapBgColor: #ffffff;
  @chartWrapBorderRadius: 12px;
  @tableWrapWidth: calc(~'100% - 48px');
  @wrapBgColor: #ffffff;
  @wrapBorderRadius: 12px;
  @wrapBoxSizing: border-box;
  @pageHeight: 48px;
  @titleMargin: 0 0 20px 0;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .title {
    display: inline-block;
    width: 100%;
    margin: @titleMargin;
    .h2Title();
  }
  .project-uninstall-rate {
    margin: @projectUninstallRateMargin;
    .content-header-wrap {
      margin: @wrapMargin;
    }
    .filter-box-wrap {
      margin: @filterBoxWidthWrapMargin;
      padding: @filterBoxWrapPadding;
      background: #FFFFFF;
      border-radius: @borderRadius;
      border: 1px solid #EAEBF7;
      .filter-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .select-box {
          width: 200px;
          margin: 0 1% 12px 0;
        }
      }
    }
    .filter-box-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .select-box {
        width: 200px;
      }
    }
    .chart-wrap {
      width: @chartWrapWidth;
      height: @chartWrapHeight;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @chartWrapBgColor;
      border-radius: @borderRadius;
      border: @boxBorder;
      .chart {
        position: relative;
        width: 100%;
        height: calc(~'100% - 62px');
        border-radius: @chartWrapBorderRadius;
      }
    }
    .table-wrap {
      width: @tableWrapWidth;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @wrapBgColor;
      border-radius: @borderRadius;
      box-sizing: @wrapBoxSizing;
      border: @boxBorder;
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title {
          width: auto;
          margin: 0;
          line-height: 22px;
        }
      }
      .table {
        position: relative;
      }
      .page-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        height: @pageHeight;
      }
    }
  }
</style>

import { replacePathParam } from '../apiToolFunctions';
import urls from '../urls';
import { postRequest } from '../index';

export default {
  getAdsPlatformList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsPlatformListURL);
    return postRequest(url, params);
  },
  getAdsGroupList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsGroupListURL);
    return postRequest(url, params);
  },
  getAdsPlanList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsPlanListURL);
    return postRequest(url, params);
  },
  /**
   * Get ads overview table
   * @param projectId
   * @param params
   * @return {Promise<AxiosResponse<T>>}
   */
  getAdsOverviewTableData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsOverviewTableDataURL);
    return postRequest(url, params);
  },
  getAdsProfitTableData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsProfitTableDataURL);
    return postRequest(url, params);
  },
  getAdsProfitLineData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsProfitLineDataURL);
    return postRequest(url, params);
  },
  getAdsRevenueCostLineData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRevenueCostLineDataURL);
    return postRequest(url, params);
  },
  getAdsRetentionLineData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRetentionLineDataURL);
    return postRequest(url, params);
  },
  getAdsRevenueSaleLineData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRevenueSaleLineDataURL);
    return postRequest(url, params);
  },
  getAdsAnalysisDauLineData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsAnalysisDauLineDataURL);
    return postRequest(url, params);
  },
  getAdsSaleRevenueBarData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsSaleRevenueBarDataURL);
    return postRequest(url, params);
  },
  getAdsRevenueDistributePieData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRevenueDistributePieDataURL);
    return postRequest(url, params);
  },
  getAdsROITableData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsROITableDataURL);
    return postRequest(url, params);
  },
  getAdsRetentionData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRetentionDataURL);
    return postRequest(url, params);
  },
  getAdsRevenueTableData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRevenueTableDataURL);
    return postRequest(url, params);
  },
  getAdsRevenueBarData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsRevenueBarDataURL);
    return postRequest(url, params);
  },
  getAdsAdsRevenueBarData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getAdsAdsRevenueBarDataURL);
    return postRequest(url, params);
  },
};

<template>
  <div class="test-item">
    <div class="test-info">
      <h1 class="test-name" @click="jumpToOverview">{{ test.test_name }}</h1>
      <p class="test-date">{{ test.test_date }}</p>
      <p class="test-desc">{{ getTestUserNumberDetail }}</p>
    </div>
    <div class="test-result" :class="getResultClass">
      <img class="result-icon" :src="getResultIcon">
      <span class="result-desc">{{ getResultDescription }}</span>
    </div>
    <div class="operation">
      <img src="../assets/image/ic_option.png"
           alt="Option"
           class="test-option"
           @click.stop="showEditBox">
      <div v-if="edit_box_visible" class="edit-box-wrap">
        <project-list-edit-box
          :edit_text="showEdit"
          @on-edit="itemEditHandler()"
          @on-delete="itemDeleteHandler()"
          @on-hide="itemBlurHandler()"></project-list-edit-box>
    </div>
    </div>
  </div>
</template>

<script>
import balanceIcon from '../assets/image/ic_ab_balance.png';
import leadIcon from '../assets/image/ic_ab_lead.png';
import insufficient from '../assets/image/ic_ab_insufficient@2x.png';
import ProjectListEditBox from './ProjectListEditBox.vue';

export default {
  name: 'ABTestItem',
  components: { ProjectListEditBox },
  props: {
    test: Object,
    current_test_list_name: String,
  },
  data() {
    return {
      edit_box_visible: false,
      result_obj: {
        class_name: [
          'test-result-obvious',
          'test-result-not-obvious',
          'test-result-insufficient',
        ],
        icon: [
          leadIcon,
          balanceIcon,
          insufficient,
        ],
        description: [
          '测试效果明显',
          '测试效果不明显',
          '测试数据不足',
        ],
      },
    };
  },
  methods: {
    getVariantName(code) {
      const variantName = code === 0
        ? '基准变体'
        : `Variant ${String.fromCharCode(code + 64)}`;
      return variantName;
    },
    getResultByParam(param) {
      const status = this.test.test_result;
      return this.result_obj[param][status];
    },
    jumpToOverview() {
      this.$emit('on-jump');
    },
    itemEditHandler() {
      console.log('ABTest item edit');
      this.edit_box_visible = false;
      // Confirm modal
      this.$emit('on-edit');
    },
    itemDeleteHandler() {
      console.log('ABTest item delete');
      this.edit_box_visible = false;
      // Confirm modal
      this.$emit('on-delete');
    },
    itemBlurHandler() {
      this.edit_box_visible = false;
    },
    showEditBox() {
      this.edit_box_visible = true;
    },
  },
  computed: {
    showEdit() {
      return this.current_test_list_name === 'running_test_list'
        ? '终止'
        : 'empty';
    },
    getTestUserNumberDetail() {
      const number = this.test.test_user_number
        ? this.test.test_user_number
        : 0;
      return `远程配置·${number}名用户`;
    },
    getResultClass() {
      return this.getResultByParam('class_name');
    },
    getResultIcon() {
      return this.getResultByParam('icon');
    },
    getResultDescription() {
      let text = this.getResultByParam('description');
      if (this.test.test_result === 0) {
        const variantName = this.getVariantName(this.test.priority_code[0]);
        text = `${variantName} ${text}`;
      }
      return text;
    },
  },
};
</script>

<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @testItemBgColor: #ffffff;
  @testInfoMargin: 24px 24px 16px;
  @testNameMargin: 0 0 16px 0;
  @testDateMargin: 0 0 4px 0;
  @testResultHeight: 56px;
  @testResultObviousColor: rgba(33, 154, 123, 1);
  @testResultNotObviousColor: rgba(134, 141, 209, 1);
  @testResultInsufficientColor: rgba(249, 77, 113, 1);
  @testResultObviousBgColor: #e3f2ee;
  @testResultNotObviousBgColor: #f0f1f9;
  @testResultInsufficientBgColor: #fee9ed;
  @optionWidth: 20px;
  @optionTop: 24px;
  @optionRight: 24px;
  @editBoxWrapWidth: 60px;
  @editBoxBgColor: rgba(255, 255, 255, 0.8);
  @editBoxBorderRadius: 6px;
  .title {
    line-height: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #202444;
  }
  .detail {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #878AA2;
  }
  .test-item {
    position: relative;
    width: 100%;
    background-color: @testItemBgColor;
    border-radius: @borderRadius;
    overflow: hidden;
    border: @boxBorder;
    .test-info {
      margin: @testInfoMargin;
      .test-name {
        .title();
        width: 88%;
        margin: @testNameMargin;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .test-date, .test-desc {
        .detail();
      }
      .test-date {
        margin: @testDateMargin;
      }
    }
    .test-result {
      height: @testResultHeight;
      line-height: @testResultHeight;
      img, span {
        vertical-align: middle;
      }
      img {
        margin: 0 4px 0 24px;
      }
      span {
        margin-left: 4px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .test-result-obvious {
      background-color: @testResultObviousBgColor;
      color: @testResultObviousColor;
    }
    .test-result-not-obvious {
      background-color: @testResultNotObviousBgColor;
      color: @testResultNotObviousColor;
    }
    .test-result-insufficient {
      background-color: @testResultInsufficientBgColor;
      color: @testResultInsufficientColor;
    }
    .operation {
      position: absolute;
      top: @optionTop;
      right: @optionRight;
      .test-option {
        width: @optionWidth;
        cursor: pointer;
      }
      .edit-box-wrap {
        position: absolute;
        bottom: 0;
        right: 6px;
        transform: translateY(100%);
        width: @editBoxWrapWidth;
        background-color: @editBoxBgColor;
        border-radius: @editBoxBorderRadius;
        box-shadow: 0px 2px 8px 0px rgba(13,17,52,0.08);
        overflow: hidden;
      }
    }
  }
</style>

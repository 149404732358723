<template>
  <div class="filter-box">
    <div class="left-box">
      <div class="select-box" v-if="filter_visible.date">
        <DatePicker
          class="time-filter"
          type="daterange"
          :options="date_picker_option"
          :clearable="false"
          v-model="filter.time"
          placeholder="日期"
          style="width: 200px">
        </DatePicker>
      </div>
      <div class="select-box" v-if="filter_visible.country">
        <complex-multiple-select
          :data="filter.country"
          option_enabled
          :checkbox_items="country_list"
          placeholder="国家或地区"
          :value_param="country_value"
          @list-change="countryChangeHandler"
          @option-change="countryOptionChangeHandler">
        </complex-multiple-select>
      </div>
      <div class="select-box" v-if="filter_visible.network">
        <complex-multiple-select
          :data="filter.network"
          :option_enabled="false"
          :checkbox_items="network_list"
          label_param="label"
          placeholder="网络"
          @list-change="networkChangeHandler">
        </complex-multiple-select>
      </div>
      <div class="select-box" v-if="filter_visible.campaign">
        <complex-multiple-select
          :data="filter.campaign"
          :checkbox_items="campaign_list"
          :option_enabled="false"
          label_param="label"
          placeholder="广告系列"
          @list-change="campaignChangeHandler">
        </complex-multiple-select>
      </div>
      <div class="refresh-box"
           @click="refreshFilter">
        <Icon :class="{'spin': button_loading && !button_click}" type="md-refresh" size="24"/>
      </div>
    </div>
    <div class="right-box">
      <div class="button-box">
        <Button class="search-button"
                type="primary"
                :disabled="button_loading"
                @click="search">
          <img v-if="button_loading && button_click"
               src="../assets/image/ic_button_loading@2x.png"
               alt="Button loading">
          <span>查询</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import ComplexMultipleSelect from './ComplexMultipleSelect.vue';

export default {
  name: 'FilterBox',
  props: {
    button_loading: {
      type: Boolean,
    },
    filter: {
      type: Object,
    },
    country_list: {
      type: Array,
    },
    network_list: {
      type: Array,
    },
    campaign_list: {
      type: Array,
    },
    filter_visible: {
      type: Object,
      default() {
        return {
          date: true,
          country: true,
          network: true,
          campaign: true,
        };
      },
    },
    country_value: {
      type: String,
      default() {
        return 'value';
      },
    },
  },
  components: { ComplexMultipleSelect },
  data() {
    return {
      button_click: true,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
          {
            text: '最近60天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 60);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
    };
  },
  methods: {
    search() {
      this.$emit('search');
    },
    countryChangeHandler(checkedGroup) {
      this.filter.country = [...checkedGroup];
    },
    countryOptionChangeHandler(checkedOption) {
      this.filter.country_list_exclude = checkedOption;
    },
    networkChangeHandler(checkGroup) {
      this.filter.network = [...checkGroup];
    },
    campaignChangeHandler(checkGroup) {
      this.filter.campaign = [...checkGroup];
    },
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    refreshFilter() {
      this.filter.country = [];
      this.filter.country_list_exclude = 0;
      this.filter.network = [];
      this.filter.campaign = [];
      this.initTimeFilter();
      this.button_click = false;
      this.$emit('search');
    },
  },
  watch: {
    button_loading(newVal) {
      if (!newVal) {
        this.button_click = true;
      }
    },
  },
};
</script>
<style lang="less">
  .filter-box {
    .button-box {
      .search-button {
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .filter-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .left-box {
      flex: 0 0 88%;
      display: flex;
      align-items: center;
      /*margin-bottom: -12px;*/
    }
    .right-box {
      flex: 0 0 12%;
      display: flex;
      flex-direction: row-reverse;
    }
    .select-box {
      width: 200px;
      margin: 0 1% 0 0;
    }
    .refresh-box {
      cursor: pointer;
    }
    .spin {
      transform-origin: center;
      animation: spin 0.4s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>

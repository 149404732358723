<template>
  <Modal
      class="create-mark-modal"
      :value="modal_visible"
      :closable="false"
      width="400"
      @on-ok="okHandler"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
    </div>
    <div class="main">
      <mark-input
        :mark="mark"></mark-input>
    </div>
    <div class="footer" slot="footer">
<!--      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>-->
<!--      <Button class="footer-btn" type="primary" @click="okHandler">创建</Button>-->
      <LoadingButton
        class="footer-btn"
        :button_loading="button_loading"
        type="default"
        text="取消"
        @on-click="cancelHandler"></LoadingButton>
      <LoadingButton
        class="footer-btn"
        :button_loading="button_loading"
        :show_loading="true"
        type="primary"
        text="创建"
        @on-click="okHandler"></LoadingButton>
    </div>
  </Modal>
</template>

<script>
import MarkInput from '../MarkInput.vue';
import LoadingButton from '../LoadingButton.vue';

export default {
  name: 'CreateMarkModal',
  components: { LoadingButton, MarkInput },
  props: {
    modal_visible: Boolean,
  },
  data() {
    return {
      button_loading: false,
      header_title: '添加标注',
      mark_types: [
        {
          value: 'version_update',
          label: '版本更新',
        },
        {
          value: 'advertising_monetization',
          label: '广告变现',
        },
        {
          value: 'purchase_amount',
          label: '买量',
        },
        {
          value: 'others',
          label: '其它',
        },
      ],
      mark: {
        date: null,
        type: null,
        visible_for_everyone: false,
        content: null,
      },
    };
  },
  methods: {
    check() {
      const { mark } = this;
      // eslint-disable-next-line no-restricted-syntax
      for (const val of Object.values(mark)) {
        if (val === null) return false;
      }
      return true;
    },
    okHandler() {
      if (!this.check()) {
        this.$Message.warning('输入不能为空');
        return;
      }
      const mark = { ...this.mark };
      mark.date = this.$tools.dateFormat(mark.date);
      // Send creating mark request
      const params = {
        mark,
        user_id: this.user_id,
      };
      this.button_loading = true;
      this.$api.createMark(this.project_id, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('标注创建成功');
            this.$emit('on-create');
            return Promise.resolve();
          }
          return Promise.reject(new Error('创建失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          this.button_loading = false;
        });
    },
    cancelHandler() {
      Object.assign(this.$data, this.$options.data());
      this.$emit('on-cancel');
    },
  },
  computed: {
    user_id() {
      return this.$store.state.user_id;
    },
    project_id() {
      return this.$route.params.project_id;
    },
  },
};
</script>

<style scoped lang="less">
  .create-mark-modal {
    .header {
      padding: 20px 32px;
      .header-title {
        font-size: 20px;
        font-weight: 600;
        color: #202444;
        line-height: 28px;
      }
    }
    .main {
      padding: 0 32px;
    }
    .footer {
      padding: 0px 32px 20px;
    }
  }
</style>

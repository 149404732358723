<template>
  <div class="project-menu menu">
    <div v-show="!is_expanded" class="unexpanded-menu">
      <li class="menu-item"
          :class="{'selected-item': selected_item_name === item.name}"
          v-for="item in menu_list"
          :key="item.name"
          @click="selectItem(item)">
        <Dropdown trigger="hover" placement="right">
          <img class="item-icon"
               :src="selected_item_name === item.name ? item.selected_icon : item.icon"
               alt="Item Icon">
          <DropdownMenu slot="list">
            <DropdownItem :selected="selected_item_name === item.name">
              {{ item.label }}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
    </div>
    <div v-show="is_expanded" class="expanded-menu">
      <li class="menu-item"
          :class="{'selected-item': selected_item_name === item.name}"
          v-for="item in menu_list"
          :key="item.name"
          @click="selectItem(item)">
        <img class="item-icon"
             :src="selected_item_name === item.name ? item.selected_icon : item.icon"
             alt="Item Icon">
        <span class="item-name">{{ item.label }}</span>
      </li>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import menuMixin from './menuMixin';
import icon1 from '../../../../assets/image/ic_sidebar_overview@2x.png';
import icon12 from '../../../../assets/image/ic_sidebar_selected_overview@2x.png';
import icon2 from '../../../../assets/image/ic_sidebar_keep@2x.png';
import icon22 from '../../../../assets/image/ic_sidebar_selected_keep@2x.png';
import icon3 from '../../../../assets/image/ic_sidebar_uninstall@2x.png';
import icon32 from '../../../../assets/image/ic_sidebar_selected_uninstall@2x.png';
import icon4 from '../../../../assets/image/ic_sidebar_revenue@2x.png';
import icon42 from '../../../../assets/image/ic_sidebar_selected_revenue@2x.png';
import icon5 from '../../../../assets/image/ic_sidebar_test@2x.png';
import icon52 from '../../../../assets/image/ic_sidebar_selected_test@2x.png';
import isExpandedMixin from '../isExpandedMixin';

export default {
  name: 'ProjectMenu',
  data() {
    return {
      project_id: null,
      menu_list: [
        {
          label: '广告概览',
          icon: icon1,
          selected_icon: icon12,
          name: 'ads_overview',
        },
        {
          label: '留存',
          icon: icon2,
          selected_icon: icon22,
          name: 'ads_retention',
        },
        {
          label: 'ROI',
          icon: icon3,
          selected_icon: icon32,
          name: 'ads_roi',
        },
        {
          label: '收入',
          icon: icon4,
          selected_icon: icon42,
          name: 'ads_revenue',
        },
        // {
        //   label: '概览',
        //   icon: icon1,
        //   selected_icon: icon12,
        //   name: 'overview',
        // },
        // {
        //   label: '留存',
        //   icon: icon2,
        //   selected_icon: icon22,
        //   name: 'keep',
        // },
        // {
        //   label: '卸载率',
        //   icon: icon3,
        //   selected_icon: icon32,
        //   name: 'uninstall_rate',
        // },
        // {
        //   label: '收入',
        //   icon: icon4,
        //   selected_icon: icon42,
        //   name: 'revenue',
        // },
        // {
        //   label: 'A/B 测试',
        //   icon: icon5,
        //   selected_icon: icon52,
        //   name: 'ab_test',
        // },
      ],
    };
  },
  mixins: [
    menuMixin,
    isExpandedMixin,
  ],
  methods: {
    // checkMenuItem(path, array) {
    //   // eslint-disable-next-line no-restricted-syntax
    //   for (const item of array) {
    //     if (path.includes(item)) {
    //       return item;
    //     }
    //   }
    //   return null;
    // },
    // selectItem(item) {
    //   this.selected_item_name = item.name;
    //   const route = {
    //     path: `/${this.project_id}/${item.name}`,
    //   };
    //   this.$router.push(route);
    // },
  },
  mounted() {
    this.project_id = this.$route.params.project_id;
  },
};
</script>

<style scoped lang="less">
  @import "../../../../style/sidebarMenu.less";
</style>

<template>
  <div class="project-revenue" ref="projectRevenue">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title"
        @on-mark-create-succeed="markListRefreshHandler(false, drawChartFnList, revenue_chart_data)"
        @on-mark-edit-succeed="markListRefreshHandler(false, drawChartFnList, revenue_chart_data)"
        @on-mark-delete-succeed="
        markListRefreshHandler(false, drawChartFnList, revenue_chart_data)
      "></content-header>
    </div>
    <div class="filter-box-wrap">
      <filter-box
        :button_loading="button_loading"
        :filter="filter"
        :country_list="country_list"
        :network_list="network_list"
        :campaign_list="campaign_list"
        country_value="label"
        @search="search"></filter-box>
    </div>
    <div class="bar-chart-wrap">
      <h2 class="title">{{ bar_chart_title }}</h2>
      <div class="chart" ref="bar_chart"></div>
    </div>
    <div class="line-charts-wrap">
      <div class="line-chart-wrap">
        <h2 class="title">{{ line_chart_1_title }}</h2>
        <div class="chart" ref="line_chart_1"></div>
      </div>
      <div class="line-chart-wrap">
        <h2 class="title">{{ line_chart_2_title }}</h2>
        <div class="chart" ref="line_chart_2"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase,max-len */

import ContentHeader from '../../../components/ContentHeader.vue';
// import ComplexMultipleSelect from '../../../components/ComplexMultipleSelect.vue';
import routerTriggerMixin from './routerTriggerMixin';
import FilterBox from '../../../components/FilterBox.vue';
import filterMixin from './filterMixin';
import markMixin from './markMixin';

export default {
  name: 'ProjectRevenue',
  components: { FilterBox, ContentHeader },
  data() {
    return {
      chart_data_name: 'revenue_chart_data',
      header_title: '内购收入',
      bar_chart_title: 'GooglePlay',
      line_chart_1_title: '订阅支付状态按日展开',
      line_chart_2_title: '订阅转化率',
      button_loading: false,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      bar_chart_data: {
        x_axis: [1, 2, 3, 4, 5],
        subscription: {
          name: '订阅',
          // data: [21, 34, 43, 66, 88],
          color: 'rgba(116, 129, 244, 1)',
        },
        inapp: {
          name: 'APP内购',
          // data: [32, 34, 53, 53, 69],
          color: 'rgba(62, 196, 162, 1)',
        },
      },
      line_chart_1_data: {
        x_axis: [1, 2, 3, 4, 5, 6],
        completed: {
          name: '已完成',
          // data: [6, 334, 54, 324, 66, 144],
          color: 'rgba(51, 182, 149, 1)',
        },
        failed: {
          name: '失败',
          // data: [45, 354, 231, 34, 54, 243],
          color: 'rgba(250, 119, 146, 1)',
        },
        free_trial: {
          name: '免费试用',
          // data: [432, 232, 24, 243, 233, 20],
          color: 'rgba(38, 171, 238, 1)',
        },
        pending: {
          name: '待定',
          // data: [123, 54, 45, 232, 55, 436],
          color: 'rgba(247, 153, 21, 1)',
        },
        pending_deferred: {
          name: '待延期',
          // data: [24, 432, 123, 434, 45, 24],
          color: 'rgba(156, 172, 14, 1)',
        },
      },
      line_chart_2_data: {
        x_axis: [1, 2, 3, 4, 5, 6],
        conversion_rate: {
          name: '转化率',
          // data: [0.43345, 0.666666, 0.2345, 0.42342, 0.12324],
          color: 'rgba(116, 129, 244, 1)',
        },
        total_count: {
          name: '总计',
          // data: [2343, 545, 2343, 3443, 5464, 2344],
          color: 'rgba(156, 172, 14, 1)',
        },
        completed_count: {
          name: '完成数',
          // data: [234, 344, 656, 234, 453, 234],
          color: 'rgba(62, 196, 162, 1)',
        },
        free_trial_count: {
          name: '免费试用数',
          // data: [234, 23, 43, 12, 54, 66],
          color: 'rgba(61, 182, 242, 1)',
        },
      },
      revenue_chart_data: null,
    };
  },
  mixins: [routerTriggerMixin, filterMixin, markMixin],
  methods: {
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    dataCallback(responseData, nameList, chartData, xAxisList) {
      // eslint-disable-next-line no-param-reassign
      chartData.x_axis = [...xAxisList];
      // eslint-disable-next-line camelcase,no-restricted-syntax
      for (const name of nameList) {
        let tempArr = responseData[name];
        if (name.indexOf('rate') !== -1) {
          // eslint-disable-next-line no-param-reassign
          chartData[name].data = [...tempArr];
        } else {
          tempArr = tempArr.map((val) => Math.round(val * 100) / 100);
          // eslint-disable-next-line no-param-reassign
          chartData[name].data = [...tempArr];
        }
      }
    },
    fetchData() {
      this.fetchFilterList();
      this.fetchChartData();
      // this.fetchRevenueData();
      // this.fetchRevenuePaymentData();
      // this.fetchRevenueConversionRateData();
    },
    async fetchChartData() {
      const instance1 = this.$loading(this.$refs.bar_chart);
      const instance2 = this.$loading(this.$refs.line_chart_1);
      const instance3 = this.$loading(this.$refs.line_chart_2);
      try {
        const responseList = await Promise.all([
          this.fetchRevenueData(),
          this.fetchRevenuePaymentData(),
          this.fetchRevenueConversionRateData(),
        ]);
        console.log('response');
        console.log(responseList);
        for (let i = 0; i < responseList.length; i += 1) {
          const response = responseList[i];
          let chartData;
          let nameList;
          let dateList;
          if (i === 0) {
            const { sale_types, date_list } = response.data;
            chartData = this.bar_chart_data;
            nameList = sale_types;
            dateList = date_list;
            this.revenue_chart_data = chartData;
          }
          if (i === 1 || i === 2) {
            const { payment_states, date_list } = response.data;
            chartData = this[`line_chart_${i}_data`];
            nameList = payment_states;
            dateList = date_list;
            this.revenue_chart_data = chartData;
          }
          this.dataCallback(response.data, nameList, chartData, dateList);
        }
        await this.markListRefreshHandler(false, this.drawChartFnList, this.revenue_chart_data);
      } catch (error) {
        console.log(error);
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
        instance3.hide();
      }
    },
    fetchRevenueData() {
      const params = this.dataParams;
      return this.$api.getRevenueData(this.project_id, params);
    },
    fetchRevenuePaymentData() {
      const params = this.dataParams;
      return this.$api.getRevenuePaymentData(this.project_id, params);
    },
    fetchRevenueConversionRateData() {
      const params = this.dataParams;
      return this.$api.getRevenueConversionRateData(this.project_id, params);
    },
    search() {
      this.button_loading = true;
      this.fetchData();
    },
    drawBarChart() {
      const chartElement = this.$refs.bar_chart;
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const markLines = [];
      if (this.revenue_chart_data.mark_lines) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.revenue_chart_data.mark_lines) {
          const obj = {
            xAxis: item.xAxis,
          };
          markLines.push(obj);
        }
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: this.$tooltipFormatter([], this.revenue_chart_data.mark_lines, {
            showTotal: true,
          }),
          renderMode: 'html',
          backgroundColor: 'opacity',
        },
        legend: {
          top: '1%',
          left: '0',
          icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
        },
        grid: {
          left: 36,
          top: 60,
          right: 24,
          bottom: 18,
        },
        xAxis: {
          axisLabel: {
            margin: 8,
            color: 'rgba(135, 138, 162, 1)',
            showMaxLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(233, 234, 247, 1)',
            },
          },
          axisPointer: {
            snap: true,
            type: 'line',
            lineStyle: {
              width: 0,
              color: '#E6E7F5',
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: true,
          data: this.bar_chart_data.x_axis,
        },
        yAxis: {
          axisLabel: {
            margin: 16,
            fontSize: '12px',
            color: 'rgba(135, 138, 162, 1)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgb(245, 245, 252)',
            },
          },
        },
        series: [
          {
            name: this.bar_chart_data.subscription.name,
            type: 'bar',
            data: this.bar_chart_data.subscription.data,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(51, 182, 149, 1)' },
                  { offset: 1, color: 'rgba(98, 220, 199, 1)' },
                ],
              ),
            },
            stack: 'bar',
            barMaxWidth: 72,
            label: {
              show: true,
              color: 'rgba(84, 88, 120, 1)',
            },
            markLine: {
              symbol: ['none', 'none'],
              lineStyle: {
                width: 0.5,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                },
                lineStyle: {
                  type: 'dotted',
                  width: 1,
                },
              },
              data: markLines,
            },
          },
          {
            name: this.bar_chart_data.inapp.name,
            type: 'bar',
            data: this.bar_chart_data.inapp.data,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(116, 129, 244, 1)' },
                  { offset: 1, color: 'rgba(172, 183, 250, 1)' },
                ],
              ),
            },
            stack: 'bar',
            barMaxWidth: 72,
            label: {
              show: true,
              color: 'rgba(84, 88, 120, 1)',
            },
          },
        ],
      };
      chart.setOption(option);
    },
    drawChart() {},
    drawLineChart1() {
      console.log('drawLineChart1');
      const chartElement = this.$refs.line_chart_1;
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const markLines = [];
      if (this.revenue_chart_data.mark_lines) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.revenue_chart_data.mark_lines) {
          const obj = {
            xAxis: item.xAxis,
          };
          markLines.push(obj);
        }
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
          formatter: this.$tooltipFormatter([], this.revenue_chart_data.mark_lines),
          renderMode: 'html',
          backgroundColor: 'opacity',
        },
        legend: {
          top: '0.5%',
          left: 0,
          icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
        },
        grid: {
          left: 36,
          top: 60,
          right: 24,
          bottom: 18,
        },
        xAxis: {
          axisLabel: {
            margin: 8,
            color: 'rgba(135, 138, 162, 1)',
            showMaxLabel: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(233, 234, 247, 1)',
            },
          },
          axisPointer: {
            snap: true,
            type: 'line',
            lineStyle: {
              width: 2,
              color: '#E6E7F5',
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          data: this.line_chart_1_data.x_axis,
        },
        yAxis: {
          axisLabel: {
            margin: 16,
            fontSize: '12px',
            color: 'rgba(135, 138, 162, 1)',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgb(245, 245, 252)',
            },
          },
        },
        series: [
          {
            name: this.line_chart_1_data.completed.name,
            type: 'line',
            data: this.line_chart_1_data.completed.data,
            itemStyle: {
              color: this.line_chart_1_data.completed.color,
            },
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
            markLine: {
              symbol: ['none', 'none'],
              lineStyle: {
                width: 0.5,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                },
                lineStyle: {
                  type: 'dotted',
                  width: 1,
                },
              },
              data: markLines,
            },
          },
          {
            name: this.line_chart_1_data.failed.name,
            type: 'line',
            data: this.line_chart_1_data.failed.data,
            itemStyle: {
              color: this.line_chart_1_data.failed.color,
            },
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
          },
          {
            name: this.line_chart_1_data.free_trial.name,
            type: 'line',
            data: this.line_chart_1_data.free_trial.data,
            itemStyle: {
              color: this.line_chart_1_data.free_trial.color,
            },
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
          },
        ],
      };
      chart.setOption(option);
    },
    drawLineChart2() {
      console.log('drawLineChart');
      const chartElement = this.$refs.line_chart_2;
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const markLines = [];
      if (this.revenue_chart_data.mark_lines) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.revenue_chart_data.mark_lines) {
          const obj = {
            xAxis: item.xAxis,
          };
          markLines.push(obj);
        }
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
          formatter: this.$tooltipFormatter(['rate'], this.revenue_chart_data.mark_lines),
          renderMode: 'html',
          backgroundColor: 'opacity',
        },
        legend: {
          top: '1%',
          left: 0,
          icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
        },
        grid: {
          left: 42,
          top: 60,
          right: 36,
          bottom: 18,
        },
        xAxis: {
          axisLabel: {
            margin: 8,
            color: 'rgba(135, 138, 162, 1)',
            showMaxLabel: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(233, 234, 247, 1)',
            },
          },
          axisPointer: {
            snap: true,
            type: 'line',
            lineStyle: {
              width: 2,
              color: '#E6E7F5',
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: true,
          data: this.line_chart_1_data.x_axis,
        },
        yAxis: [
          {
            name: '转化率',
            type: 'value',
            axisLabel: {
              margin: 12,
              fontSize: '12px',
              color: 'rgba(135, 138, 162, 1)',
              formatter: (value) => this.$tools.percentageFormat(value),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: 'rgb(245, 245, 252)',
              },
            },
          },
          {
            name: '总计',
            type: 'value',
            axisLabel: {
              margin: 16,
              fontSize: '12px',
              color: 'rgba(135, 138, 162, 1)',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.line_chart_2_data.conversion_rate.name,
            type: 'line',
            data: this.line_chart_2_data.conversion_rate.data,
            itemStyle: {
              color: this.line_chart_2_data.conversion_rate.color,
            },
            yAxisIndex: 0,
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
            markLine: {
              symbol: ['none', 'none'],
              lineStyle: {
                width: 0.5,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                },
                lineStyle: {
                  type: 'dotted',
                  width: 1,
                },
              },
              data: markLines,
            },
          },
          {
            name: this.line_chart_2_data.total_count.name,
            type: 'bar',
            data: this.line_chart_2_data.total_count.data,
            itemStyle: {
              color: this.line_chart_2_data.total_count.color,
            },
            yAxisIndex: 1,
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
          },
          {
            name: this.line_chart_2_data.completed_count.name,
            type: 'bar',
            data: this.line_chart_2_data.completed_count.data,
            itemStyle: {
              color: this.line_chart_2_data.completed_count.color,
            },
            yAxisIndex: 1,
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
          },
          {
            name: this.line_chart_2_data.free_trial_count.name,
            type: 'bar',
            data: this.line_chart_2_data.free_trial_count.data,
            itemStyle: {
              color: this.line_chart_2_data.free_trial_count.color,
            },
            yAxisIndex: 1,
            symbol: 'emptyCircle',
            showSymbol: false,
            showAllSymbol: false,
          },
        ],
      };
      chart.setOption(option);
    },
    countryChangeHandler(checkedGroup) {
      this.filter.country = [...checkedGroup];
    },
    countryOptionChangeHandler(checkedOption) {
      this.filter.country_list_exclude = checkedOption;
    },
    networkChangeHandler(checkGroup) {
      this.filter.network = [...checkGroup];
    },
    campaignChangeHandler(checkGroup) {
      this.filter.campaign = [...checkGroup];
    },
  },
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    dataParams() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        country_list: this.filter.country,
        country_list_exclude: this.filter.country_list_exclude,
        network_list: this.filter.network,
        campaign_list: this.filter.campaign,
      };
      return params;
    },
    drawChartFnList() {
      return [this.drawBarChart, this.drawLineChart1, this.drawLineChart2];
    },
  },
  mounted() {
    this.initTimeFilter();
    this.fetchData();
  },
};
</script>
<style lang="less">
  .project-revenue {
    .bar-chart-wrap {
      @import url('../../../style/revenueTooltip.less');
    }
    .line-chart-wrap:nth-child(1) {
      @import url('../../../style/paymentTooltip.less');
    }
    .line-chart-wrap:nth-child(2) {
      @import url('../../../style/conversionRateTooltip.less');
    }
    .button-box {
      .search-button {
        height: 36px;
        line-height: 32px;
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @marginSide: 24px;
  @contentHeaderWrapMargin: @marginSide;
  @filterBoxWrapMargin: 0 @marginSide 24px;
  @wrapMargin: 0 @marginSide @marginSide;
  @chartWrapHeight: 384px;
  @titleMargin: 0 0 20px 0;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .project-revenue {
    .content-header-wrap {
      margin: @contentHeaderWrapMargin;
    }
    .filter-box-wrap {
      margin: @filterBoxWrapMargin;
      padding: @filterBoxWrapPadding;
      background: #FFFFFF;
      border-radius: @borderRadius;
      border: 1px solid #EAEBF7;
      .filter-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: #FFFFFF;
        border-radius: 4px;
        .select-box {
          width: 200px;
          margin: 0 1% 12px 0;
        }
      }
    }
    .title {
      display: inline-block;
      width: 100%;
      margin: @titleMargin;
      .h2Title();
    }
    .bar-chart-wrap {
      width: calc(~'100% - 48px');
      height: @chartWrapHeight;
      margin: @wrapMargin;
      padding: 20px 24px 24px;
      background-color: #ffffff;
      border-radius: @borderRadius;
      border: @boxBorder
    }
    .line-charts-wrap {
      margin: @wrapMargin;
      .line-chart-wrap {
        display: inline-block;
        width: calc(~'50% - 12px');
        height: @chartWrapHeight;
        padding: 20px 24px 24px;
        background-color: #ffffff;
        border-radius: @borderRadius;
        border: @boxBorder;
      }
      .line-chart-wrap:first-child {
        margin-right: @marginSide;
      }
    }
    .chart {
      position: relative;
      width: 100%;
      height: calc(~'100% - 60px');
    }
  }
</style>

import Vue from 'vue';
import LoadingComponent from '../components/Loading.vue';

export default {
  loading: (parent, backgroundColor = 'rgb(255, 255, 255)') => {
    const LoadingConstructor = Vue.extend(LoadingComponent);
    const div = document.createElement('div');
    const loadingInstance = new LoadingConstructor({
      propsData: {
        background_color: backgroundColor,
      },
    });
    if (parent) {
      parent.appendChild(div);
      loadingInstance.$mount(div);
    }
    return loadingInstance;
  },
};

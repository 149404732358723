import leadIcon from '../../../assets/image/ic_ab_lead.png';
import balanceIcon from '../../../assets/image/ic_ab_balance.png';
import insufficient from '../../../assets/image/ic_ab_insufficient@2x.png';
import leadIconBig from '../../../assets/image/ic_ab_lead_big@2x.png';
import balanceIconBig from '../../../assets/image/ic_ab_balance_big@2x.png';
import insufficientBig from '../../../assets/image/ic_ab_insufficient_big@2x.png';

export default {
  data() {
    return {
      icon_size: 'regular',
      result_obj: {
        class_name: [
          'test-result-obvious',
          'test-result-not-obvious',
          'test-result-insufficient',
        ],
        icon: [
          leadIcon,
          balanceIcon,
          insufficient,
        ],
        icon_big: [
          leadIconBig,
          balanceIconBig,
          insufficientBig,
        ],
        description: [
          '测试效果明显',
          '测试效果不明显',
          '测试数据不足',
        ],
      },
    };
  },
  methods: {
    getResultByParam(param) {
      const status = this.test ? this.test.status : this.test_result.result;
      return this.result_obj[param][status];
    },
  },
  computed: {
    getResultClass() {
      return this.getResultByParam('class_name');
    },
    getResultIcon() {
      if (this.icon_size === 'regular') {
        return this.getResultByParam('icon');
      }
      return this.getResultByParam('icon_big');
    },
    getResultDescription() {
      let text = this.getResultByParam('description');
      if (this.test.test_status === 0) {
        const variantName = `Variant ${String.fromCharCode(this.test.variant_code + 64)}`;
        text = `${variantName} ${text}`;
      }
      return text;
    },
  },
};

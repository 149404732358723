<template>
  <div class="project-ab-test-detail">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title"
        :back="true"
        @on-mark-create-succeed="markListRefreshHandler"
        @on-mark-edit-succeed="markListRefreshHandler"
        @on-mark-delete-succeed="markListRefreshHandler"></content-header>
    </div>
    <div class="filter-box-wrap">
      <filter-box
        :button_loading="button_loading"
        :filter="filter"
        :filter_visible="filter_box_visible_obj"
        @search="search"></filter-box>
    </div>
    <div class="chart-wrap">
      <h2 class="title">{{ chart_title }}</h2>
      <div class="chart" :ref="chart_ref"></div>
    </div>
    <div class="table-wrap">
      <div class="table-header">
        <h2 class="title">{{ table_title }}</h2>
        <table-operation :columns="xlsx_columns" :table_data="xlsx_table_data"></table-operation>
      </div>
      <div v-if="type !== 'in_app_purchase'" class="heat-map-filter-box-wrap">
        <heat-map-filter-box
          :filter="table_filter"
          :filter_visible="table_filter_visible_obj"
          @on-show-number-change="showNumberChangeHandler"></heat-map-filter-box>
      </div>
      <div class="table" ref="table">
        <Table :columns="columns"
               :data="table_data"
               :row-class-name="getRowClassName"
               @on-cell-click="rowExpand"></Table>
      </div>
      <div class="page-box">
        <Page :total="total"
              :current="page_num"
              :page-size="page_size"
              @on-change="pageNumChange"
              @on-page-size-change="pageSizeChange"
              size="small" show-elevator show-sizer></Page>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '../../../components/ContentHeader.vue';
import getLineChartOption from '../../../utils/getLineChartOption';
import FilterBox from '../../../components/FilterBox.vue';
import HeatMapFilterBox from '../../../components/HeatMapFilterBox.vue';
import routerTriggerMixin from './routerTriggerMixin';
import filterMixin from './filterMixin';
import markMixin from './markMixin';
import TableOperation from '../../../components/TableOperation.vue';
import exportMixin from './exportMixin';

export default {
  name: 'ProjectABTestDetail',
  components: {
    TableOperation,
    HeatMapFilterBox,
    FilterBox,
    ContentHeader,
  },
  data() {
    return {
      chart_ref: 'ab-test-detail-chart',
      filter_box_visible_obj: {
        date: true,
        country: false,
        network: false,
        campaign: false,
      },
      table_filter_visible_obj: {
        dimension: false,
        show_number: true,
      },
      button_loading: false,
      type: null,
      table_filter: {
        dimension: 'network',
        show_number: false,
      },
      page_size: 20,
      page_num: 1,
      total: 0,
      name_map: new Map([['keep', '留存'], ['uninstall_rate', '卸载率'], ['in_app_purchase', '内购']]),
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      variant_name_list: ['基本变体', 'Variant A', 'Variant B', 'Variant C'],
      // The maximum number of variants is 4.
      chart_data: {
        name_list: ['基本变体', 'Variant A', 'Variant B', 'Variant C'],
        color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)', 'rgba(62, 196, 162, 1)', 'rgba(156, 172, 14, 1)'],
        tooltip_color: ['rgb(159,160,173)', 'rgba(75, 92, 240, 1)', 'rgba(33, 154, 123, 1)', 'rgba(134, 149, 6, 1)'],
        x_axis: [],
        y_axis: [],
      },
      total_key: 'new_users',
      columns: [
        {
          title: ' ',
          key: 'empty',
          width: 30,
          fixed: 'left',
        },
      ],
      table_data: [
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
        {
          empty: '.',
          date: '2020-1-08',
          new_users: '8943',
          1: '3445',
          2: '2343',
          3: '1564',
          4: '234',
          5: '32',
          6: '23',
        },
      ],
    };
  },
  mixins: [routerTriggerMixin, filterMixin, markMixin, exportMixin],
  methods: {
    getChartParent() {
      return this.$refs[this.chart_ref];
    },
    getTableParent() {
      return this.$refs.table;
    },
    drawChart() {
      const chartElement = this.$refs[this.chart_ref];
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const configuration = {
        x: {
          hideMaxLabel: true,
        },
        y: {
          formatter: this.type === 'in_app_purchase'
            ? 'normal'
            : 'rate',
          multiple: true,
        },
        series: {
          formatter: this.type === 'in_app_purchase'
            ? 'normal'
            : 'rate',
        },
        grid: {
          top: '30px',
          bottom: '18px',
          left: '42px',
          right: '12px',
        },
      };
      const option = getLineChartOption(this.chart_data, configuration);
      chart.setOption(option);
    },
    fetchData() {
      this.fetchMainData();
      // this.fetchFilterList();
    },
    async fetchMainData() {
      const instance1 = this.$loading(this.getChartParent());
      const instance2 = this.$loading(this.getTableParent());
      let response;
      try {
        if (this.type === 'keep') {
          response = await this.fetchTestKeepData();
          this.fetchTestKeepDataCallback(response);
        } if (this.type === 'uninstall_rate') {
          response = await this.fetchTestUninstallData();
          this.fetchTestUninstallDataCallback(response);
        } if (this.type === 'in_app_purchase') {
          response = await this.fetchTestInAppData();
          this.fetchTestInAppDataCallback(response);
        }
        await this.markListRefreshHandler();
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
        instance1.hide();
        instance2.hide();
      }
    },
    fetchTestKeepDataCallback(response) {
      const {
        result,
        header,
        // eslint-disable-next-line camelcase
        retention_reports,
        total,
      } = response.data;
      if (result === 'success') {
        if (this.columns.length > 1) {
          this.columns = [this.columns[0]];
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < header.length + 1; i++) {
          let obj;
          if (i === 1) {
            obj = {
              key: header[i - 1],
              title: '日期',
              width: 120,
              align: 'left',
              fixed: 'left',
            };
          } else if (i === 2) {
            obj = {
              key: header[i - 1],
              title: '新增用户',
              width: 96,
              align: 'left',
              fixed: 'left',
            };
          } else {
            obj = {
              key: header[i - 1],
              title: header[i - 1],
              minWidth: 96,
              align: 'center',
            };
          }
          this.columns.push(obj);
          const render = this.columnRender;
          this.columns.forEach((val) => {
            this.$set(val, 'render', render);
          });
        }
        retention_reports.forEach((val) => {
          // eslint-disable-next-line no-param-reassign
          val.empty = '.';
        });
        const chartData = [...retention_reports[0]];
        const yAxisArr = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of chartData) {
          delete item.date;
          delete item.empty;
          delete item.new_users;
          delete item.variant;
          this.$tools.formatChartData(item, this.attribute_name);
          yAxisArr.push(Object.values(item));
        }
        this.chart_data.x_axis = Object.keys(chartData[0]);
        this.chart_data.y_axis = Object.values(yAxisArr);
        const arr = [];
        retention_reports.splice(1).forEach((subArr) => {
          subArr.forEach((item, index) => {
            if (index > 0) {
              // eslint-disable-next-line no-param-reassign
              item.date = this.variant_name_list[index - 1];
            }
            arr.push(item);
          });
        });
        this.table_data = [...arr];
        this.total = total;
        this.configTable();
      }
    },
    fetchTestKeepData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        page_size: this.page_size,
        page_num: this.page_num,
      };
      return this.$api.getABTestRetentionDetail(this.project_id, this.experiment_id, params);
    },
    fetchTestUninstallDataCallback(response) {
      console.log('response callback');
      const {
        result,
        header,
        // eslint-disable-next-line camelcase
        remove_daily_report,
        // eslint-disable-next-line camelcase
        remove_reports,
        total,
      } = response.data;
      if (result === 'success') {
        if (this.columns.length > 1) {
          this.columns = [this.columns[0]];
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < header.length + 1; i++) {
          let obj;
          if (i === 1) {
            obj = {
              key: header[i - 1],
              title: '日期',
              width: 120,
              align: 'left',
              fixed: 'left',
            };
          } else if (i === 2) {
            obj = {
              key: header[i - 1],
              title: '新增用户',
              width: 96,
              align: 'left',
              fixed: 'left',
            };
          } else {
            obj = {
              key: header[i - 1],
              title: header[i - 1],
              minWidth: 96,
              align: 'center',
            };
          }
          this.columns.push(obj);
          const render = this.columnRender;
          this.columns.forEach((val) => {
            this.$set(val, 'render', render);
          });
        }
        remove_daily_report.remove_report_list.forEach((val) => {
          this.$tools.formatChartData(val, 'remove_rate');
        });
        this.chart_data.x_axis = [...remove_daily_report.date_list];
        this.chart_data.y_axis = [...remove_daily_report.remove_report_list];
        const arr = [];
        // eslint-disable-next-line no-restricted-syntax,camelcase
        for (const subArr of remove_reports) {
          subArr.forEach((item, index) => {
            if (index > 0) {
              // eslint-disable-next-line no-param-reassign
              item.date = this.variant_name_list[index - 1];
            }
            arr.push(item);
          });
        }
        // eslint-disable-next-line camelcase
        this.table_data = [...arr];
        this.total = total;
        this.configTable();
      }
    },
    fetchTestUninstallData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        page_size: this.page_size,
        page_num: this.page_num,
      };
      return this.$api.getABTestUninstallDetail(this.project_id, this.experiment_id, params);
    },
    fetchTestInAppDataCallback(response) {
      const {
        result,
        header,
        // eslint-disable-next-line camelcase
        sale_daily_report,
        // eslint-disable-next-line camelcase
        sale_reports,
        total,
      } = response.data;
      if (result === 'success') {
        // eslint-disable-next-line camelcase
        const { date_list, sale_report_list } = sale_daily_report;
        if (this.columns.length > 1) {
          this.columns = [this.columns[0]];
        }
        // eslint-disable-next-line camelcase
        this.chart_data.x_axis = date_list;
        // eslint-disable-next-line camelcase
        this.chart_data.y_axis = sale_report_list;
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < header.length + 1; i++) {
          let obj;
          if (i === 1) {
            obj = {
              key: header[i - 1],
              title: '日期',
              width: 120,
              align: 'left',
              fixed: 'left',
            };
          } else if (i === 2) {
            obj = {
              key: header[i - 1],
              title: '收入',
              width: 96,
              align: 'left',
              fixed: 'left',
            };
          } else {
            obj = {
              key: header[i - 1],
              title: header[i - 1],
              minWidth: 150,
              ellipsis: true,
              align: 'center',
              className: 'attribute-column',
            };
          }
          this.columns.push(obj);
          const render = this.columnRender;
          this.columns.forEach((val) => {
            this.$set(val, 'render', render);
          });
        }
      }
      const arr = [];
      // eslint-disable-next-line no-restricted-syntax,camelcase
      for (const subArr of sale_reports) {
        subArr.forEach((item, index) => {
          if (index > 0) {
            // eslint-disable-next-line no-param-reassign
            item.date = this.variant_name_list[index - 1];
          }
          arr.push(item);
        });
      }
      this.drawChart();
      this.total = total;
      this.table_data = [...arr];
      this.configTable();
    },
    fetchTestInAppData() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
      };
      return this.$api.getABTestInAppPurchaseDetail(this.project_id, this.experiment_id, params);
    },
    initTimeFilter() {
      const duration = this.$store.state.ab_test_duration;
      console.dir(duration);
      const startDate = new Date(duration[0]);
      let endDate = new Date(duration[1]);
      const today = new Date();
      endDate = today.valueOf() > endDate.valueOf() ? endDate : today;
      this.filter.time = [startDate, endDate];
    },
    search() {
      this.button_loading = true;
      this.fetchMainData();
    },
    rowExpand(row, column) {
      const { expanded, date } = row;
      const { key } = column;
      if (expanded !== undefined) {
        // eslint-disable-next-line no-underscore-dangle
        const index = row._index;
        if (key === 'empty') {
          if (!expanded) {
            // Expandable
            // Create loading row
            const rowsData = [
              {
                empty: '.',
                name: 'channel1',
                date: '2020-1-08',
                new_users: '8943',
                1: '3445',
                2: '2343',
                3: '1564',
                4: '234',
                5: '32',
                6: '23',
              },
            ];
            this.table_data.splice(index + 1, 0, ...rowsData);
            this.loading_param = index + 1;
            let instance = null;
            this.$nextTick(() => {
              // After dom is updated
              const tableElement = this.$refs.table;
              const rowElement = document.querySelector('.loading-row');
              const top = rowElement.offsetTop;
              const width = tableElement.clientWidth;
              const height = rowElement.clientHeight;
              console.log(top, width, height);
              console.log('loading-row-element');
              instance = this.$loading(tableElement);
              const instanceElement = instance.element();
              instanceElement.style.top = `${top + 48}px`;
              instanceElement.style.height = '112px';
              instanceElement.style.width = `${width}px`;
              instance.show();
            });
            // Fetch detail data
            const callback = (reports) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const item of reports) {
                // eslint-disable-next-line no-restricted-syntax
                for (const k of Object.keys(item)) {
                  if (k === this.filter.dimension) {
                    item.date = item[k];
                  }
                  item.empty = '';
                }
              }
              this.rows_data = [...reports];
              instance.hide();
              this.loading_param = 0;
              // Delete the loading row before inserting new rows into the table
              this.table_data.splice(index + 1, 1, ...this.rows_data);
              this.configExpandedCellBg(index + 1, this.rows_data.length);
              // eslint-disable-next-line no-param-reassign
              this.table_data[index].expanded = true;
            };
            this.fetchKeepDetailData(date, callback);
          } else {
            // Not expandable
            this.table_data.splice(index + 1, this.rows_data.length);
            // eslint-disable-next-line no-param-reassign
            this.table_data[index].expanded = false;
          }
        }
      }
    },
    configTable() {
      this.configTableCellBg();
      this.configTableCellData();
    },
    configTableCellBg() {
      // eslint-disable-next-line no-restricted-syntax,no-plusplus
      for (let i = 0; i < this.table_data.length; i++) {
        const item = this.table_data[i];
        const cellClassName = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(item)) {
          const numberKey = Number(key);
          if (!Number.isNaN(numberKey)) {
            const value = Number(item[key][this.attribute_name]);
            const number = value === 0 ? 0 : Math.floor(value * 10) + 1;
            cellClassName[key] = (i + 1) % (this.variant_number + 1) === 1
              ? `keep-cell-${number}`
              : `channel-cell-${number}`;
          }
        }
        this.$set(item, 'cellClassName', cellClassName);
      }
    },
    configTableCellData() {
      this.table_data.forEach((item) => {
        this.$set(item, 'expanded', false);
      });
    },
    pageNumChange(pageNum) {
      this.page_num = pageNum;
      this.fetchData();
    },
    pageSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchData();
    },
    /**
     * country in filter changes
     * @param checkGroup {Array}
     */
    countryChangeHandler(checkedGroup) {
      console.table(checkedGroup);
      this.filter.country = [...checkedGroup];
    },
    /**
     * country option in filter changes
     * @param checkedOption
     */
    countryOptionChangeHandler(checkedOption) {
      console.log(`checkedOption: ${checkedOption}`);
      this.filter.country_list_exclude = checkedOption;
    },
    networkChangeHandler(checkGroup) {
      this.filter.network = [...checkGroup];
    },
    campaignChangeHandler(checkGroup) {
      this.filter.campaign = [...checkGroup];
    },
    // rowClassName(row, index) {
    //   const { length } = this.chart_data.name_list;
    //   const temp = index + 1;
    //   if (temp % length !== 0) {
    //     return 'variant-row';
    //   }
    //   return 'total-row';
    // },
    showNumberChangeHandler() {
      this.columns.forEach((val) => {
        this.$set(val, 'render', this.columnRender);
      });
    },
  },
  computed: {
    header_title() {
      return this.test_name;
    },
    chart_title() {
      const name = this.name_map.get(this.type);
      const result = `${name}趋势图`;
      return result;
    },
    table_title() {
      const name = this.name_map.get(this.type);
      const result = `${name}热力图`;
      return result;
    },
    test_name() {
      return this.$store.state.selected_test_name;
    },
    project_id() {
      return this.$route.params.project_id;
    },
    experiment_id() {
      return this.$route.params.test_id;
    },
    variant_number() {
      return this.chart_data.y_axis.length;
    },
    getRowClassName() {
      const param = this.loading_param;
      const cb = (row, index) => {
        const length = this.variant_number + 1;
        const temp = index;
        let str = '';
        if (temp % length !== 0) {
          str = 'variant-row';
          if (this.type === 'in_app_purchase') {
            str += ' no-heat-map-variant-row';
          }
        } else {
          str = 'total-row';
        }
        if (param === 0) {
          return str;
        } if (index === param) {
          str += ' loading-row';
          return str;
        }
        return str;
      };
      return cb;
    },
    attribute_name() {
      if (this.type === 'keep') {
        return 'retention_rate';
      } if (this.type === 'uninstall_rate') {
        return 'remove_rate';
      }
      return 'in_app';
    },
    /**
     * Rate rendering
     * @return {function(*, *): *}
     */
    render1() {
      const render = (h, p) => {
        const rowNumber = p.index;
        const { key } = p.column;
        if (key === 'empty' && p.row[key] === '.') {
          if (rowNumber !== 0) {
            const props = {
              type: 'md-arrow-dropright',
              size: 16,
              color: 'rgb(174, 176, 206)',
            };
            const classObj = {
              'arrow-drop-right': true,
              'arrow-drop-down': false,
            };
            // return h('span', 'empty');
            return h('Icon', {
              props,
              class: classObj,
            });
          }
          return h('span', '');
        }
        if (key === 'conversion_rate') {
          return h('span', this.$tools.percentageFormat(p.row[key]));
        }
        if (key === 'date' && p.row.empty === '') {
          const value = p.row[key];
          return h('Tooltip', {
            props: {
              content: value,
              placement: 'right',
            },
          }, `${this.$tools.tableCellValueEllipsis(value)}`);
        }
        const numberKey = Number(key);
        // eslint-disable-next-line no-shadow
        let result;
        if (Number.isNaN(numberKey)) {
          result = p.row[key];
        } else {
          result = p.row[key]
            ? this.$tools.percentageFormat(p.row[key][this.attribute_name])
            : undefined;
        }
        return h('span', result);
      };
      return render;
    },
    /**
     * Number of people rendering
     * @return {function(*, *): *}
     */
    render2() {
      const render = (h, p) => {
        const rowNumber = p.index;
        const { key } = p.column;
        if (key === 'empty' && p.row[key] === '.') {
          if (rowNumber !== 0) {
            const props = {
              type: 'md-arrow-dropright',
              size: 16,
              color: 'rgb(174, 176, 206)',
            };
            const classObj = {
              'arrow-drop-right': true,
              'arrow-drop-down': false,
            };
            // return h('span', 'empty');
            return h('Icon', {
              props,
              class: classObj,
            });
          }
          return h('span', '');
        }
        if (key === 'date' && p.row.empty === '') {
          const value = p.row[key];
          return h('Tooltip', {
            props: {
              content: value,
              placement: 'right',
            },
          }, `${this.$tools.tableCellValueEllipsis(value)}`);
        }
        const numberKey = Number(key);
        // eslint-disable-next-line no-shadow
        let result = null;
        if (Number.isNaN(numberKey)) {
          result = p.row[key];
          return h('span', result);
        }
        if (p.row[key]) {
          const value1 = p.row[key][this.attribute_name.slice(0, -5)];
          let value2 = p.row[key][this.attribute_name];
          if (value1 === -1 && value2 === -1) {
            return h('span', '');
          }
          value2 = this.$tools.percentageFormat(value2);
          return h('div', [
            h('p', value1),
            h('p', value2),
          ]);
        }
        return h('span', '');
      };
      return render;
    },
    /**
     * Determined to {this.table_filter.show_number}
     */
    columnRender() {
      const show = this.table_filter.show_number;
      return !show ? this.render1 : this.render2;
    },
  },
  mounted() {
    const { index } = this.$route.params;
    this.type = index.slice(5);
    this.initTimeFilter();
    this.fetchData();
  },
};
</script>
<style lang="less">
  @import '../../../style/gradientTable.less';
  @import '../../../style/input.less';
  .project-ab-test-detail {
    .button-box {
      .search-button {
        height: 36px;
        line-height: 32px;
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @marginSide: 24px;
  @contentHeaderWrapMargin: @marginSide;
  @filterBoxWrapMargin: 0 @marginSide 20px;
  @wrapMargin: 0 @marginSide @marginSide;
  @wrapPadding: 20px 24px;
  @wrapWidth: calc(~'100% - 48px');
  @wrapHeight: 396px;
  @wrapBgColor: #ffffff;
  @wrapBorderRadius: @borderRadius;
  @wrapBoxSizing: border-box;
  @pageHeight: 48px;
  @titleMargin: 0 0 20px 0;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .project-ab-test-detail {
    .content-header-wrap {
      margin:@contentHeaderWrapMargin;
    }
    .title {
      /* BFC */
      display: inline-block;
      width: 100%;
      margin: @titleMargin;
      .h2Title();
    }
    .filter-box-wrap {
      margin: @filterBoxWrapMargin;
      padding: @filterBoxWrapPadding;
      background: #FFFFFF;
      border-radius: @borderRadius;
      border: @boxBorder;
      .filter-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .select-box {
          width: 200px;
          margin: 0 1% 12px 0;
        }
        .button-box {
        }
      }
      .filter-box-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .select-box {
          width: 200px;
        }
      }
    }
    .chart-wrap {
      width: @wrapWidth;
      height: @wrapHeight;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @wrapBgColor;
      border: @boxBorder;
      border-radius: @wrapBorderRadius;
      box-sizing: @wrapBoxSizing;
      .chart {
        position: relative;
        width: 100%;
        height: calc(~'100% - 62px');
      }
    }
    .table-wrap {
      width: @wrapWidth;
      margin: @wrapMargin;
      padding: @wrapPadding;
      background-color: @wrapBgColor;
      border: @boxBorder;
      border-radius: @wrapBorderRadius;
      box-sizing: @wrapBoxSizing;
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .title {
          width: auto;
          margin: 0;
          line-height: 22px;
        }
      }
      .table {
        position: relative;
      }
      .page-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        height: @pageHeight;
      }
    }
  }
</style>

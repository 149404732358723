export default {
  data() {
    return {
      selected_item_name: null,
      menu_name_list: [
        'project_list',
        'configuration',
        'overview',
        'keep',
        'uninstall_rate',
        'revenue',
        'ab_test',
      ],
    };
  },
  methods: {
    /**
     * selected_item_name controls the display of menu items
     * route controls the display of main content
     * @param item {Object}
     */
    selectItem(item) {
      this.selected_item_name = item.name;
      const { params } = this.$route;
      const projectId = params.project_id;
      if (projectId) {
        const newRoute = {
          path: `/${projectId}/${item.name}`,
        };
        this.$router.push(newRoute);
      }
      // let route = this.$route.path;
      // let param = this.getRouteParam();
      // while (!this.menu_name_list.includes(param)) {
      //   const index = route.indexOf(param);
      //   route = route.slice(0, index);
      //   console.log(`param: ${param}`);
      //   param = this.$tools.getOutermostParameterInURL(route);
      // }
      // const leftRoute = route.split(`/${param}`)[0];
      // const newRoute = {
      //   path: `${leftRoute}/${item.name}`,
      // };
      // this.$router.push(newRoute);
    },
    getRouteParam() {
      const { path } = this.$route;
      const param = this.$tools.getOutermostParameterInURL(path);
      return param;
    },
    // getRouteParam2() {
    //   const { params } = this.$route;
    //   console.dir(params);
    //   return params[params.length - 1];
    // },
    selectedItemNameBinding() {
      console.log('selectedItemNameBinding');
      this.selected_item_name = this.getRouteParam();
    },
  },
  watch: {
    '$route.path': function () {
      this.selectedItemNameBinding();
    },
  },
  mounted() {
    this.selectedItemNameBinding();
  },
};

/**
 * API 接口统一管理
 */
// eslint-disable-next-line import/no-cycle
import ads from './ads/index';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  // deleteRequest,
} from './index';
import urls from './urls';
import { replacePathParam } from './apiToolFunctions';

export default {
  /**
   * Login
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  login: (params) => postRequest(urls.loginURL, params),
  /**
   * Get project id list.
   * @param params
   * @return {Promise<AxiosResponse<T>>}
   */
  getProjectIDList: (params) => getRequest(urls.getProjectIDListURL, params),
  /**
   * Get project list
   * @param params {Object}
   * @returns {Promise<AxiosResponse<T>>}
   */
  getProjectList: (params) => getRequest(urls.getProjectListURL, params),
  /**
   * Create project
   * @param params {Object}
   * @returns {Promise<AxiosResponse<T>>}
   */
  createProject: (params) => postRequest(urls.createProjectURL, params),
  /**
   * Modify project
   * projectId {String}
   * @param params {Object}
   * @returns {Promise<AxiosResponse<T>>}
   */
  modifyProject: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.modifyProjectURL);
    console.log(`Modify url: ${url}`);
    return postRequest(url, params);
  },
  /**
   * Search project
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  searchProject: (params) => getRequest(urls.searchProjectURL, params),
  /**
   * Delete project
   * @param projectId {String}
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  deleteProject: (projectId) => {
    const url = replacePathParam('project_id', projectId, urls.deleteProjectURL);
    return deleteRequest(url);
  },
  /**
   * Overview
   */
  /**
   * Get chart name list
   * @param params
   * @return {Promise<AxiosResponse<T>>}
   */
  getChartNameList: (id, params) => {
    const url = `/projects/${id}/report/overview/chart_name`;
    return getRequest(url, params);
  },
  /**
   * Get chart data
   * @param params
   * @return {Promise<AxiosResponse<T>>}
   */
  getOverviewChartData: (projectId, chartName, params) => {
    // data?_t%20=1612322675712
    // Need modification. Curry ???
    let url = replacePathParam('project_id', projectId, urls.getOverviewChartDataURL);
    url = replacePathParam('chart_name', chartName, url);
    return getRequest(url, params);
  },
  /**
   * Get overview table data
   * @param projectId {String}
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  getOverviewTableData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getOverviewTableDataURL);
    return getRequest(url, params);
  },
  /**
   * Get keep countries list
   * @param projectId {String}
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  getKeepCountryList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getKeepCountryListURL);
    return getRequest(url, params);
  },
  /**
   * Get keep network list
   * @param projectId {String}
   * @param params {Object}
   * @return {Promise<AxiosResponse<T>>}
   */
  getNetworkList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getNetworkListURL);
    return postRequest(url, params);
  },
  getCampaignList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getCampaignListURL);
    return postRequest(url, params);
  },
  getKeepData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getKeepDataURL);
    return postRequest(url, params);
  },
  getKeepDetailData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getKeepDetailDataURL);
    return postRequest(url, params);
  },
  getUninstallRateData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getUninstallRateDataURL);
    return postRequest(url, params);
  },
  getUninstallRateDetailData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getUninstallRateDetailDataURL);
    return postRequest(url, params);
  },
  getRevenueCountryList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getRevenueCountryListURL);
    return postRequest(url, params);
  },
  getRevenueData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getRevenueDataURL);
    return postRequest(url, params);
  },
  getRevenuePaymentData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getRevenuePaymentDataURL);
    return postRequest(url, params);
  },
  getRevenueConversionRateData: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getRevenueConversionRateDataURL);
    return postRequest(url, params);
  },
  /**
   * Get ABTest List
   * @param params
   * @return {Promise<AxiosResponse<T>>}
   */
  getABTestList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getABTestListURL);
    return getRequest(url, params);
  },
  createABTest: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.createABTestURL);
    return postRequest(url, params);
  },
  terminateABTest: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.terminateABTestURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return putRequest(url, params);
  },
  deleteABTest: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.terminateABTestURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return deleteRequest(url, params);
  },
  getABTestOverviewResult: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestOverviewResultURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return getRequest(url, params);
  },
  getABTestOverviewRetention: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestOverviewRetentionURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return getRequest(url, params);
  },
  getABTestOverviewUninstallRate: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestOverviewUninstallRateURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return getRequest(url, params);
  },
  getABTestOverviewInAppPurchase: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestOverviewInAppPurchaseURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return getRequest(url, params);
  },
  getABTestRetentionDetail: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestRetentionDetailURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return postRequest(url, params);
  },
  getABTestUninstallDetail: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestUninstallDetailURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return postRequest(url, params);
  },
  getABTestInAppPurchaseDetail: (projectId, experimentId, params) => {
    let url = replacePathParam('project_id', projectId, urls.getABTestInAppPurchaseDetailURL);
    url = replacePathParam('experiment_id', experimentId, url);
    return postRequest(url, params);
  },
  // Mark
  createMark: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.createMarkURL);
    return postRequest(url, params);
  },
  getMarkList: (projectId, params) => {
    const url = replacePathParam('project_id', projectId, urls.getMarkListURL);
    return getRequest(url, params);
  },
  editMark: (projectId, markId, params) => {
    let url = replacePathParam('project_id', projectId, urls.editMarkURL);
    url = replacePathParam('mark_id', markId, url);
    return putRequest(url, params);
  },
  deleteMark: (projectId, markId, params) => {
    let url = replacePathParam('project_id', projectId, urls.deleteMarkURL);
    url = replacePathParam('mark_id', markId, url);
    return deleteRequest(url, params);
  },
  // Config
  getConfigProjects: (params) => getRequest(urls.getConfigProjectsURL, params),
  createProjectAndConfig: (data) => postRequest(urls.createConfigProjectURL, data),
  updateAnalysisConfig: (projectId, data) => {
    const url = replacePathParam('project_id', projectId, urls.updateAnalysisConfigURL);
    return postRequest(url, data);
  },
  updateGooglePlayConfig: (projectId, data) => {
    const url = replacePathParam('project_id', projectId, urls.updateGooglePlayConfigURL);
    return postRequest(url, data);
  },
  ...ads,
};

export default {
  data() {
    return {};
  },
  computed: {
    xlsx_columns() {
      const columns = [...this.columns];
      columns.shift();
      return columns;
    },
    xlsx_table_data() {
      const propertyName = this.attribute_name || this.table_cell_name_list[1];
      const tableData = JSON.parse(JSON.stringify(this.table_data));
      tableData.forEach((item) => {
        const arr = Object.keys(item);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of arr) {
          const number = Number(key);
          if (!Number.isNaN(number)) {
            // eslint-disable-next-line no-param-reassign
            item[key] = item[key][propertyName];
          }
        }
      });
      return tableData;
    },
  },
};

import Vue from 'vue';
import ViewUI from 'view-design';
import echarts from 'echarts';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';
import 'view-design/dist/styles/iview.css';
import './style/mytheme.less';
import toolFunctions from './utils/toolFunctions';
import toolTipFormatter from './utils/toolTipFormatter';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './api';
import api from './api/api';

// eslint-disable-next-line no-unused-vars
import mock from './mock';
import extendComponents from './utils/extendComponents';

Vue.config.productionTip = false;
Vue.prototype.$get = getRequest;
Vue.prototype.$post = postRequest;
Vue.prototype.$put = putRequest;
Vue.prototype.$delete = deleteRequest;
Vue.prototype.$api = api;
Vue.prototype.$echarts = echarts;
Vue.prototype.$tools = toolFunctions;
// Just a function, not an Instance.
Vue.prototype.$loading = extendComponents.loading;
Vue.prototype.$tooltipFormatter = toolTipFormatter;
Vue.use(ViewUI);
// Vue.use(mock);
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

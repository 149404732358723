<template>
  <div class="project-overview" ref="projectOverview">
    <div class="content-header-wrap wrap">
      <content-header :header_title="header_title"></content-header>
    </div>
    <div class="filter-box-wrap">
      <div class="filter-box">
        <DatePicker class="time-filter"
                    type="daterange"
                    :options="date_picker_option"
                    :clearable="false"
                    v-model="time_filter"
                    placeholder="Select date"
                    style="width: 200px"
                    @on-change="timeFilterChangeHandler"></DatePicker>
      </div>
    </div>
    <div class="chart-list-box-wrap">
      <div class="chart-list-box">
        <li class="chart-item"
            :class="`chart-item-${key}`"
            ref="chartItem"
            v-for="(key) in Object.keys(chart_list)"
            :key="chart_list[key].name">
          <overview-chart
            :chart_data="chart_list[key]"
            @on-show-chart-detail="showChartDetailHandler">
          </overview-chart>
        </li>
        <div class="clear"></div>
      </div>
    </div>
    <div class="data-table-wrap">
      <div class="data-table">
        <div class="table-header">
          <h3 class="table-title">{{ table_title }}</h3>
          <table-operation
            :columns="columns"
            :table_data="table_data"></table-operation>
        </div>
        <div class="table-box">
          <Table
                 ref="table"
                 :columns="columns"
                 :data="table_data"
                 :row-class-name="rowClassName">
          </Table>
        </div>
        <div class="table-footer">
          <div class="page-box">
            <Page :current="page_num"
                  :page-size="page_size"
                  :page-size-opts="page_size_opts"
                  :total="total"
                  size="small"
                  show-elevator
                  show-sizer
                  @on-change="pageNumChange"
                  @on-page-size-change="pageSizeChange">
            </Page>
          </div>
        </div>
      </div>
    </div>
    <OverviewChartModal
      :modal_visible="overview_chart_detail_visible"
      :item="selected_chart_detail"
      @on-cancel="overviewChartDetailCancelHandler"></OverviewChartModal>
  </div>
</template>

<script>
import ContentHeader from '../../../components/ContentHeader.vue';
import OverviewChart from '../../../components/OverviewChart.vue';
import routerTriggerMixin from './routerTriggerMixin';
import OverviewChartModal from '../../../components/modal/OverviewChartModal.vue';
import TableOperation from '../../../components/TableOperation.vue';

export default {
  name: 'ProjectOverview',
  components: {
    TableOperation,
    OverviewChartModal,
    OverviewChart,
    ContentHeader,
  },
  data() {
    return {
      header_title: '概览',
      table_title: '数据日报',
      time_filter: [],
      page_size: 20,
      page_num: 1,
      total: 0,
      color_list: ['rgba(51, 182, 149, 1)', 'rgba(116, 129, 244, 1)', 'rgba(38, 171, 238, 1)', 'rgba(250, 119, 146, 1)', 'rgba(247, 153, 21, 1)'],
      area_color_list: ['rgba(51, 182, 149, 0.2)', 'rgba(116, 129, 244, 0.2)', 'rgba(38, 171, 238, 0.2)', 'rgba(250, 119, 146, 0.2)', 'rgba(247, 153, 21, 0.2)'],
      tooltip_color_list: ['rgba(33, 154, 123, 1)', 'rgba(75, 92, 240, 1)', 'rgba(8, 143, 209, 1)', 'rgba(244, 72, 108, 1)', 'rgba(225, 118, 10, 1)'],
      overview_chart_detail_visible: false,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
          {
            text: '最近60天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 60);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
      selected_chart_detail: {
        chart_data: null,
        columns: null,
        table_data: null,
      },
      chart_name_list: {
        installed: '安装量',
        dau: 'DAU',
        dnu: 'DNU',
        daily_install_ratio: '日活/安装',
        new_uninstall_rate: '新增卸载率',
        retention: '次留',
      },
      unit_format: {
        date: '',
        rate: '%',
        dollar: '$',
        normal: '',
      },
      chart_list: {
        installed: {
          name: '安装量',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '0.6',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
        dau: {
          name: 'DAU',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '0.6',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
        dnu: {
          name: 'DNU',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '0.6',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
        daily_install_ratio: {
          name: '日活/安装',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '0.6',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
        new_uninstall_rate: {
          name: '新增卸载率',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '0.6',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
        retention: {
          name: '次留',
          color: 'rgba(78, 199, 167, 0.2)',
          area_color: 'rgba(78, 199, 167, 0.2)',
          data_today: '203,388,338',
          date: '2021-01-19',
          rate: '1.2',
          type: 'line',
          x_axis: [],
          y_axis: [],
          fake: true,
        },
      },
      columns: [
        {
          title: '日期',
          key: 'date',
        },
        {
          title: '安装量',
          key: 'installed',
        },
        {
          title: 'DAU',
          key: 'dau',
        },
        {
          title: 'DNU',
          key: 'dnu',
        },
        {
          title: '日活/安装',
          key: 'daily_install_ratio',
        },
        {
          title: '新增卸载率',
          key: 'new_uninstall_rate',
        },
        {
          title: '次留',
          key: 'retention',
        },
      ],
      table_data: [],
      table_meta: {},
    };
  },
  mixins: [routerTriggerMixin],
  methods: {
    /**
     * Get parent element for loading component
     */
    getChartParent(name) {
      return document.querySelector(`.chart-item-${name}`);
    },
    getTableParent() {
      return document.querySelector('.table-box');
    },
    // Need modification
    fetchChartNameList() {
      const params = {};
      this.$api.getChartNameList(params)
        .then((response) => {
          this.chart_name_list = [...response.data.list];
          Promise.resolve([...response.data.list]);
          // Promise.all
          // eslint-disable-next-line no-restricted-syntax
          // for (const item of this.chart_name_list) {
          //   this.fetchChartList(item);
          // }
        })
        .then((list) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of list) {
            this.fetchSingleChartList(item);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    fetchData() {
      this.fetchAllChartsList();
      this.fetchTableData();
    },
    fetchAllChartsList() {
      // eslint-disable-next-line no-restricted-syntax,max-len
      // for (const key of Object.keys(this.chart_name_list).filter((val, index, arr) => index < (arr.length - 1))) {
      //   this.fetchSingleChartList(key);
      // }
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(this.chart_name_list)) {
        this.fetchSingleChartList(key);
      }
    },
    fetchSingleChartList(name) {
      const params = {
        date_start: this.$tools.dateFormat(this.time_filter[0]),
        date_end: this.$tools.dateFormat(this.time_filter[1]),
        // date_start: this.time_filter[0],
        // date_end: this.time_filter[1],
      };
      const instance = this.$loading(this.getChartParent(name));
      instance.show();
      this.$api.getOverviewChartData(this.project_id, name, { params })
        .then((response) => {
          this.chart_list[name] = { ...response.data };
          this.chart_list[name].fake = false;
          this.$set(this.chart_list[name], 'name', this.chart_name_list[name]);
          this.configSingleChartColor(name, this.chart_list[name]);
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    fetchTableData() {
      const chartNameList = Object.keys(this.chart_name_list);
      console.table(chartNameList);
      const params = {
        date_start: this.$tools.dateFormat(this.time_filter[0]),
        date_end: this.$tools.dateFormat(this.time_filter[1]),
        page_size: this.page_size,
        page_num: this.page_num,
        chart_name_list: JSON.stringify(chartNameList),
      };
      console.log(`this.project_id: ${this.project_id}`);
      const instance = this.$loading(this.getTableParent());
      instance.show();
      this.$api.getOverviewTableData(this.project_id, { params })
        .then((response) => {
          const {
            record,
            // eslint-disable-next-line camelcase
            records_aggregate,
            meta,
            total,
          } = response.data;
          records_aggregate.date = '列总计';
          record.splice(0, 0, records_aggregate);
          this.table_data = [...record];
          this.table_meta = { ...meta };
          this.total = total;
          this.configTableDataDisplay();
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    /**
     * Set table row style.
     * @param row
     * @param index
     * @return {string|null}
     */
    rowClassName(row, index) {
      if (index % 2 === 0 && index !== 0) {
        return 'table-row-grey';
      } if (index % 2 === 1) {
        return 'table-row-white';
      } if (index === 0) {
        return 'table-row-total';
      }
      return null;
    },
    /**
     * Change chart item height when window size changes.
     */
    changeChartItemHeight() {
      this.changeChartListBoxHeight();
      const containerElement = this.$refs.projectOverview;
      const containerWidth = containerElement.clientWidth;
      const containerContentWidth = containerWidth * 0.98;
      const chartItemWidth = containerContentWidth * 0.23;
      const chartItems = document.getElementsByClassName('chart-item');
      // eslint-disable-next-line no-restricted-syntax
      for (const item of chartItems) {
        item.style.height = `${chartItemWidth * 0.87}px`;
      }
    },
    changeChartListBoxHeight() {
      const chartListBox = document.querySelector('.chart-list-box');
      const width = chartListBox.clientWidth;
      chartListBox.style.height = `${width * 0.435}px`;
    },
    configSingleChartColor(chartName, chartData) {
      const chartNameArr = Object.keys(this.chart_list);
      const index = chartNameArr.indexOf(chartName);
      const { length } = this.color_list;
      const number = index % length;
      const color = this.color_list[number];
      const areaColor = this.area_color_list[number];
      const tooltipColor = this.tooltip_color_list[number];
      this.$set(chartData, 'color', color);
      this.$set(chartData, 'area_color', areaColor);
      this.$set(chartData, 'tooltip_color', tooltipColor);
    },
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.time_filter = [startDate, endDate];
    },
    configTableDataDisplay() {
      const render = (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        const type = this.table_meta[key];
        let result = null;
        if (type === 'rate') {
          result = this.$tools.percentageFormat(value);
        } else {
          result = value;
        }
        return h('span', result);
      };
      this.columns.forEach((val) => {
        this.$set(val, 'render', render);
      });
    },
    showChartDetailHandler(data) {
      console.log('showChartDetailHandler');
      console.log(data);
      const chartDetail = this.selected_chart_detail;
      chartDetail.chart_data = { ...data };
      const columns = [
        {
          key: 'date',
          title: '日期',
        },
        {
          key: 'value',
          title: '数值',
        },
      ];
      const tableData = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < chartDetail.chart_data.x_axis.length; i++) {
        const date = chartDetail.chart_data.x_axis[i];
        const value = chartDetail.chart_data.y_axis[i];
        const tempObj = {
          date,
          value,
        };
        tableData.push(tempObj);
      }
      chartDetail.columns = columns;
      chartDetail.table_data = tableData;
      chartDetail.time = this.time_filter;
      this.overview_chart_detail_visible = true;
    },
    overviewChartDetailCancelHandler() {
      this.overview_chart_detail_visible = false;
    },
    timeFilterChangeHandler(value) {
      console.log(value);
      this.fetchAllChartsList();
      this.fetchTableData();
    },
    pageNumChange(pageNum) {
      this.page_num = pageNum;
      this.fetchTableData();
    },
    pageSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchTableData();
    },
    // exportData() {
    //   const { table } = this.$refs;
    //   table.exportCsv({
    //     filename: 'Table data',
    //   });
    // },
  },
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    page_size_opts() {
      return [5, 10, 20, 40, 80];
    },
  },
  mounted() {
    this.initTimeFilter();
    this.changeChartListBoxHeight();
    window.addEventListener('resize', this.changeChartItemHeight);
    this.fetchAllChartsList();
    this.fetchTableData();
  },
  destroyed() {
    window.removeEventListener('resize', this.changeChartItemHeight);
  },
};
</script>
<style lang="less">
  @selectHeight: 36px;
  @selectLineHeight: 34px;
  @selectBgColor: #FAFBFF;
  .thText {
    font-size: 14px;
    font-weight: 600!important;
    color: #202444;
    line-height: 20px;
  }
  .tdText {
    font-size: 14px;
    color: #545878;
    line-height: 16px;
  }
  .project-overview {
    .filter-box {
      .ivu-select-selection {
        height: @selectHeight;
        background-color: #FAFBFF;
        .ivu-select-placeholder, .ivu-select-selected-value {
          height: @selectHeight;
          line-height: @selectLineHeight;
        }
      }
    }
    .table-row-grey td {
      background: rgba(125, 129, 164, 0.02);
    }
    .table-row-white td {
      background: #ffffff;
    }
    .table-row-total td {
      background: #E4E5F0;
    }
    .ivu-table-header thead tr th {
      height: 48px;
    }
    .ivu-table th {
      .thText();
    }
    .ivu-table td {
      height: 40px;
      .tdText();
    }
    /*.ivu-table .ivu-table-row-hover {*/
    /*  background: #7D81A4!important;*/
    /*}*/
  }
</style>
<style lang="less" scoped>
  @import '../../../style/util.less';
  @boxBorder: 1px solid #EAEBF7;
  @sidePadding: 24px;
  @filterBoxPadding: 0 @sidePadding @sidePadding;
  @filterItemWidth: 284px;
  @timeFilterWidth: @filterItemWidth;
  @inputFilterWidth: @filterItemWidth;

  @paddingSide: 24px;
  @projectOverviewMargin: 24px 0;
  @wrapMargin: 0 @paddingSide @paddingSide;
  @chartListBoxPadding: 0 calc(~'@{paddingSide} - 0.1%');
  @chartItemWidth: calc(~'100% / 4 - 0.2%');
  @chartItemHeight: 49.8%;
  @chartItemMargin: 0 0.1% 0.2%;
  @chartItemBorderRadius: 4px;
  @chartItemBorder: 1px solid #EAEBF7;
  @chartItemBoxShadow: 0px 4px 16px 0px rgba(13,17,52,0.08);
  @dataTableWrapPadding: 0 @sidePadding;
  @dataTablePadding: 20px 24px 0;
  @dataTableBorderRadius: 4px;
  @dataTableBgColor: #ffffff;
  @dataTableHeaderMargin: 0 0 20px 0;
  @dataTableOperationLineHeight: 22px;
  @dataTableFooterHeight: 48px;
  .h3Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .project-overview {
    width: 100%;
    margin: @projectOverviewMargin;
    .content-header-wrap {
      margin: @wrapMargin;
    }
    .filter-box-wrap {
      margin: 0 24px 20px;
      padding: 20px 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #EAEBF7;
      .filter-box {
        display: flex;
        flex-direction: row;
        .time-filter {
          width: @timeFilterWidth;
        }
        .input-filter {
          width: @inputFilterWidth;
        }
      }
    }
    .chart-list-box-wrap {
      width: 100%;
      .chart-list-box {
        position: relative;
        width: 100%;
        margin-bottom: calc(~'20px - 0.2%');
        padding: @chartListBoxPadding;
        overflow: hidden;
        .chart-item {
          position: relative;
          /*display: inline-block;*/
          float: left;
          width: @chartItemWidth;
          height: @chartItemHeight;
          margin: @chartItemMargin;
          border-radius: @chartItemBorderRadius;
          border: @chartItemBorder;
          transition: 0.2s ease-in-out;
          &:hover {
            box-shadow: @chartItemBoxShadow;
          }
        }
        .clear {
          clear: both;
        }
      }
    }
    .data-table-wrap {
      padding: @dataTableWrapPadding;
      .data-table {
        padding: @dataTablePadding;
        border: @boxBorder;
        border-radius: @dataTableBorderRadius;
        background-color: @dataTableBgColor;
        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: @dataTableHeaderMargin;
          .table-title {
            .h3Title();
          }
          .table-operation {
            line-height: @dataTableOperationLineHeight;
            img {
              height: 16px;
              vertical-align: middle;
              cursor: pointer;
            }
            .ivu-tooltip {
              margin-right: 8px;
            }
          }
        }
        .table-box {
          position: relative;
        }
      }
      .table-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        height: @dataTableFooterHeight;
      }
    }
  }
</style>

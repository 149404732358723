<template>
  <div class="project-ab-test-overview" ref="project_ab_test_overview">
    <div class="content-header-wrap wrap">
      <content-header :header_title="header_title"
                      :back="true"></content-header>
    </div>
    <div class="test-overview">
      <div class="result" :class="getResultClass">
        <img :src="getResultIcon"
             alt="Result icon"
             class="result-icon">
        <div class="result-text">
          <h1 class="text-1">{{ result_text_1 }}</h1>
          <h2 class="text-2">{{ result_text_2 }}</h2>
        </div>
      </div>
      <div class="info">
        <span class="user-count info-item">
          用户总数:
          <span>{{ user_count }}</span>
        </span>
        <span class="duration info-item">
          持续时间:
          <span>{{ duration }}</span>
        </span>
        <span class="variant-count info-item">
          变体:
          <span>{{ variant_count }}</span>
        </span>
      </div>
    </div>
    <div class="test-details-wrap">
      <ul class="test-details" ref="test_details">
        <li class="test-detail-item-wrap"
            v-for="item in test_detail_list"
            :key="item.name">
          <div class="test-detail-item"
               :ref="`chart_${item.id}`">
            <a-b-test-overview-chart
              :chart_data="item"
              :result="item.result_code"
              :data_type="item.id === 'in_app_purchase' ? 'normal' : 'rate'"
              @jump-to-detail="jumpToTestDetail(item.id)">
            </a-b-test-overview-chart>
          </div>
        </li>
      </ul>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase,max-len */

import ContentHeader from '../../../components/ContentHeader.vue';
import ABTestOverviewChart from '../../../components/ABTestOverviewChart.vue';
import abTestMixin from './abTestMixin';

export default {
  name: 'ProjectABTestOverview',
  data() {
    return {
      loading_count: 3,
      icon_size: 'large',
      variant_name_list: ['基准变体', 'Variant A', 'Variant B', 'Variant C', 'Variant D'],
      test_result: {
        // 0: obvious 1: not obvrateious 2: insufficient
        result: 0,
        priority_variant: 1,
        data: 0.03,
        duration: ['2021-2-1', '2021-3-1'],
        user_count: 180000,
        variant_count: 2,
      },
      test_detail_list: [
        {
          name: '次留',
          id: 'keep',
          result: 'Variant A 的表现优于基准变体 3%',
          x_axis: [],
          y_axis: [
            [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
            [34, 32, 6, 32, 12, 65, 34, 23, 12, 23, 2, 24],
          ],
          name_list: ['基准变体', 'Variant A'],
          color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
          tooltip_color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
        },
        {
          name: '安装卸载率',
          id: 'uninstall_rate',
          result: 'Variant A 的表现优于基准变体 3%',
          x_axis: [],
          y_axis: [
            [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
            [34, 32, 6, 32, 12, 65, 34, 23, 12, 23, 2, 24],
          ],
          name_list: ['基准变体', 'Variant B'],
          color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
          tooltip_color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
          // y_axis: [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
        },
        {
          name: '内购',
          id: 'in_app_purchase',
          result: '...',
          x_axis: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子', 'A', 'B', 'C', 'D', 'E', 'F'],
          y_axis: [
            [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
            [34, 32, 6, 32, 12, 65, 34, 23, 12, 23, 2, 24],
          ],
          name_list: ['基准变体', 'Variant C'],
          color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
          tooltip_color: ['rgba(187, 188, 201, 1)', 'rgba(81, 145, 255, 1)'],
          // y_axis: [5, 20, 36, 10, 10, 20, 22, 32, 10, 6, 33, 12],
        },
      ],
    };
  },
  components: { ABTestOverviewChart, ContentHeader },
  mixins: [abTestMixin],
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    experiment_id() {
      return this.$route.params.test_id;
    },
    header_title() {
      return `${this.test_result.test_name}`;
    },
    result_text_1() {
      const { result } = this.test_result;
      if (result === 0) {
        const { data } = this.test_result;
        const value = this.$tools.percentageFormat(data);
        const variantName = this.getVariantName(this.test_result.priority_variant[0]);
        const referenceName = this.getVariantName(this.test_result.priority_variant[1]);
        return `${variantName} 的表现优于${referenceName} ${value}`;
      } if (result === 1) {
        return 'Variant表现并不明显';
      }
      return '测试数据不足';
    },
    result_text_2() {
      const { result } = this.test_result;
      if (result === 0) {
        const { data } = this.test_result;
        const value = this.$tools.percentageFormat(data);
        const variantName = this.getVariantName(this.test_result.priority_variant[0]);
        const referenceName = this.getVariantName(this.test_result.priority_variant[1]);
        return `${variantName} 的表现优于${referenceName} ${value}`;
      } if (result === 1) {
        return 'Variant表现并不明显';
      }
      return '测试数据不足';
    },
    user_count() {
      let value = this.test_result.user_count;
      const number = this.$tools.numberOfDigit(value);
      if (number > 4 && number < 9) {
        value = `${value / 10000}万`;
      } else if (number >= 9) {
        value = `${value / 100000000}亿`;
      } else if (number <= 0) {
        value = 0;
      }
      return value;
    },
    duration() {
      const start = this.test_result.duration[0];
      const end = this.test_result.duration[1];
      return `${start} ~ ${end}`;
    },
    variant_count() {
      const value = this.test_result.variant_count;
      return `${value}个`;
    },
  },
  methods: {
    getVariantName(code) {
      const variantName = code === 0
        ? '基准变体'
        : `Variant ${String.fromCharCode(code + 64)}`;
      return variantName;
    },
    getResultText(result, data, priorityCodeList) {
      if (result === 0) {
        const value = this.$tools.percentageFormat(data);
        const variantName = this.getVariantName(priorityCodeList[0]);
        const referenceName = this.getVariantName(priorityCodeList[1]);
        return `${variantName} 的表现优于${referenceName} ${value}`;
      } if (result === 1) {
        return 'Variant表现并不明显';
      }
      return '测试数据不足';
    },
    getParentElement() {
      return this.$refs.project_ab_test_overview;
    },
    getChartElement(index) {
      return this.$refs[`chart_${this.test_detail_list[index].id}`][0];
    },
    fetchData() {
      this.fetchResultData();
      this.fetchRetentionData();
      this.fetchUninstallRateData();
      this.fetchInAppPurchaseData();
    },
    fetchResultData() {
      const loading = this.$loading(this.getParentElement());
      loading.show();
      const params = {};
      this.$api.getABTestOverviewResult(this.project_id, this.experiment_id, { params })
        .then((response) => {
          const {
            result,
            // eslint-disable-next-line camelcase
            test_result,
          } = response.data;
          if (result === 'success') {
            // eslint-disable-next-line camelcase
            const { duration } = test_result;
            this.$store.commit('setABTestDuration', {
              duration,
            });
            // eslint-disable-next-line camelcase
            this.test_result = { ...test_result };
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          loading.hide();
        });
    },
    fetchRetentionData() {
      const element = this.getChartElement(0);
      const loading = this.$loading(element);
      loading.show();
      const params = {};
      this.$api.getABTestOverviewRetention(this.project_id, this.experiment_id, { params })
        .then((response) => {
          const {
            result,
            // eslint-disable-next-line camelcase
            test_detail,
          } = response.data;
          if (result === 'success') {
            const {
              // eslint-disable-next-line no-shadow
              result,
              data,
              priority_code,
              date_list,
              result_list,
            } = test_detail;
            const retentionItem = this.test_detail_list[0];
            retentionItem.x_axis = [...date_list];
            retentionItem.y_axis = [...result_list];
            this.$set(retentionItem, 'result_code', result);
            retentionItem.result = this.getResultText(result, data, priority_code);
            retentionItem.name_list = this.variant_name_list.slice(0, retentionItem.y_axis.length);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          loading.hide();
        });
    },
    fetchUninstallRateData() {
      const element = this.getChartElement(1);
      const loading = this.$loading(element);
      loading.show();
      const params = {};
      this.$api.getABTestOverviewUninstallRate(this.project_id, this.experiment_id, { params })
        .then((response) => {
          const {
            result,
            // eslint-disable-next-line camelcase
            test_detail,
          } = response.data;
          if (result === 'success') {
            const {
              // eslint-disable-next-line no-shadow
              result,
              data,
              priority_code,
              date_list,
              result_list,
            } = test_detail;
            const uninstallRateItem = this.test_detail_list[1];
            uninstallRateItem.x_axis = [...date_list];
            uninstallRateItem.y_axis = [...result_list];
            this.$set(uninstallRateItem, 'result_code', result);
            uninstallRateItem.result = this.getResultText(result, data, priority_code);
            uninstallRateItem.name_list = this.variant_name_list.slice(0, uninstallRateItem.y_axis.length);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          loading.hide();
        });
    },
    fetchInAppPurchaseData() {
      const element = this.getChartElement(2);
      const loading = this.$loading(element);
      const params = {};
      this.$api.getABTestOverviewInAppPurchase(this.project_id, this.experiment_id, { params })
        .then((response) => {
          const {
            result,
            // eslint-disable-next-line camelcase
            test_detail,
          } = response.data;
          if (result === 'success') {
            const {
              // eslint-disable-next-line no-shadow
              result,
              data,
              priority_code,
              date_list,
              result_list,
            } = test_detail;
            const inAppPurchaseItem = this.test_detail_list[2];
            inAppPurchaseItem.x_axis = [...date_list];
            inAppPurchaseItem.y_axis = [...result_list];
            this.$set(inAppPurchaseItem, 'result_code', result);
            inAppPurchaseItem.result = this.getResultText(result, data, priority_code);
            inAppPurchaseItem.name_list = this.variant_name_list.slice(0, inAppPurchaseItem.y_axis.length);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          loading.hide();
        });
    },
    jumpToTestDetail(id) {
      const currentPath = this.$route.path;
      const targetPath = `${currentPath}/test_${id}`;
      const route = {
        path: targetPath,
      };
      this.$router.push(route);
    },
  },
  mounted() {
    const testDetailsElement = this.$refs.test_details;
    const width = testDetailsElement.clientWidth;
    testDetailsElement.style.height = `${width * 0.216}px`;
    this.fetchData();
  },
  beforeRouteEnter(to, from, next) {
    const testName = to.params.test_id;
    if (testName) {
      next();
    } else {
      next(from);
    }
  },
};
</script>

<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  @contentHeaderWrapMargin: 24px;
  @testOverviewMargin: 0 24px 11px;
  @testOverviewPadding: 28px 32px;
  @testDetailsWrapMargin: 0 24px 24px;
  @testResultObviousColor: rgba(33, 154, 123, 1);
  @testResultNotObviousColor: rgba(134, 141, 209, 1);
  @testResultInsufficientColor: rgba(249, 77, 113, 1);
  @testResultObviousBgColor: #e3f2ee;
  @testResultNotObviousBgColor: #f0f1f9;
  @testResultInsufficientBgColor: #fee9ed;
  .result-title-1 {
    font-size: 21px;
    font-weight: 600;
    color: #219A7B;
    line-height: 29px;
  }
  .result-title-2 {
    font-size: 14px;
    font-weight: 400;
    color: #219A7B;
    line-height: 20px;
  }
  .project-ab-test-overview {
    position: relative;
    height: 100%;
    overflow: auto;
    .content-header-wrap {
      margin: @contentHeaderWrapMargin;
    }
    .test-overview {
      margin: @testOverviewMargin;
      .result {
        display: flex;
        align-items: center;
        padding: 28px 32px;
        background: #e5f3ef;
        border: @boxBorder;
        border-radius: @borderRadius @borderRadius 0px 0px;
        .result-icon {
          width: 48px;
          margin-right: 20px;
        }
        .result-text {
          .text-1 {
            .result-title-1();
          }
          .text-2 {
            .result-title-2();
          }
        }
      }
      .test-result-obvious {
        background: @testResultObviousBgColor;
        .result-text {
          .text-1 {
            color: @testResultObviousColor;
          }
          .text-2 {
            color: @testResultObviousColor;
          }
        }
      }
      .test-result-not-obvious {
        background: @testResultNotObviousBgColor;
        .result-text {
          .text-1 {
            color: @testResultNotObviousColor;
          }
          .text-2 {
            color: @testResultNotObviousColor;
          }
        }
      }
      .test-result-insufficient {
        background: @testResultInsufficientBgColor;
        .result-text {
          .text-1 {
            color: @testResultInsufficientColor;
          }
          .text-2 {
            color: @testResultInsufficientColor;
          }
        }
      }
      .info {
        padding: 16px 32px;
        background-color: rgba(255, 255, 255, 1);
        border: @boxBorder;
        border-top-style: none;
        border-radius: 0 0 @borderRadius @borderRadius;
        .info-item {
          margin-right: 18px;
        }
        .user-count {
          span {
            font-weight: 600;
          }
        }
        .variant-count {
          span {
            font-weight: 600;
          }
        }
      }
    }
    .test-details-wrap {
      margin: @testDetailsWrapMargin;
      .test-details {
        margin: 0 -0.1%;
        .test-detail-item-wrap {
          /*display: inline-block;*/
          float: left;
          width: 33.1%;
          height: 100%;
          margin: 0 0.1% 0.2%;
          background-color: rgb(255, 255, 255);
          border-radius: @borderRadius;
          border: @boxBorder;
          .test-detail-item {
            position: relative;
            width: 100%;
            height: 100%;
          }
        }
      }
      .clear {
        clear: both;
      }
    }
  }
</style>

<template>
  <div class="project-ab-test" ref="project_ab_test">
    <div class="content-header-wrap wrap">
      <content-header :header_title="header_title">
        <template #test-filter>
          <RadioGroup v-model="current_test_list_name"
                      type="button"
                      button-style="solid">
            <Radio label="running_test_list">{{ '正在运行' }}</Radio>
            <Radio label="completed_test_list">{{ '已完成' }}</Radio>
          </RadioGroup>
        </template>
        <div class="header-operation">
          <Input class="header-search"
                 search
                 v-model="search_content"
                 @on-enter="searchEnter"/>
          <Button class="header-button"
                  type="primary"
                  @click="showCreateABTestModal">
            <img src="../../../assets/image/ic_add.png" alt="Add">
            <span>{{ header_button }}</span>
          </Button>
        </div>
      </content-header>
    </div>
    <div class="test-list-wrap" v-if="!empty_list_visible" ref="test_list_wrap">
      <div class="test-list">
        <div class="test-item-wrap"
             v-for="item in current_test_list"
             :key="item.test_id">
          <a-b-test-item
            :test="item"
            :current_test_list_name="current_test_list_name"
            @on-edit="itemEditHandler(item)"
            @on-delete="itemDeleteHandler(item)"
            @on-jump="jumpToABTestOverview(item.test_id, item.test_name)">
          </a-b-test-item>
        </div>
      </div>
    </div>
    <div class="empty-list-wrap" v-if="empty_list_visible" ref="empty_list_wrap">
      <div class="empty-list-inner-wrap">
        <div class="empty-list-box">
          <img src="../../../assets/image/pic_ab_test_empty.png"
               alt="Empty list"
               class="empty-list-img">
          <h1 class="empty-list-title">{{ empty_list_title }}</h1>
          <p class="empty-list-description">{{ empty_list_description }}</p>
        </div>
      </div>
    </div>
    <create-test-modal
      :modal_visible="create_test_modal_visible"
      :test_id_list="test_id_list"
      @ok="createTestOkHandler"
      @cancel="createTestCancelHandler">
    </create-test-modal>
    <confirm-modal
      :modal_visible="confirm_modal_visible"
      :confirm_message="confirm_modal_text"
      @confirm="confirmHandler"
      @cancel="cancelHandler"></confirm-modal>
  </div>
</template>

<script>
import ContentHeader from '../../../components/ContentHeader.vue';
import ABTestItem from '../../../components/ABTestItem.vue';
import CreateTestModal from '../../../components/modal/CreateTestModal.vue';
import routerTriggerMixin from './routerTriggerMixin';
import ConfirmModal from '../../../components/modal/ConfirmModal.vue';

export default {
  name: 'ProjectABTest',
  components: {
    ConfirmModal,
    CreateTestModal,
    ABTestItem,
    ContentHeader,
  },
  data() {
    return {
      header_title: 'A/B测试',
      header_button: '添加测试',
      empty_list_title: '暂无测试',
      empty_list_description: '您还没有添加测试，点击右上角的按钮添加测试~',
      current_test_list_name: 'running_test_list',
      search_content: '',
      create_test_modal_visible: false,
      confirm_modal_visible: false,
      selected_test_item: null,
      test_operation: 0,
      confirm_modal_text: '确定要终止该测试吗？',
      test_id_list: [],
      running_test_list: [
        {
          test_name: 'UserCursorUpdate ABTest1',
          test_date: '2021-02-02',
          test_user_number: '4000',
          // 0: obvious 1: not obvious 2.insufficient
          test_status: 0,
          variant_code: 1,
        },
        {
          test_name: 'UserCursorUpdate ABTest2',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 2,
          variant_code: 1,
        },
        {
          test_name: 'UserCursorUpdate ABTest3',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 1,
          variant_code: 1,
        },
        {
          test_name: 'UserCursorUpdate ABTest4',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 1,
          variant_code: 1,
        },
      ],
      completed_test_list: [
        {
          test_name: 'UserCursorUpdate ABTest1',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 1,
        },
        {
          test_name: 'UserCursorUpdate ABTest2',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 2,
        },
        {
          test_name: 'UserCursorUpdate ABTest3',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 1,
        },
        {
          test_name: 'UserCursorUpdate ABTest4',
          test_date: '2021-02-02',
          test_user_number: '4000',
          test_status: 2,
        },
      ],
    };
  },
  mixins: [routerTriggerMixin],
  methods: {
    currentTestListNameCheck() {
      if (this.running_test_list.length === 0 && this.completed_test_list.length !== 0) {
        this.current_test_list_name = 'completed_test_list';
      } else {
        this.current_test_list_name = 'running_test_list';
      }
    },
    getParentElement() {
      return this.$refs.test_list_wrap || this.$refs.empty_list_wrap;
    },
    fetchData() {
      this.fetchABTestList();
    },
    /**
     * Fetch ABTest list
     * @param content {String}
     */
    fetchABTestList(content = '') {
      const params = {
        search_content: content,
      };
      const instance = this.$loading(this.getParentElement());
      instance.show();
      this.$api.getABTestList(this.project_id, { params })
        .then((response) => {
          console.dir(response.data);
          this.running_test_list = [...response.data.running_test_list];
          this.completed_test_list = [...response.data.completed_test_list];
          const testIdCallback = (item) => {
            const testId = item.test_id;
            this.test_id_list.push(testId);
          };
          this.test_id_list = [];
          this.currentTestListNameCheck();
          this.running_test_list.forEach(testIdCallback);
          this.completed_test_list.forEach(testIdCallback);
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    showCreateABTestModal() {
      this.create_test_modal_visible = true;
    },
    searchEnter() {
      this.fetchABTestList(this.search_content);
    },
    createTestOkHandler(test) {
      console.dir(test);
      const params = { ...test };
      this.$api.createABTest(this.project_id, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('测试创建成功');
            this.fetchABTestList();
          } else {
            this.$Message.error('测试创建失败');
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    createTestCancelHandler() {
      this.create_test_modal_visible = false;
    },
    jumpToABTestOverview(testId, testName) {
      console.log('jump');
      console.log(testId);
      this.$store.commit('setSelectedTestName', {
        selected_test_name: testName,
      });
      const currentPath = this.$route.path;
      const route = {
        path: `${currentPath}/${testId}`,
      };
      this.$router.push(route);
    },
    itemEditHandler(item) {
      this.confirm_modal_text = '确定要终止该测试吗？';
      this.confirm_modal_visible = true;
      this.selected_test_item = item;
      // Terminate
      this.test_operation = 0;
    },
    itemDeleteHandler(item) {
      this.confirm_modal_visible = '确定要删除该测试吗？';
      this.confirm_modal_visible = true;
      this.selected_test_item = item;
      // Delete
      this.test_operation = 1;
    },
    confirmHandler() {
      console.log('Confirm');
      const testId = this.selected_test_item.test_id;
      const params = {};
      const apiName = this.test_operation === 0
        ? 'terminateABTest'
        : 'deleteABTest';
      this.$api[apiName](this.project_id, testId, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            const resultText = this.test_operation === 0
              ? '测试终止成功'
              : '测试删除成功';
            this.$Message.success(resultText);
            this.fetchABTestList();
          } else {
            this.$Message.error('测试操作失败');
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    cancelHandler() {
      console.log('Cancel');
      this.confirm_modal_visible = false;
    },
  },
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    empty_list_visible() {
      return this.running_test_list.length === 0 && this.completed_test_list.length === 0;
    },
    current_test_list() {
      return this[this.current_test_list_name];
    },
  },
  mounted() {
    this.fetchABTestList();
  },
};
</script>
<style lang="less">
  @import '../../../style/input.less';
</style>
<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 4px;
  @filterBoxWrapPadding: 20px 24px;
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #202444;
  }
  @emptyListImgWidth: 504px;
  @projectKeepPadding: 24px 0;
  @wrapMargin: 0 24px 24px;
  @headerHeight: 36px;
  @headerSearchMargin: 0 16px 0 0;
  @testListWrapMargin: 0 24px;
  @testItemMargin: 0 1% 2%;
  /* Need modification */
  @testItemWidth: 31.3%;
  .project-ab-test {
    position: relative;
    height: 100%;
    padding: @projectKeepPadding;
    .content-header-wrap {
      margin: @wrapMargin;
      .header-operation {
        display: flex;
        flex-direction: row;
        align-items: center;
        .header-search {
          margin: @headerSearchMargin;
        }
        .header-button {
          height: @headerHeight;
          img {
            width: 16px;
            margin-right: 4px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
          img, span {
            vertical-align: middle;
          }
        }
      }
    }
    .test-list-wrap {
      /*width: 100%;*/
      height: calc(~'100% - 62px');
      margin: @testListWrapMargin;
      .test-list {
        margin: 0 -1%;
        .test-item-wrap {
          display: inline-block;
          width: @testItemWidth;
          margin: @testItemMargin;
          /*<!--border: @boxBorder;-->*/
          /*<!--border-radius: @borderRadius;-->*/
        }
      }
    }
    .empty-list-wrap {
      height: calc(~'100% - 62px');;
      .empty-list-inner-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .empty-list-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .empty-list-img {
            width: @emptyListImgWidth;
          }
          .empty-list-title {
            .title();
            margin: 24px 0 8px;
          }
          .empty-list-description {
            font-size: 14px;
            font-weight: 400;
            color: #878AA2;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>

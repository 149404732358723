<template>
  <div class="ads-roi">
    <div class="content-header-wrap">
      <content-header
        :header_title="header_title">
        <div></div>
      </content-header>
    </div>
    <div class="filter-box-wrap">
      <r-o-i-filter-box
        :button_loading="button_loading"
        :filter="filter"
        :platform_list="platform_list"
        :group_list="group_list"
        :plan_list="plan_list"
        @search="search"/>
    </div>
    <div class="content-box">
      <div class="table-wrap">
        <div class="table-header">
          <h2 class="title">{{ roi_table_title }}</h2>
          <table-operation
            :columns="roi_table_columns"
            :table_data="roi_table_data"/>
        </div>
        <div class="table-show-number">
          <Checkbox
            v-model="roi_show_number"
            size="large">显示金额</Checkbox>
        </div>
        <div class="table" ref="roi_table">
          <Table :columns="roi_table_columns"
                 :data="roi_table_data"/>
        </div>
        <div class="page-box">
          <Page
            :total="roi_total"
            :current="roi_page_num"
            :page-size="roi_page_size"
            @on-change="ROIPageNumChange"
            @on-page-size-change="ROIPageSizeChange"
            size="small" show-elevator show-sizer/>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '../../../../components/ContentHeader.vue';
import TableOperation from '../../../../components/TableOperation.vue';
import routerTriggerMixin from '../routerTriggerMixin';
import paramNameJSON from './paramName.json';
import ROIFilterBox from '../../../../components/ROIFilterBox.vue';
import ROIFilterMixin from '../ROIFilterMixin';

export default {
  name: 'AdsROI',
  components: { ROIFilterBox, ContentHeader, TableOperation },
  mixins: [routerTriggerMixin, ROIFilterMixin],
  data() {
    return {
      header_title: 'ROI',
      button_loading: false,
      // Table
      roi_table_title: 'ROI报表',
      roi_table_columns: [],
      roi_table_data: [],
      roi_show_number: false,
      currency: '￥',
      // Page
      roi_total: 0,
      roi_page_num: 1,
      roi_page_size: 10,
    };
  },
  computed: {
    project_id() {
      return this.$route.params.project_id;
    },
    roi_render() {
      return (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        const number = Number(value);
        if (value === -1) {
          return h('span', '-');
        } if (key === 'ads_cost') {
          return h('span', this.$tools.keepDecimals(number));
        } if (key === 'convert_gap') {
          return h('span', this.$tools.percentageFormat(value));
        } if (this.ROIFormatCheck(key)) {
          // return h('span', this.$tools.percentageFormat(value));
          const isShowNumber = this.roi_show_number;
          const { amount, rate } = value;
          if (isShowNumber) {
            return h('div', [
              h('p', this.$tools.percentageFormat(rate)),
              h('p', this.$tools.keepDecimals(amount)),
            ]);
          }
          return h('span', this.$tools.percentageFormat(rate));
        }
        return h('span', value);
      };
    },
  },
  methods: {
    ROIFormatCheck(key) {
      const regx = /^roi[0-9]*/g;
      if (regx.test(key)) return true;
      return false;
    },
    ROITableFormat(key) {
      if (key === 'ads_cost') {
        return `${paramNameJSON[key]}（${this.currency}）`;
      }
      const regx = /^roi[0-9]*/g;
      if (regx.test(key)) {
        return key.replace(regx, (x) => `ROI ${x.slice(3)}`);
      }
      return paramNameJSON[key];
    },
    fetchData() {
      this.fetchROIFilterList();
      this.fetchROIData();
    },
    async fetchROIData() {
      const instance = this.$loading(this.$refs.roi_table);
      try {
        const ROITableResponse = await this.fetchROITableData();
        this.tableDataCallback(ROITableResponse?.data, 'roi');
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
        instance.hide();
      }
    },
    fetchROITableData() {
      const params = {
        page_size: this.roi_page_size,
        page_num: this.roi_page_num,
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        network_list: this.filter.platform,
        campaign_list: this.filter.group,
        ad_group_list: this.filter.plan,
        creative_id: this.filter.creative_id,
      };
      return this.$api.getAdsROITableData(this.project_id, params);
    },
    tableDataCallback(responseData, tableName) {
      if (!responseData) {
        this[`${tableName}_table_columns`] = [];
        this[`${tableName}_table_data`] = [];
        return;
      }
      this[`${tableName}_table_columns`] = [];
      this[`${tableName}_table_data`] = [];
      const columns = this[`${tableName}_table_columns`];
      const data = this[`${tableName}_table_data`];
      const { header, record, total } = responseData;
      this[`${tableName}_total`] = total;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of header) {
        const obj = {
          key,
          title: this.ROITableFormat(key),
          minWidth: 120,
          fixed: key === 'date' ? 'left' : null,
          render: this.roi_render,
        };
        columns.push(obj);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const rowData of record) {
        data.push(rowData);
      }
    },
    search() {
      this.button_loading = true;
      this.roi_page_num = 1;
      this.fetchROIData();
    },
    ROIPageNumChange(pageNum) {
      this.roi_page_num = pageNum;
      this.fetchROIData();
    },
    ROIPageSizeChange(pageSize) {
      this.roi_page_size = pageSize;
      this.roi_page_num = 1;
      this.fetchROIData();
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    this.initTimeFilter();
  },
};
</script>

<style scoped lang="less">
  @boxBorder: 1px solid #EAEBF7;
  @borderRadius: 7px;
  .h2Title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .ads-roi {
    .content-header-wrap {
      margin: 24px;
    }
    .title {
      display: inline-block;
      width: 100%;
      margin: 0 0 20px 0;
      .h2Title();
    }
    .filter-box-wrap {
      margin: 0 20px 20px;
      padding: 24px;
      background: #ffffff;
      border-radius: @borderRadius;
      border: 1px solid #EAEBF7;
    }
    .content-box {
      padding: 0 calc(~'24px - 1%');
      .table-wrap {
        margin: 0 1% 2%;
        float: left;
      }
      .table-wrap {
        width: 98%;
        padding: 20px 24px;
        background-color: #ffffff;
        border-radius: @borderRadius;
        border: @boxBorder;
        box-sizing: border-box;
        .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .title {
            width: auto;
            margin: 0;
            line-height: 22px;
          }
        }
        .table-show-number {
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 6px;
        }
        .table {
          position: relative;
          min-height: 120px;
        }
        .page-box {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          height: 48px;
        }
      }
    }
    .clear {
      clear: both;
    }
  }
</style>

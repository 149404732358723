<template>
  <div class="roi-filter-box">
    <div class="left-box">
      <div class="select-box">
        <DatePicker
          class="time-filter"
          type="daterange"
          :options="date_picker_option"
          :clearable="false"
          v-model="filter.time"
          placeholder="日期"
          style="width: 200px"/>
      </div>
      <div class="select-box">
        <complex-multiple-select
          :data="filter.platform"
          :checkbox_items="platform_list"
          placeholder="投放平台"
          @list-change="platformChangeHandler"/>
      </div>
      <div class="select-box">
        <complex-multiple-select
          :data="filter.group"
          :checkbox_items="group_list"
          placeholder="广告分组"
          @list-change="groupChangeHandler"/>
      </div>
      <div class="select-box">
        <complex-multiple-select
          :data="filter.plan"
          :checkbox_items="plan_list"
          placeholder="广告计划"
          @list-change="planChangeHandler"/>
      </div>
      <div class="select-box">
        <Input
          class="input-filter"
          style="width: 200px"
          v-model="filter.creative_id"
          placeholder="请输入广告创意ID"/>
        <Icon
          class="refresh-icon"
          :class="{'spin': button_loading && !button_click}"
          type="md-refresh"
          size="24"
          @click="refreshFilter"/>
      </div>
    </div>
    <div class="right-box">
      <div class="button-box">
        <Button class="search-button"
                type="primary"
                :disabled="button_loading"
                @click="search">
          <img v-if="button_loading && button_click"
               src="../assets/image/ic_button_loading@2x.png"
               alt="Button loading">
          <span>查询</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import ComplexMultipleSelect from './ComplexMultipleSelect.vue';

export default {
  name: 'ROIFilterBox',
  props: {
    button_loading: {
      type: Boolean,
    },
    filter: {
      type: Object,
    },
    platform_list: {
      type: Array,
    },
    group_list: {
      type: Array,
    },
    plan_list: {
      type: Array,
    },
    filter_visible: {
      type: Object,
      default() {
        return {
          date: true,
          platform: true,
          group: true,
          plan: true,
        };
      },
    },
  },
  components: { ComplexMultipleSelect },
  data() {
    return {
      button_click: true,
      date_picker_option: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
              return [startDate, endDate];
            },
          },
          {
            text: '最近两周',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 14);
              return [startDate, endDate];
            },
          },
          {
            text: '最近30天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 30);
              return [startDate, endDate];
            },
          },
          {
            text: '最近60天',
            value() {
              const today = new Date();
              const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
              const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 60);
              return [startDate, endDate];
            },
          },
        ],
        disabledDate: (date) => date >= Date.now(),
      },
    };
  },
  methods: {
    search() {
      this.$emit('search');
    },
    platformChangeHandler(checkedGroup) {
      this.filter.platform = [...checkedGroup];
    },
    groupChangeHandler(checkedGroup) {
      this.filter.group = [...checkedGroup];
    },
    planChangeHandler(checkedGroup) {
      this.filter.plan = [...checkedGroup];
    },
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    refreshFilter() {
      if (this.filter.platform.length === 1 && this.filter.platform[0] === 'pangle'
        && this.filter.plan.length === 0
        && this.filter.group.length === 0
        && this.filter.creative_id === '') {
        return;
      }
      // Temporary value
      this.filter.platform = ['pangle'];
      this.filter.plan = [];
      this.filter.group = [];
      this.filter.creative_id = '';
      this.initTimeFilter();
      this.button_click = false;
      this.$emit('search');
    },
  },
  watch: {
    button_loading(newVal) {
      if (!newVal) {
        this.button_click = true;
      }
    },
  },
};
</script>

<style lang="less">
  .roi-filter-box {
    .button-box {
      .search-button {
        img, span {
          vertical-align: middle;
        }
        img {
          width: 16px;
          margin-right: 4px;
          animation: imgRotate infinite 0.6s linear;
        }
        @keyframes imgRotate {
          0% {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .roi-filter-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    .left-box {
      flex: 0 0 88%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      /*margin-bottom: -12px;*/
      .select-box, .refresh-box {
        margin-top: 6px;
      }
    }
    .right-box {
      flex: 0 0 12%;
      display: flex;
      flex-direction: row-reverse;
    }
    .select-box {
      width: 200px;
      margin: 0 1% 0 0;
      .refresh-icon {
        display: inline-block;
        cursor: pointer;
      }
      &:last-child {
        width: 240px;
        .input-filter, .refresh-icon {
          vertical-align: middle;
        }
        .input-filter {
          margin-right: 8px;
        }
      }
    }
    .spin {
      cursor: pointer;
      transform-origin: center;
      animation: spin 0.4s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>

export default {
  data() {
    return {
      filter: {
        time: [],
        platform: ['pangle'],
        plan: [],
        group: [],
        creative_id: '',
      },
      platform_list: [
        {
          label: 'pangle',
          value: 'pangle',
        },
      ],
      plan_list: [],
      group_list: [],
    };
  },
  computed: {
    fn_key_map() {
      return new Map([
        [this.fetchPlatformList, 'platform'],
        [this.fetchPlanList, 'plan'],
        [this.fetchGroupList, 'group'],
      ]);
    },
    key_name_map() {
      return new Map([
        ['platform', '广告平台'],
        ['group', '广告组'],
        ['plan', '广告计划'],
      ]);
    },
  },
  methods: {
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    fetchPlatformList() {
      const params = {};
      return this.$api.getAdsPlatformList(this.project_id, params);
    },
    fetchGroupList() {
      const params = {
        network_list: this.filter.platform,
      };
      return this.$api.getAdsGroupList(this.project_id, params);
    },
    fetchPlanList() {
      const params = {
        network_list: this.filter.platform,
        campaign_list: this.filter.plan,
      };
      return this.$api.getAdsPlanList(this.project_id, params);
    },
    filterListCallback(responseData, fn) {
      const key = this.fn_key_map.get(fn);
      const name = this.key_name_map.get(key);
      if (!responseData) {
        throw new Error(`${name}筛选条件请求错误`);
      }
      const { result, list } = responseData;
      if (result === 'success') {
        this[`${key}_list`] = [...list];
      } else {
        throw new Error(`${name}筛选条件请求错误`);
      }
    },
    async fetchROIFilterList(
      fnList = [
        this.fetchPlatformList,
        this.fetchGroupList,
        this.fetchPlanList],
    ) {
      const promises = [];
      try {
        this.button_loading = true;
        fnList.forEach((fn) => {
          promises.push(fn());
        });
        const responses = await Promise.all(promises);
        const dataList = responses.map((response) => response.data);
        dataList.forEach((data, index) => {
          this.filterListCallback(data, fnList[index]);
        });
      } catch (error) {
        console.log(error);
        this.$Message.error(error.message);
      } finally {
        this.button_loading = false;
      }
    },
  },
  watch: {
    'filter.platform': function () {
      this.filter.group = [];
      this.fetchROIFilterList([this.fetchGroupList]);
    },
    'filter.group': function () {
      this.filter.plan = [];
      this.fetchROIFilterList([this.fetchPlanList]);
    },
  },
};

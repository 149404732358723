<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    console.log('App created');
    if (localStorage.getItem('store')) {
      this.$store.replaceState({ ...JSON.parse(localStorage.getItem('store')) });
    }
    this.$store.commit('setSidebarIsExpanded', {
      sidebar_is_expanded: true,
    });
    window.addEventListener('beforeunload', () => {
      console.log('beforeunload');
      localStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
};
</script>
<style lang="less">
  @import "style/common.less";
  @import "style/global.less";
  #app {
    width: 100%;
    height: 100%;
  }
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import cookie from '../utils/cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_name: 'Test',
    user_id: 1,
    token: cookie.getToken() || sessionStorage.getItem('token'),
    selected_project_id: null,
    selected_test_name: null,
    ab_test_duration: [],
    sidebar_is_expanded: true,
    project_list: [
      // {
      //   project_id: '1',
      //   project_name: 'Paintist',
      //   project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
      //   total_user_number: 234234,
      //   total_user_rate: -0.15,
      //   new_user_number: 543345,
      //   new_user_rate: 0.12,
      //   edit_box_visible: false,
      // },
      // {
      //   project_id: '2',
      //   project_name: 'Paintist2',
      //   project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
      //   total_user_number: 234234,
      //   total_user_rate: 0.15,
      //   new_user_number: 543345,
      //   new_user_rate: 0.12,
      //   edit_box_visible: false,
      // },
      // {
      //   project_id: '3',
      //   project_name: 'Paintist3',
      //   project_logo_url: 'https://file.iviewui.com/dist/7dcf5af41fac2e4728549fa7e73d61c5.svg',
      //   total_user_number: 234234,
      //   total_user_rate: 0.15,
      //   new_user_number: 543345,
      //   new_user_rate: 0.12,
      //   edit_box_visible: false,
      // },
    ],
    mark_list: [],
  },
  mutations: {
    setUserName(state, payload) {
      state.user_name = payload.user_name;
    },
    setUserId(state, payload) {
      state.user_id = payload.user_id;
    },
    setToken(state, payload) {
      state.token = payload.token;
    },
    setSelectedProjectID(state, payload) {
      state.selected_project_id = payload.selected_project_id;
    },
    setProjectList(state, payload) {
      state.project_list = payload.project_list;
    },
    setSelectedTestName(state, payload) {
      state.selected_test_name = payload.selected_test_name;
    },
    setABTestDuration(state, payload) {
      state.ab_test_duration = [...payload.duration];
    },
    setMarkList(state, payload) {
      state.mark_list = [...payload.markList];
    },
    setSidebarIsExpanded(state, payload) {
      state.sidebar_is_expanded = payload.sidebar_is_expanded;
    },
  },
  actions: {
    fetchMarkList({ state }, payload) {
      console.log('Start fetch mark list');
      const { dateRange, getMarkList } = payload;
      const userId = state.user_id;
      const projectId = state.selected_project_id;
      const params = {
        user_id: userId,
        date_range: JSON.stringify(dateRange),
      };
      return getMarkList(projectId, { params });
      // .then((response) => {
      //   let { marks } = response.data;
      //   if (marks) {
      //     marks = marks.map((val) => {
      //       const date = new Date(val.date);
      //       // eslint-disable-next-line no-param-reassign
      //       val.date = date;
      //       return val;
      //     });
      //     commit('setMarkList', {
      //       markList: marks,
      //     });
      //     console.log('Commit completed');
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(new Error('标注列表获取失败'));
      // })
      // .catch((error) => {
      //   console.log(error.message);
      // });
    },
  },
  modules: {
  },
});

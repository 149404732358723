import toolFunctions from './toolFunctions';

const formatterFactory = (valueFormatList, markList, config = {}, tooltipColorList = []) => {
  const formatter = (params) => {
    const { name } = params[0];
    let strStart = `<div class="ads-overview-tooltip revenue-tooltip payment-tooltip conversion-rate-tooltip">
          <h4 class="tooltip-date">${name}</h4>
          <div class="tooltip-content-wrap">`;
    const strEndInner = '</div>';
    const strEnd = '</div>';
    let leftStart = '<p class="tooltip-content-left tooltip-content">';
    const leftEnd = '</p>';
    let rightStart = '<p class="tooltip-content-right tooltip-content">';
    const rightEnd = '</p>';
    const titleList = [];
    const valueList = [];
    let total = 0;
    params.forEach((item) => {
      const { seriesName, value } = item;
      titleList.push(seriesName);
      valueList.push(value);
      total += Number(value);
    });
    if (config.showTotal) {
      titleList.push('总计');
      valueList.push(total);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < titleList.length; i++) {
      const title = titleList[i];
      let value = null;
      value = valueFormatList[i] === 'rate' ? toolFunctions.percentageFormat(valueList[i]) : toolFunctions.keepDecimals(valueList[i]);
      let titleStr = '';
      let valueStr = '';
      if (tooltipColorList) {
        if (config.showTotal && i === 0) {
          titleStr = `<span class="tooltip-content-text" style="color: #000000">${title}:</span>`;
          valueStr = `<span class="tooltip-content-text" style="color: #000000">${value}</span>`;
        } else if (title === '总计') {
          titleStr = `<span class="tooltip-content-text" style="color: #000000!important;">${title}:</span>`;
          valueStr = `<span class="tooltip-content-text" style="color: #000000!important;">${value}</span>`;
        } else {
          titleStr = `<span class="tooltip-content-text" style="color: ${tooltipColorList[i]}!important;">${title}:</span>`;
          valueStr = `<span class="tooltip-content-text" style="color: ${tooltipColorList[i]}!important;">${value}</span>`;
        }
      } else if (config.showTotal && i === 0) {
        titleStr = `<span class="tooltip-content-text">${title}:</span>`;
        valueStr = `<span class="tooltip-content-text">${value}</span>`;
      } else {
        titleStr = `<span class="tooltip-content-text">${title}:</span>`;
        valueStr = `<span class="tooltip-content-text">${value}</span>`;
      }
      leftStart += titleStr;
      rightStart += valueStr;
    }
    let markStrStart = '';
    let markStrEnd = '';
    let markStr = '';
    if (Array.isArray(markList)) {
      let flag = false;
      const color = 'rgba(75, 92, 240, 1)';
      // eslint-disable-next-line no-restricted-syntax
      for (const item of markList) {
        const { xAxis, text } = item;
        if (xAxis === name || Number(xAxis) === Number(name)) {
          if (!flag) {
            markStrStart = '<h4 class="tooltip-mark-title">标注</h4><div class="tooltip-mark-wrap">';
            markStrEnd = '</div>';
            flag = true;
          }
          markStr += `<p class="tooltip-mark">
                        <span class="tooltip-mark-text" style="color: ${color}!important;"></span>
                        <span class="tooltip-mark-text" style="color: ${color}!important;">${text}</span>
                    </p>`;
        }
      }
      markStr = markStrStart + markStr + markStrEnd;
    }
    leftStart += leftEnd;
    rightStart += rightEnd;
    strStart = strStart + leftStart + rightStart + strEndInner + markStr + strEnd;
    return strStart;
  };
  return formatter;
};
export default formatterFactory;

<template>
  <div class="top">
    <Dropdown placement="bottom" transfer @on-click="handleDropdownClick">
      <div class="user-info">
        <img class="user-avatar" src="@/assets/image/pic_avatar@2x.png" alt="User Avatar">
        <span class="user-name">{{ user_name }}</span>
      </div>
      <DropdownMenu slot="list">
        <DropdownItem name="config" v-if="false">项目配置</DropdownItem>
        <DropdownItem name="exit">退出登录</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <div v-if="show_app_select" class="select-project">
      <Select v-model="selected_project_id"
              :disabled="app_select_is_disabled">
        <Option v-for="item in project_list"
                :key="item.project_id"
                :value="item.project_id"
                :label="item.project_name">
        </Option>
      </Select>
    </div>
  </div>
</template>

<script>
import cookie from '../../../utils/cookie';

export default {
  name: 'Top',
  data() {
    return {
      selected_project_id: null,
    };
  },
  methods: {
    routeChangeHandler() {
      const projectId = this.$route.params.project_id;
      console.log(projectId);
      if (projectId === this.selected_project_id) {
        return;
      } if (projectId) {
        // watch
        console.log('not null');
        this.selected_project_id = projectId;
      } else {
        this.selected_project_id = null;
      }
    },
    getProjectList() {
      this.project_list = [...this.$store.state.project_list];
    },
    exit() {
      this.$store.commit('setToken', {
        token: undefined,
      });
      sessionStorage.removeItem('token');
      localStorage.removeItem('store');
      cookie.removeToken();
      this.$router.push({
        path: '/login',
      });
    },
    config() {
      this.$router.push({ name: 'projectConfig' });
    },
    handleDropdownClick(name) {
      this[name]();
    },

  },
  computed: {
    user_name() {
      return this.$store.state.user_name;
    },
    project_list() {
      return this.$store.state.project_list;
    },
    show_app_select() {
      return this.selected_project_id;
    },
    app_select_is_disabled() {
      const testId = this.$route.params.test_id;
      return !!testId;
    },
  },
  watch: {
    '$route.path': function () {
      this.routeChangeHandler();
    },
    selected_project_id(newVal) {
      this.$store.commit('setSelectedProjectID', {
        selected_project_id: newVal,
      });
      if (newVal) {
        const { path } = this.$route;
        const arr = path.split('/');
        arr.splice(1, 1, newVal);
        const route = arr.join('/');
        console.dir(arr);
        console.log(route);
        try {
          this.$router.push(route);
        } catch (e) {
          console.log('top');
          console.log(e.message);
        }
      }
    },
  },
  mounted() {
    this.routeChangeHandler();
  },
};
</script>
<style lang="less">
  .top {
    .ivu-select-selection {
      border: none;
    }
    .ivu-select-visible {
      .ivu-select-selection {
        border: none;
        box-shadow: none;
      }
    }
    .ivu-select-single {
      .ivu-select-selection {
        background-color: #ffffff!important;
        .ivu-select-selected-value {
          font-size: 16px;
          font-weight: 600;
          color: #202444;
          padding-right: 30px;
        }
      }
    }
  }
</style>
<style lang="less">
  .top {
    .ivu-poptip-popper {
      min-width: 80px;
      .ivu-poptip-content {
        .ivu-poptip-inner {
          .ivu-poptip-body {
            padding: 8px 0;
            .exit-text {
              display: block;
              padding: 4px 16px;
              cursor: pointer;
              &:hover {
                background-color: #ebf7ff;
              }
            }
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @topWidth: 100%;
  @topHeight: 100%;
  @topPadding: 0 24px;
  @topLineHeight: 24px;
  @userInfoLineHeight: 48px;
  @userNameMarginLeft: 8px;
  .top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: @topWidth;
    height: @topHeight;
    padding: @topPadding;
    line-height: @topLineHeight;
    .user-info {
      line-height: @userInfoLineHeight;
      cursor: pointer;
      .user-name, .user-avatar {
        vertical-align: middle;
      }
      .user-name {
        margin-left: @userNameMarginLeft;
      }
      .user-avatar {
        width: 24px;
        height: 24px;
      }
    }
  }
</style>

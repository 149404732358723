import echarts from 'echarts';
import toolTipFormatter from './toolTipFormatter';
import toolFunctions from './toolFunctions';

const getBarChartOption = (chartData, configuration) => {
  console.log(chartData, configuration);
  const series = [];
  if (configuration.y.multiple) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < chartData.y_axis.length; i++) {
      const item = chartData.y_axis[i];
      const color = chartData?.color[i];
      const temp = {
        name: chartData.name_list[i],
        type: 'bar',
        data: item,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: color || 'rgba(51, 182, 149, 1)' },
              { offset: 1, color: color || 'rgba(98, 220, 199, 1)' },
            ],
          ),
        },
        stack: 'bar',
        barMaxWidth: 72,
        label: {
          show: true,
          color: 'rgba(84, 88, 120, 1)',
          fontSize: 10,
          formatter: (params) => {
            const { value } = params;
            const number = Number(value);
            return toolFunctions.keepDecimals(number);
          },
        },
      };
      series.push(temp);
    }
  } else {
    const item = chartData.y_axis;
    const { color } = chartData;
    const temp = {
      name: chartData.name,
      type: 'bar',
      data: item,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(
          0, 0, 0, 1,
          [
            { offset: 0, color: color || 'rgba(51, 182, 149, 1)' },
            { offset: 1, color: color || 'rgba(98, 220, 199, 1)' },
          ],
        ),
      },
      stack: 'bar',
      barMaxWidth: 72,
      label: {
        show: true,
        color: 'rgba(84, 88, 120, 1)',
      },
    };
    series.push(temp);
  }
  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: toolTipFormatter([], [],
        configuration.series.showTotal ? { showTotal: true } : {}, chartData.tooltip_color),
      renderMode: 'html',
      backgroundColor: 'opacity',
    },
    legend: {
      top: '1%',
      left: '0',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    grid: configuration.grid
      ? {
        top: configuration.grid.top,
        right: configuration.grid.right,
        bottom: configuration.grid.bottom,
        left: configuration.grid.left,
      } : {
        top: '12%',
        bottom: '8%',
        left: '3%',
        right: '3%',
      },
    xAxis: {
      axisLabel: {
        margin: 8,
        color: 'rgba(135, 138, 162, 1)',
        showMaxLabel: true,
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(233, 234, 247, 1)',
        },
      },
      axisPointer: {
        snap: true,
        type: 'line',
        lineStyle: {
          width: 0,
          color: '#E6E7F5',
        },
      },
      axisTick: {
        show: false,
      },
      boundaryGap: true,
      data: chartData.x_axis,
    },
    yAxis: {
      axisLabel: {
        margin: 16,
        fontSize: '12px',
        color: 'rgba(135, 138, 162, 1)',
        formatter: (value) => {
          const type = configuration.y.formatter;
          if (type === 'rate') {
            return toolFunctions.percentageFormat(value);
          } if (type === 'normal') {
            const numberVal = Number(value);
            let result = numberVal;
            const numberCount = toolFunctions.numberOfDigit(numberVal);
            if (!Number.isNaN(numberVal)) {
              if (numberCount > 6) {
                result = `${numberVal / 1000000}M`;
              } else if (numberCount > 3) {
                result = `${numberVal / 1000}K`;
              }
            }
            return result;
          }
          return value;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgb(245, 245, 252)',
        },
      },
    },
    series,
  };
  return option;
};

export default getBarChartOption;

<template>
  <div class="table-operation">
    <Tooltip content="导出文件" placement="top">
      <img src="../assets/image/ic_list_export.png"
           alt="Table export"
           @click="exportData">
    </Tooltip>
    <Tooltip v-if="false" content="更多操作" placement="top">
      <img src="../assets/image/ic_option.png" alt="Table option">
    </Tooltip>
  </div>
</template>

<script>
import xlsx from '../utils/xlsx';

export default {
  name: 'TableOperation',
  props: {
    columns: Array,
    table_data: Array,
  },
  methods: {
    exportData() {
      if (this.columns.length === 0 || this.table_data.length === 0) {
        this.$Message.warning('数据不能为空');
        return;
      }
      xlsx.exportXLSXFile(this.columns, this.table_data);
    },
  },
};
</script>

<style scoped lang="less">
  .table-operation {
    line-height: 22px;
    img {
      height: 16px;
      vertical-align: middle;
      cursor: pointer;
    }
    .ivu-tooltip {
      margin-right: 8px;
    }
  }
</style>

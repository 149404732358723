import toolFunctions from './toolFunctions';
// import toolTipFormatter from './toolTipFormatter';
const getPieChartOption = (chartData, configuration) => {
  const tooltipFormatter = () => (params) => {
    const { name, value, percent } = params;
    const index = chartData.data.findIndex((item) => item.name === name);
    const color = chartData.color[index];
    const number = Number(value);
    let str = `<div class="tooltip">
                  <h4 class="tooltip-title">${name}:</h4>`;
    str += `<p class="tooltip-content">
                      <span class="tooltip-text" style="color: ${color}!important;">${name}:</span>
                      <span class="tooltip-text" style="color: ${color}!important;">${toolFunctions.keepDecimals(number)}(${percent}%)</span>
                  </p>`;
    str += '</div>';
    return str;
  };
  const option = {
    legend: {
      top: '1%',
      left: '0',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    tooltip: {
      trigger: 'item',
      // axisPointer: {
      //   type: 'none',
      // },
      formatter: tooltipFormatter(),
      renderMode: 'html',
      backgroundColor: '#fffff',
    },
    grid: configuration.grid
      ? {
        top: configuration.grid.top,
        right: configuration.grid.right,
        bottom: configuration.grid.bottom,
        left: configuration.grid.left,
      } : {
        top: '12%',
        bottom: '8%',
        left: '3%',
        right: '3%',
      },
    series: {
      type: 'pie',
      center: ['50%', '50%'],
      radius: '55%',
      data: chartData.data,
      label: {
        show: true,
        position: 'outside',
        formatter: (params) => {
          const { name, value } = params;
          const numberValue = Number(value);
          return `${name}: ${toolFunctions.keepDecimals(numberValue)}`;
        },
      },
    },
    color: chartData.color,
  };
  return option;
};

export default getPieChartOption;

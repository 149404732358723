<template>
  <Modal
      class="modal"
      :value="modal_visible"
      :closable="false"
      width="400"
      @on-ok="okHandler"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
    </div>
    <div class="main">
      <div class="input-item">
        <p class="input-title">测试ID</p>
        <Input class="input-text"
               type="number"
               placeholder="请输入"
               v-model="test.id"/>
      </div>
      <div class="input-item">
        <p class="input-title">测试名称</p>
        <Input class="input-text"
               placeholder="请输入"
               v-model="test.name"/>
      </div>
      <div class="input-item">
        <p class="input-title">开始时间</p>
        <DatePicker class="input-date"
                    placeholder="请选择"
                    v-model="test.start"></DatePicker>
      </div>
      <div class="input-item">
        <p class="input-title">结束时间</p>
        <DatePicker class="input-date"
                    :options="date_picker_option"
                    placeholder="请选择"
                    v-model="test.end"></DatePicker>
      </div>
      <div class="input-item">
        <p class="input-title">跟踪指标</p>
        <Select class="input-select"
                placeholder="请选择"
                v-model="test.reference">
          <Option v-for="item in reference_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></Option>
        </Select>
      </div>
      <div class="input-item">
        <p class="input-title">备注</p>
        <Input class="input-textarea"
               type="textarea"
               placeholder="请填写"
               :rows="3"
               v-model="test.remarks"/>
      </div>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'CreateTestModal',
  props: {
    modal_visible: Boolean,
    test_id_list: Array,
  },
  data() {
    return {
      header_title: '添加测试',
      test: {
        id: '',
        name: '',
        start: '',
        end: '',
        reference: '',
        remarks: '',
      },
      reference_list: [
        {
          label: '留存',
          value: 'retention',
        },
        {
          label: '安装卸载率',
          value: 'new_uninstall_rate',
        },
        {
          label: '内购',
          value: 'in_app_purchase',
        },
      ],
    };
  },
  methods: {
    check() {
      const arr = Object.entries(this.test);
      // eslint-disable-next-line no-restricted-syntax
      for (const item of arr) {
        const key = item[0];
        const value = item[1];
        const formatValue = `firebase_exp_${item[1]}`;
        if (!value && key !== 'end' && key !== 'remarks') {
          this.$Message.warning('输入不能为空');
          return false;
        } if (key === 'id' && this.test_id_list.indexOf(formatValue) !== -1) {
          this.$Message.warning('测试ID已存在');
          return false;
        }
      }
      return true;
    },
    okHandler() {
      if (!this.check()) {
        return;
      }
      const test = { ...this.test };
      test.id = `firebase_exp_${test.id}`;
      test.start = this.$tools.dateFormat(test.start);
      test.end = test.end ? this.$tools.dateFormat(test.end) : '';
      this.$emit('ok', test);
      this.$emit('cancel');
    },
    cancelHandler() {
      // Refresh data
      Object.assign(this.$data, this.$options.data());
      this.$emit('cancel');
    },
  },
  computed: {
    date_picker_option() {
      return {
        disabledDate: (date) => {
          if (!this.test.start) {
            return false;
          }
          return date && date.valueOf() <= this.test.start.valueOf();
        },
      };
    },
  },
};
</script>

<style lang="less">
  @import "../../style/modal.less";
  .modal {
    .ivu-input[rows='3'] {
      height: auto!important;
    }
    span.ivu-date-picker-cells-cell-disabled, span.ivu-date-picker-cells-cell-disabled:hover {
      color: #c5c8ce;
      background-color: #F7F7F7;
    }
  }
</style>
<style scoped lang="less">
  @paddingSide: 32px;
  @headerPadding: 20px 32px;
  @footerPadding: 24px 32px;
  @inputTitleMarginBottom: 6px;
  @inputTextMarginBottom: 25px;
  .headerTitle {
    font-size: 20px;
    font-weight: 600;
    color: #202444;
    line-height: 28px;
  }
  .inputTitle {
    font-size: 12px;
    font-weight: 400;
    color: #545778;
    line-height: 17px;
  }
  .modal {
    .header {
      padding: @headerPadding;
      .header-title {
        .headerTitle();
      }
    }
    .main {
      padding: 0 @paddingSide;
      .input-item {
        display: flex;
        flex-direction: column;
        .input-title {
          .inputTitle();
          margin-bottom: @inputTitleMarginBottom;
        }
        .input-text, .input-select, .input-date {
          margin-bottom: @inputTextMarginBottom;
        }
      }
    }
    .footer {
      padding: @footerPadding;
    }
  }
</style>

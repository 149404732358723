<template>
  <div class="login">
    <div class="login-form">
      <img class="logo" src="../../assets/image/login_logo@2x.png" alt="Login Logo">
      <Input class="input" type="text"
             placeholder="用户名"
             v-model="user_name"/>
      <Input class="input" type="password"
             placeholder="密码"
             v-model="password"
             @on-enter="login"/>
      <Checkbox class="checkbox" v-model="remember_me">记住我</Checkbox>
      <Button class="btn"
              type="primary"
              size="small"
              :disabled="button_loading"
              @click="login">
        <img v-if="button_loading"
             src="../../assets/image/ic_button_loading@2x.png"
             alt="Button loading">
        <span>登录</span>
      </Button>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Crypto from 'crypto-js';
import cookie from '../../utils/cookie';

export default {
  name: 'Login',
  data() {
    return {
      button_loading: false,
      user_name: '',
      password: '',
      remember_me: false,
    };
  },
  methods: {
    judgeNull() {
      if (this.user_name === '' || this.password === '') {
        return true;
      }
      return false;
    },
    login() {
      if (this.judgeNull()) {
        this.$Message.warning('输入不能为空');
        return;
      }
      this.button_loading = true;
      const params = {
        username: this.user_name,
        // password: Crypto.SHA1(this.password).toString(),
        password: this.password,
      };
      this.$api.login(params)
        .then((response) => {
          console.dir(response);
          const { authenticated, token, id } = response.data;
          if (authenticated === 'true' || token) {
            this.$Message.success('登录成功');
            // Set store user name
            this.$store.commit('setUserName', {
              user_name: this.user_name,
            });
            this.$store.commit('setUserId', {
              user_id: id,
            });
            this.$store.commit('setToken', {
              token,
            });
            sessionStorage.setItem('token', token);
            if (this.remember_me) {
              // Set cookie
              cookie.setToken(token, 7);
            }
            const route = {
              path: '/',
            };
            this.$router.push(route);
          } else {
            this.$Message.error('登录失败');
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          this.button_loading = false;
        });
    },
  },
};
</script>

<style lang="less">
  @loginInputHeight: 33px;
  @loginInputPadding: 0 12px;
  @loginInputFontSize: 12px;
  .login {
    .ivu-input {
      height: @loginInputHeight;
      padding: @loginInputPadding;
      font-size: @loginInputFontSize;
    }
    .ivu-checkbox {
      margin-right: 6px;
    }
  }
</style>
<style scoped lang="less">
  @loginBg: url('../../assets/image/bg_signin.png');
  @loginFormWidth: 300px;
  @loginFormPadding: 54px 36px;
  @loginFormBgColor: #ffffff;
  @loginFormBorderRadius: 8px;
  @loginFormBoxShadow: 0px 2px 18px 0px rgba(13,17,52,0.08);
  @loginLogoHeight: 30px;
  @loginLogoMargin: 0 0 24px 0;
  @loginInputMargin: 0 0 9px 0;
  @loginLastInputMargin: 0 0 32px 0;
  @loginCheckboxMargin: 0 0 14px 0;
  @loginCheckboxFontSize: 12px;
  @loginCheckboxFontWeight: 400;
  @loginCheckboxColor: #545878;
  @loginCheckboxLineHeight: 15px;
  @loginBtnFontSize: 11px;
  @loginBtnFontWeight: 200;
  @loginBtnColor: #ffffff;
  @loginBtnLineHeight: 17px;
  .login {
    width: 100%;
    height: 100%;
    background-image: @loginBg;
    background-size: cover;
    background-position: center;
    .login-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: @loginFormWidth;
      padding: @loginFormPadding;
      background-color: @loginFormBgColor;
      border-radius: @loginFormBorderRadius;
      box-shadow: @loginFormBoxShadow;
      .logo {
        height: @loginLogoHeight;
        margin: @loginLogoMargin;
      }
      .input {
        margin: @loginInputMargin;
      }
      .input:nth-child(3) {
        margin: @loginLastInputMargin;
      }
      .checkbox {
        display: block;
        margin: @loginCheckboxMargin;
        font-size: @loginCheckboxFontSize;
        font-weight: @loginCheckboxFontWeight;
        color: @loginCheckboxColor;
        line-height: @loginCheckboxLineHeight;
      }
      .btn {
        width: 100%;
        img {
          height: 16px;
          margin-right: 6px;
          animation: spin 0.6s infinite linear;
        }
        img, span {
          vertical-align: middle;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>

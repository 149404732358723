<template>
  <div class="edit-box">
    <div v-if="edit_text !== 'empty'" class="btn" @click="editHandler">{{ edit_text }}</div>
    <div class="btn" @click="deleteHandler">删除</div>
  </div>
</template>

<script>
export default {
  name: 'ProjectListEditBox',
  props: {
    edit_text: {
      type: String,
      default: '编辑',
    },
  },
  methods: {
    editHandler() {
      this.$emit('on-edit');
    },
    deleteHandler() {
      this.$emit('on-delete');
    },
    hideHandler() {
    },
  },
  mounted() {
    this.hideHandler = () => {
      // Why isn't it called when the edit button is clicked ???
      this.$emit('on-hide');
    };
    document.addEventListener('click', this.hideHandler);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideHandler);
  },
};
</script>

<style scoped lang="less">
  .edit-box {
    width: 100%;
    .btn {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      font-weight: 400;
      color: #202444;
      background-color: #ffffff;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #eff0f4;
      }
    }
  }
</style>

<template>
  <div class="loading" v-if="visible" :style="loadingBackgroundColor">
    <div class="sp-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    background_color: {
      type: String,
      default: 'rgb(255, 255, 255)',
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    element() {
      return this.$el;
    },
    show() {
      if (!this.$el) return;
      this.visible = true;
    },
    hide() {
      if (!this.$el) return;
      this.visible = false;
    },
  },
  computed: {
    loadingBackgroundColor() {
      return {
        backgroundColor: this.background_color,
      };
    },
  },
};
</script>

<style scoped lang="less">
  .loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 1);
    .sp-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 4px solid rgba(0, 0, 0, 0.25);
      border-top: 4px solid rgba(0, 0, 0, 1);
      transform-origin: center;
      animation: spinCircleRotation 0.6s infinite linear;
    }
  }
  @keyframes spinCircleRotation {
    0% {
      transform: translate(-50%, -50%)  rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
</style>

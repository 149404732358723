import Cookies from 'js-cookie';

const tokenKey = 'token';
export default {
  getToken() {
    return Cookies.get(tokenKey);
  },
  setToken(token, expires) {
    return Cookies.set(tokenKey, token, { expires });
  },
  removeToken() {
    return Cookies.remove(tokenKey);
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import configRoute from '@/router/configRoute';
import store from '../store/index';
import Home from '../views/home/Home.vue';
import Login from '../views/login/Login.vue';
import ProjectList from '../views/home/content/ProjectList.vue';
import ProjectListMenu from '../views/home/sidebar/menu/ProjectListMenu.vue';
import ProjectOverview from '../views/home/content/ProjectOverview.vue';
import ProjectMenu from '../views/home/sidebar/menu/ProjectMenu.vue';
import ProjectKeep from '../views/home/content/ProjectKeep.vue';
import ProjectUninstallRate from '../views/home/content/ProjectUninstallRate.vue';
import ProjectRevenue from '../views/home/content/ProjectRevenue.vue';
import ProjectABTest from '../views/home/content/ProjectABTest.vue';
import ProjectABTestOverview from '../views/home/content/ProjectABTestOverview.vue';
import ProjectABTestDetail from '../views/home/content/ProjectABTestDetail.vue';
import cookie from '../utils/cookie';
import jump from '../views/home/content/jump.vue';
import AdsOverview from '../views/home/content/ads/AdsOverview.vue';
import AdsROI from '../views/home/content/ads/AdsROI.vue';
import AdsRevenue from '../views/home/content/ads/AdsRevenue.vue';
import AdsRetention from '../views/home/content/ads/AdsRetention.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      path: '/project_list',
    },
    children: [
      {
        path: 'project_list',
        name: 'ProjectList',
        components: {
          default: ProjectList,
          sidebar: ProjectListMenu,
        },
      },
      {
        path: ':project_id/ads_overview',
        components: {
          default: AdsOverview,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ads_roi',
        components: {
          default: AdsROI,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ads_overview',
        components: {
          default: AdsOverview,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ads_roi',
        components: {
          default: AdsROI,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ads_revenue',
        components: {
          default: AdsRevenue,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ads_retention',
        components: {
          default: AdsRetention,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/overview',
        components: {
          default: ProjectOverview,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/keep',
        components: {
          default: ProjectKeep,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/uninstall_rate',
        components: {
          default: ProjectUninstallRate,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/revenue',
        components: {
          default: ProjectRevenue,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ab_test',
        components: {
          default: ProjectABTest,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ab_test/:test_id',
        components: {
          default: ProjectABTestOverview,
          sidebar: ProjectMenu,
        },
      },
      {
        path: ':project_id/ab_test/:test_id/:index',
        components: {
          default: ProjectABTestDetail,
          sidebar: ProjectMenu,
        },
      },
    ],
  },
  ...configRoute,
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/jump',
    name: 'Jump',
    component: jump,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'BI数据分析系统';
  const token = store.state.token || cookie.getToken();
  const isLogin = !!token;
  const { path } = to;
  if (isLogin && path !== '/login') {
    next();
  } else if (isLogin && path === '/login') {
    next('/');
  } else if (!isLogin && path === '/login') {
    next();
  } else {
    next('/login');
  }
});
export default router;

<template>
  <div class="overview-chart" :ref="overview_chart_ref">
    <h3 class="chart-name">{{ chart_data.name }}</h3>
    <Icon class="chart-option" type="ios-expand" size="16" @click="showEditBox"/>
    <div class="container" v-if="!is_null">
      <h1 class="chart-data-today">{{ getDataToday(chart_data) }}</h1>
      <p class="chart-detail">
        <span class="chart-date">{{ chart_data.date }}</span>
        <span class="chart-text">
        同比
        <span class="chart-text-rate"
              :class="{
                        'positive-percentage': chart_data.rate > 0,
                        'negative-percentage': chart_data.rate <= 0
                      }">
          {{ $tools.percentageFormat(chart_data.rate) }}
        </span>
        <img class="chart-text-icon" :src="getArrowIcon(chart_data.rate)" alt="Status icon">
      </span>
      </p>
      <div class="chart" :ref="chart_ref" v-if="!is_null"></div>
    </div>
    <div class="empty-result-container" v-if="is_null">
<!--      <div class="empty-result">-->
        <Icon class="empty-icon" size="54" type="ios-folder-open-outline" color="#e1e1e1"/>
        <p class="empty-text">数据不足</p>
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import icIncrease from '../assets/image/ic_increase.png';
import icDecrease from '../assets/image/ic_decrease.png';
import getLineChartOption from '../utils/getLineChartOption';

export default {
  name: 'OverviewChart',
  props: {
    chart_data: Object,
  },
  data() {
    return {
      ic_increase: icIncrease,
      rate_name_list: ['日活/安装', '新增卸载率', '次留'],
    };
  },
  methods: {
    showEditBox() {
      this.$emit('on-show-chart-detail', this.chart_data);
    },
    getArrowIcon(rate) {
      return rate > 0 ? icIncrease : icDecrease;
    },
    getDataToday(item) {
      if (this.rate_name_list.indexOf(item.name) !== -1) {
        return this.$tools.percentageFormat(item.data_today);
      }
      return this.$tools.keepDecimals(item.data_today);
    },
    drawChart() {
      console.log('draw chart');
      if (!this.is_null) {
        const chartType = this.chart_data.type;
        if (chartType === 'line') {
          this.drawLineChart();
        } if (chartType === 'pie') {
          this.drawPieChart();
        }
      }
    },
    drawLineChart() {
      console.log('Draw line chart');
      const ref = this.chart_ref;
      const chartElement = this.$refs[ref];
      if (!chartElement) return;
      const chart = this.$echarts.init(chartElement);
      const chartData = this.chart_data;
      const configuration = {
        x: {
          padding: [0, 60, 0, 0],
          formatter: (value, index) => (index < (chartData.x_axis.length - 1)
            ? ''
            : value),
        },
        y: {
          margin: 8,
          splitLine: false,
          formatter: this.rate_name_list.indexOf(chartData.name) !== -1
            ? 'rate'
            : 'normal',
        },
        series: {
          formatter: this.rate_name_list.indexOf(chartData.name) !== -1
            ? 'rate'
            : 'normal',
        },
        grid: {
          top: '12px',
          bottom: '24px',
          left: '30px',
          right: '6px',
        },
      };
      const option = getLineChartOption(this.chart_data, configuration);
      chart.setOption(option);
    },
    drawPieChart() {
      this.$Message.info('Draw pie chart.');
    },
  },
  computed: {
    overview_chart_ref() {
      return `overview-chart-${this.chart_data.name}`;
    },
    chart_ref() {
      return `chart-${this.chart_data.name}`;
    },
    is_null() {
      return this.$tools.isNullObj(this.chart_data) || !this.chart_data.x_axis;
    },
  },
  watch: {
    chart_data(newVal) {
      console.dir(newVal);
      console.log('chart_data changes');
      if (!this.is_null) {
        this.$nextTick(() => {
          this.drawChart();
        });
      }
    },
  },
  mounted() {
    // asynchronous
    // setTimeout(() => {
    //   console.log('延迟0.5s执行');
    //   this.drawChart();
    // }, 500);
    // this.drawChart();
    // const chartElement = this.$refs[this.overview_chart_ref];
    // const instance = this.$loading(chartElement);
    // instance.show();
    // setTimeout(instance.close, 3000);
  },
};
</script>
<style lang="less">
  @import '../style/tooltip.less';
</style>
<style scoped lang="less">
  @chartPadding: 6% 8%;
  @chartBgColor: #ffffff;
  @chartBorderRadius: 4px;
  @chartNameMargin: 4px;
  @chartOptionWidth: 16px;
  @chartDateTodayFontSize: 38px;
  @chartDateTodayFontWeight: 500;
  @chartDateTodayColor: #202444;
  @chartDateTodayLineHeight: 46px;
  @chartTextLineHeight: 20px;
  @chartTextRateMargin: 0 0 0 4px;
  @chartTextIconMargin: 0 0 0 4px;
  @chartTextRateDecreaseColor: #F94D71;
  @chartTextRateIncreaseColor: #02B286;
  @chartWidth: 100%;
  @chartHeight: calc(~'100% - 72px');
  .h3Title {
    font-size: 14px;
    font-weight: 400;
    color: #545878;
    line-height: 20px;
  }
  .chartDetailText {
    font-size: 14px;
    font-weight: 200;
    color: rgba(135, 138, 162, 1);
    line-height: 16px;
  }
  .overview-chart {
    position: relative;
    width: 100%;
    height: 100%;
    padding: @chartPadding;
    background-color: @chartBgColor;
    border-radius: @chartBorderRadius;
    .chart-name {
      margin: @chartNameMargin;
      .h3Title();
    }
    .chart-option {
      position: absolute;
      width: @chartOptionWidth;
      top: 8%;
      right: 6%;
      cursor: pointer;
    }
    .container {
      height: calc(~'100% - 24px');
      .chart-data-today {
        font-size: @chartDateTodayFontSize;
        font-weight: @chartDateTodayFontWeight;
        color: @chartDateTodayColor;
        line-height: @chartDateTodayLineHeight;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .chart-detail {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .chart-date, .chart-text {
          .chartDetailText();
        }
        .chart-text {
          line-height: @chartTextLineHeight;
          .chart-text-rate {
            margin: @chartTextRateMargin;
            vertical-align: middle;
          }
          .positive-percentage {
            color: @chartTextRateIncreaseColor;
          }
          .negative-percentage {
            color: @chartTextRateDecreaseColor;
          }
          .chart-text-icon {
            margin: @chartTextIconMargin;
            vertical-align: middle;
          }
        }
      }
      .chart {
        width: @chartWidth;
        height: @chartHeight;
      }
    }
    /*.empty-result-container {*/
    /*  position: relative;*/
    /*  width: 100%;*/
    /*  height: calc(~'100% - 24px');*/
    /*  .empty-result {*/
    /*    position: absolute;*/
    /*    top: 50%;*/
    /*    left: 50%;*/
    /*    transform: translate(-50%, -50%);*/
    /*    text-align: center;*/
    /*    .empty-icon {*/
    /*      margin-bottom: 12px;*/
    /*    }*/
    /*    .empty-text {*/
    /*      font-size: 18px;*/
    /*      font-weight: 400;*/
    /*      color: #c1c1c1;*/
    /*    }*/
    /*  }*/
    /*}*/
    .empty-result-container {
      position: absolute;
      /*top: 24px;*/
      /*left: 0;*/
      /*width: 100%;*/
      /*height: calc(~'100% - 24px');*/
      /*.empty-result {*/
      /*  position: absolute;*/
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .empty-icon {
        margin-bottom: 12px;
      }
      .empty-text {
        font-size: 18px;
        font-weight: 400;
        color: #c1c1c1;
      }
      /*}*/
    }
  }
</style>

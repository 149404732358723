import axios from 'axios';
import store from '../store/index';
import cookie from '../utils/cookie';
import router from '../router/index';

axios.defaults.baseURL = 'https://dc-bi-center.doviapps.com/api/v1/';
axios.defaults.timeout = 10000;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `JWT ${store.state.token}`;
    }
    return config;
  },
  (error) => Promise.error(error),
);
// Response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  }, (error) => {
    if (!error.response) return Promise.reject(error);
    const { status } = error.response;
    const { url } = error.response.config;
    if (error.message.includes('timeout')) {
      // eslint-disable-next-line no-param-reassign
      error.message = '请求超时';
      return Promise.reject(error);
    }
    if (status) {
      switch (status) {
        case 400:
          if (url.indexOf('login') !== -1) {
            // eslint-disable-next-line no-param-reassign
            error.message = '用户名或密码错误';
          }
          break;
        case 401:
          // eslint-disable-next-line no-param-reassign
          error.message = '身份过期，请重新登录';
          store.commit('setToken', {
            token: undefined,
          });
          sessionStorage.removeItem('token');
          localStorage.removeItem('store');
          cookie.removeToken();
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        case 500:
          // eslint-disable-next-line no-param-reassign
          error.message = '服务器错误';
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  },
);
export const getRequest = (url, params) => axios.get(url, params);
export const postRequest = (url, params) => axios.post(url, params);
export const putRequest = (url, params) => axios.put(url, params);
export const deleteRequest = (url, params) => axios.delete(url, params);

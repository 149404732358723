import { format } from 'date-fns';

export default {
  /**
   * Judge whether an object is null
   * @param obj {Object}
   * @returns {boolean}
   */
  isNullObj(obj) {
    return JSON.stringify(obj) === '{}';
  },
  /**
   * Judge whether an object is changed
   * @param newObj {Object}
   * @param oldObj {Object}
   * @returns {boolean}
   */
  isModified(newObj, oldObj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(newObj)) {
      if (newObj[key] !== oldObj[key] && !(newObj[key] instanceof Object)) {
        return true;
      }
    }
    return false;
  },
  /**
   * Get outermost param in URL.
   * Used mainly in sidebar router
   * @param url {String}
   * @return {*}
   */
  getOutermostParameterInURL(url) {
    const regx = /(\/.+)*\/(.+)/;
    const matches = url.match(regx);
    const result = matches[matches.length - 1];
    return result;
  },
  imageFileToURL(file) {
    const URLObject = window.URL || window.webkitURL || window.mozURL;
    const url = URLObject.createObjectURL(file);
    return url;
  },
  // 2020-01-01
  dateFormat(date) {
    const result = format(date, 'yyyy-MM-dd');
    return result;
  },
  percentageFormat(value) {
    const number = Number(value);
    if (Number.isNaN(number) || number === 0) {
      return value;
    }
    if (number === -1) {
      return '';
    }
    const temp = number * 10000;
    return `${Math.round(temp) / 100}%`;
  },
  keepDecimals(number) {
    if (typeof number !== 'number') {
      return number;
    }
    const result = Math.round(number * 100) / 100;
    return result;
  },
  numberOfDigit(number) {
    let temp = number;
    let result = 0;
    while (temp >= 1) {
      temp /= 10;
      // eslint-disable-next-line no-plusplus
      result++;
    }
    return result;
  },
  // Temp
  formatChartData(val, name) {
    const keyArr = Object.keys(val);
    keyArr.forEach((key) => {
      const numberKey = Number(key);
      if (!Number.isNaN(numberKey)) {
        const obj = val[key];
        // eslint-disable-next-line no-param-reassign
        val[key] = obj[name];
      }
    });
  },
  tableCellValueEllipsis(value) {
    if (!value) {
      return value;
    }
    const { length } = value;
    if (length < 13) {
      return value;
    }
    const startStr = value.slice(0, 4);
    const endStr = value.slice(-4);
    const resultStr = `${startStr}...${endStr}`;
    return resultStr;
  },
  getMarkDateRange(arr, dateStart) {
    const { length } = arr;
    const lastElement = arr[length - 1];
    const temp = Number(lastElement);
    if (Number.isNaN(temp)) {
      // format: 2021-3-12
      const dateEnd = lastElement;
      return [this.dateFormat(dateStart), dateEnd];
    }
    // format: 3
    const dateEnd = new Date(dateStart.getTime() + 3600 * 1000 * 24 * temp);
    return [this.dateFormat(dateStart), this.dateFormat(dateEnd)];
  },
  getMarkDate(arr, date, dateRange, flag = false) {
    const numberVal = Number(arr[0]);
    const dateStart = dateRange[0];
    const dateTarget = new Date(date);
    if (!Number.isNaN(numberVal)) {
      let result = (dateTarget.getTime() - dateStart.getTime()) / (3600 * 1000 * 24);
      if (flag) {
        result += 1;
      }
      result = Math.floor(result);
      return result;
    }
    return this.dateFormat(date);
  },
};

<template>
  <Button class="btn"
          :type="type"
          :disabled="button_loading"
          @click="click">
    <img v-if="button_loading && show_loading"
         src="../assets/image/ic_button_loading@2x.png"
         alt="Button loading">
    <span>{{ text }}</span>
  </Button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    button_loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Button',
    },
    show_loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('on-click');
    },
  },
};
</script>

<style scoped lang="less">
  .btn {
    font-size: 14px;
    font-weight: 200;
    line-height: 17px;
    img {
      height: 16px;
      margin-right: 6px;
      animation: spin 0.6s infinite linear;
    }
    img, span {
      vertical-align: middle;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>

<template>
  <div class="project-list" ref="project_list">
    <div class="header">
      <h1 class="header-title">{{ header_title }}</h1>
      <div v-if="false" class="header-operation">
        <Input class="header-search"
               search
               v-model="search_content"
               @on-enter="searchEnter"/>
        <Button class="header-button"
                type="primary"
                @click="showCreateProjectModal">
          <img src="../../../assets/image/ic_add.png" alt="Add">
          <span>{{ header_button }}</span>
        </Button>
      </div>
    </div>
    <div class="empty-list-wrap" v-if="empty_list_visible">
      <div class="empty-list-box">
        <img class="empty-list-img"
             src="../../../assets/image/pic_home_empty@2x.png"
             alt="Empty list">
        <h1 class="empty-list-title">{{ empty_list_title }}</h1>
        <p class="empty-list-description">{{ empty_list_description }}</p>
      </div>
    </div>
    <div class="project-list-wrap" v-if="!empty_list_visible">
      <div class="project-list">
        <div class="project-item item-header">
          <h2 class="column">{{ header_titles[0] }}{{ project_list_length }}</h2>
          <h2 class="column"
              :class="{'operation': index === header_titles.length - 1}"
              v-for="(item, index) in header_titles.slice(1)"
              :key="item"
              >{{ item }}</h2>
        </div>
        <div class="project-item"
             v-for="(item, index) in project_list"
             :key="item.project_id">
          <div class="project-info info" @click="jumpToProjectDetail(item)">
            <img class="project-logo"
                 :src="getProjectLogoURL(item.project_logo_url)"
                 alt="Project Logo">
            <h2 class="project-name">{{ item.project_name }}</h2>
          </div>
          <div class="total-user-info info">
            <h1 class="total-user-number">
              {{ item.total_user_number === -1 ? '-' : item.total_user_number }}
            </h1>
            <p class="total-user-rate">
              <span>周同比</span>
              <span class="rate-text"
                    :class="{
                'positive-rate': item.total_user_rate > 0,
                'negative-rate': item.total_user_rate < 0
              }">
                {{ $tools.percentageFormat(item.total_user_rate) }}
<!--                <span v-if="item.total_user_rate === 0">-</span>-->
              </span>
              <img
                v-if="item.total_user_rate"
                :src="getArrowIcon(item.total_user_rate)" alt="Arrow Icon">
            </p>
          </div>
          <div class="active-user-info info">
            <h1 class="active-user-number">
              {{ item.active_user_number === -1 ? '-' : item.active_user_number }}
            </h1>
            <p class="active-user-rate">
              <span>周同比</span>
              <span class="rate-text"
                    :class="{
                'positive-rate': item.active_user_rate > 0,
                'negative-rate': item.active_user_rate < 0
              }">
                {{ $tools.percentageFormat(item.active_user_rate) }}
<!--                <span v-if="item.active_user_rate === 0">-</span>-->
              </span>
              <img
                v-if="item.active_user_rate"
                :src="getArrowIcon(item.active_user_rate)" alt="Arrow Icon">
            </p>
          </div>
          <div class="new-user-info info">
            <h1 class="new-user-number">
              {{ item.new_user_number === -1 ? '-' : item.new_user_number }}
            </h1>
            <p class="new-user-rate">
              <span>周同比</span>
              <span class="rate-text"
                    :class="{
                'positive-rate': item.new_user_rate > 0,
                'negative-rate': item.new_user_rate < 0
              }">
                {{ $tools.percentageFormat(item.new_user_rate) }}
<!--                <span v-if="item.new_user_rate === 0">-</span>-->
              </span>
              <img
                v-if="item.new_user_rate"
                :src="getArrowIcon(item.new_user_rate)" alt="Arrow Icon">
            </p>
          </div>
          <div v-if="false" class="operation">
            <img class="operation-img"
                 src="../../../assets/image/ic_option.png"
                 alt="Option Logo"
                 @click.stop="showEditBox(item, index)">
          </div>
        </div>
      </div>
    </div>
    <div v-show="edit_box_visible"
         class="edit-box-wrap"
         ref="edit_box">
      <project-list-edit-box
        @on-edit="projectItemEditHandler(selected_item)"
        @on-delete="projectItemDeleteHandler(selected_item)"
        @on-hide="projectBlurHandler()">
      </project-list-edit-box>
    </div>
    <CreateProjectModal
      :modal_visible="project_info_modal_visible"
      :title="project_info_modal_title"
      :item="selected_item"
      :project_id_list="project_id_list"
      @create="createProjectHandler"
      @modify="modifyProjectHandler"
      @cancel="createProjectModalCancelHandler">
    </CreateProjectModal>
    <ConfirmModal
      :modal_visible="confirm_modal_visible"
      :confirm_message="confirm_modal_message"
      @confirm="confirmHandler"
      @cancel="cancelHandler">
    </ConfirmModal>
  </div>
</template>

<script>
import CreateProjectModal from '../../../components/modal/CreateProjectModal.vue';
import ProjectListEditBox from '../../../components/ProjectListEditBox.vue';
import icIncrease from '../../../assets/image/ic_increase.png';
import icDecrease from '../../../assets/image/ic_decrease.png';
import ConfirmModal from '../../../components/modal/ConfirmModal.vue';

export default {
  name: 'ProjectList',
  // eslint-disable-next-line vue/no-unused-components
  components: { ConfirmModal, ProjectListEditBox, CreateProjectModal },
  data() {
    return {
      project_info_modal_visible: false,
      project_info_modal_title: '添加项目',
      selected_item: {},
      selected_item_index: null,
      // header_titles: ['项目', '总用户数', '日活用户数', '新增用户数', ''],
      header_titles: ['项目'],
      search_content: '',
      edit_box_visible: false,
      confirm_modal_visible: false,
      confirm_modal_message: '确定要删除所选项目吗？',
      project_id_list: [],
      project_list: [],
    };
  },
  methods: {
    projectListCallback(flag) {
      this.project_list.forEach((item) => {
        this.$set(item, 'project_logo', null);
        this.$set(item, 'edit_box_visible', false);
      });
      if (flag) {
        this.$store.commit('setProjectList', {
          project_list: [...this.project_list],
        });
      }
    },
    /**
     * Fetch project list
     */
    fetchProjectList() {
      console.log('ProjectList');
      const params = {
        search_content: '',
      };
      const loadingInstance = this.$loading(this.parent);
      loadingInstance.show();
      this.$api.getProjectList(params)
        .then((response) => {
          const arr = response.data.projects;
          // const arr = response.data.projects.map((val) => {
          //   // eslint-disable-next-line no-param-reassign
          //   val.total_user_rate = this.$tools.keepDecimals(val.total_user_rate);
          //   // eslint-disable-next-line no-param-reassign
          //   val.new_user_rate = this.$tools.keepDecimals(val.new_user_rate);
          //   return val;
          // });
          // It could be merged into a function
          this.project_list = [...arr];
          this.projectListCallback(true);
        })
        .catch((error) => {
          console.log('project list error');
          this.$Message.error(error.message);
        })
        .finally(() => {
          loadingInstance.hide();
        });
    },
    fetchProjectIDList() {
      const params = {};
      this.$api.getProjectIDList(params)
        .then((response) => {
          this.project_id_list = [...response.data.project_id_list];
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    /**
     * Search some project
     */
    searchEnter() {
      if (this.search_content === '') {
        // Request for project list
        this.fetchProjectList();
        return;
      }
      const params = {
        search_content: this.search_content,
      };
      const instance = this.$loading(this.parent);
      instance.show();
      this.$api.searchProject({ params })
        .then((response) => {
          const list = response.data.projects;
          if (list.length > 0) {
            this.project_list = [...list];
            this.projectListCallback(false);
          } else {
            this.$Message.warning('搜索结果为空');
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
          console.log(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    /**
     * Jump to project detail
     * @param item {Object}
     */
    jumpToProjectDetail(item) {
      this.$store.commit('setSelectedProjectID', {
        selected_project_id: item.project_id,
      });
      const route = {
        path: `/${item.project_id}/ads_overview`,
      };
      try {
        this.$router.push(route);
      } catch (e) {
        console.log('project list');
        console.log(e.message);
      }
    },
    showCreateProjectModal() {
      // Reset selected_item
      this.selected_item = {};
      this.project_info_modal_title = '添加项目';
      this.project_info_modal_visible = true;
    },
    getProjectLogoURL(projectLogoURL) {
      return `${process.env.VUE_APP_BASE_URL}${projectLogoURL}`;
    },
    /**
     * Create a new project
     * @param project {Object}
     */
    createProjectHandler(project) {
      console.dir(project);
      const instance = this.$loading(this.parent);
      instance.show();
      // Send request
      this.$api.createProject(project)
        // eslint-disable-next-line consistent-return
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('项目创建成功');
            return this.$api.getProjectList({});
          }
          this.$Message.error('项目创建失败');
          return Promise.reject(new Error('项目创建失败'));
        })
        .then((response) => {
          console.dir(response);
          if (response) {
            this.project_list = [...response.data.projects];
            this.projectListCallback(true);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    /**
     * Modify an existed project
     * @param project {Object}
     */
    modifyProjectHandler(project, projectId) {
      console.dir(project);
      // Send request
      const instance = this.$loading(this.parent);
      instance.show();
      this.$api.modifyProject(projectId, project)
        // eslint-disable-next-line consistent-return
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('项目修改成功');
            return this.$api.getProjectList({});
          }
          this.$Message.error('项目修改失败');
          return Promise.reject(new Error('项目修改失败'));
        })
        .then((response) => {
          console.dir(response);
          if (response) {
            this.project_list = [...response.data.projects];
            this.projectListCallback(true);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    createProjectModalCancelHandler() {
      this.project_info_modal_visible = false;
    },
    projectItemEditHandler(item) {
      // eslint-disable-next-line no-param-reassign
      item.edit_box_visible = false;
      this.project_info_modal_title = '编辑项目';
      this.project_info_modal_visible = true;
    },
    projectItemDeleteHandler(item) {
      // eslint-disable-next-line no-param-reassign
      item.edit_box_visible = false;
      this.confirm_modal_visible = true;
    },
    projectBlurHandler() {
      console.log('project blur');
      this.edit_box_visible = false;
      // this.selected_item = null;
      // this.selected_item_index = null;
    },
    showEditBox(item, index) {
      const editBoxElement = this.$refs.edit_box;
      // eslint-disable-next-line no-param-reassign
      this.edit_box_visible = true;
      this.selected_item = item;
      this.selected_item_index = index;
      editBoxElement.style.top = `${(index + 1) * 104 + 42}px`;
    },
    getArrowIcon(rate) {
      return rate > 0 ? icIncrease : icDecrease;
    },
    confirmHandler() {
      console.log('confirm');
      const projectId = this.selected_item.project_id;
      const params = {
        project_id: projectId,
      };
      const instance = this.$loading(this.parent);
      instance.show();
      this.$api.deleteProject(projectId, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('项目删除成功');
            return this.$api.getProjectList({});
          }
          return null;
        })
        .then((response) => {
          if (response) {
            this.project_list = [...response.data.projects];
            this.projectListCallback(true);
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          instance.hide();
        });
    },
    cancelHandler() {
      console.log('Confirm modal cancel');
      this.confirm_modal_visible = false;
    },
  },
  computed: {
    header_title() {
      return '项目列表';
    },
    header_button() {
      return '添加项目';
    },
    empty_list_title() {
      return '欢迎进入BI系统';
    },
    empty_list_description() {
      return '您还没有添加项目，点击右上角的按钮添加项目~';
    },
    empty_list_visible() {
      return this.project_list.length <= 0;
    },
    project_list_length() {
      return `（${this.project_list.length}）`;
    },
    parent() {
      return this.$refs.project_list;
    },
  },
  mounted() {
    // this.fetchProjectIDList();
    this.fetchProjectList();
  },
};
</script>
<style lang="less">
  @headerLineHeight: 36px;
  .header-search {
    .ivu-input, .ivu-icon {
      height: @headerLineHeight;
      line-height: @headerLineHeight;
    }
  }
</style>
<style scoped lang="less">
  /* Grid gap need modification */
  @borderRadius: 4px;
  @titleFontSize: 24px;
  @titleFontWeight: 600;
  @titleColor: #202444;
  @h2TitleFontSize: 16px;
  @h2TitleFontWeight: 600;
  @h2TitleColor: #202444;

  @paddingSide: 24px;
  @headerPadding: 24px @paddingSide;
  @headerHeight: 84px;
  @headerLineHeight: 36px;
  @headerSearchMargin: 0 16px 0 0;
  @projectListWrapPadding: @paddingSide;
  @projectListHeight: calc(~'100% - @{headerHeight}');
  @itemHeaderHeight: 62px;
  @itemHeaderBorderRadius: @borderRadius @borderRadius 0 0;
  @projectItemHeight: 104px;
  @projectItemMargin: 12px;
  @projectItemBgColor: #ffffff;
  @projectItemBorder: 1px solid #EAEBF7;
  @projectItemHoverBoxShadow: 0px 0px 16px 0px rgba(13,17,52,0.06);
  @lastProjectItemBorderRadius: 0 0 @borderRadius @borderRadius;
  @projectInfoLineHeight: 48px;
  @projectLogoWidth: 48px;
  @projectLogoHeight: 48px;
  @projectLogoMargin: 0 0 0 32px;
  @projectNameMargin: 0 0 0 16px;
  @operationLineHeight: 16px;
  @operationImageHeight: 16px;
  @operationImageMargin: 0 16px 0 0;
  @emptyListImgWidth: 504px;
  @editBoxWrapWidth: 60px;
  @editBoxBgColor: rgba(255, 255, 255, 0.8);
  @editBoxBorderRadius: 6px;

  @positiveColor: #05b388;
  @negativeColor: #f94d71;
  /* Mixins */
  .title {
    font-size: @titleFontSize;
    font-weight: @titleFontWeight;
    color: @titleColor;
  }
  .h2Title {
    font-size: @h2TitleFontSize;
    font-weight: @h2TitleFontWeight;
    color: @h2TitleColor;
    line-height: 22px;
  }
  .project-list {
    position: relative;
    width: 100%;
    height: 100%;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: @headerHeight;
      padding: @headerPadding;
      line-height: @headerLineHeight;
      .header-title {
        .title();
      }
      .header-operation {
        display: flex;
        flex-direction: row;
        align-items: center;
        .header-search {
          height: @headerLineHeight;
          margin: @headerSearchMargin;
        }
        .header-button {
          img {
            width: 16px;
            margin-right: 4px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
          img, span {
            vertical-align: middle;
          }
        }
      }
    }
    .project-list-wrap {
      height: @projectListHeight;
      padding: 0 @projectListWrapPadding;
      .project-list {
        height: 100%;
        .item-header {
          border-radius: @itemHeaderBorderRadius;
          border-top: @projectItemBorder;
          box-shadow: 0 0 0 0!important;
        }
        .project-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: @projectItemHeight;
          background-color: @projectItemBgColor;
          border-left: @projectItemBorder;
          border-bottom: @projectItemBorder;
          border-right: @projectItemBorder;
          &:hover {
            position: relative;
            box-shadow: 0 0px 4px #e6e6e6;
            z-index: 99;
          }
          &:last-child {
            border-radius: @lastProjectItemBorderRadius;
          }
          .info, .column {
            flex: 0 0 20%;
          }
          .info:first-child, .column:first-child {
            flex-basis: 21.6%;
          }
          .column {
            font-size: 16px;
            font-weight: 600;
            text-align: right;
            &:first-child {
              text-align: left;
              padding-left: 32px;
            }
          }
          .operation {
            position: relative;
            flex: 1 0 auto;
            line-height: @operationLineHeight;
            text-align: right;
            .operation-img {
              height: @operationImageHeight;
              margin: @operationImageMargin;
              cursor: pointer;
            }
          }
          .project-info {
            line-height: @projectInfoLineHeight;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            .project-logo {
              width: @projectLogoWidth;
              height: @projectLogoHeight;
              margin: @projectLogoMargin;
              border-radius: 12px;
            }
            .project-name {
              display: inline-block;
              margin: @projectNameMargin;
              .h2Title();
            }
            .project-logo, .project-name {
              vertical-align: middle;
            }
          }
          .total-user-info, .active-user-info, .new-user-info {
            display: none;
            text-align: right;
          }
          .total-user-info {
            .total-user-number {
              .title();
            }
            .total-user-rate {
              line-height: 20px;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #878AA2;
                vertical-align: middle;
              }
              .rate-text {
                margin-left: 4px;
              }
              .positive-rate {
                color: @positiveColor;
              }
              .negative-rate {
                color: @negativeColor;
              }
              img {
                height: 16px;
                margin-left: 4px;
                vertical-align: middle;
              }
            }
          }
          .active-user-info {
            .active-user-number {
              .title();
            }
            .active-user-rate {
              line-height: 20px;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #878AA2;
                vertical-align: middle;
              }
              .rate-text {
                margin-left: 4px;
              }
              .positive-rate {
                color: @positiveColor;
              }
              .negative-rate {
                color: @negativeColor;
              }
              img {
                height: 16px;
                margin-left: 4px;
                vertical-align: middle;
              }
            }
          }
          .new-user-info {
            .new-user-number {
              .title();
            }
            .new-user-rate {
              line-height: 20px;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #878AA2;
                vertical-align: middle;
              }
              .rate-text {
                margin-left: 4px;
                vertical-align: middle;
              }
              .positive-rate {
                color: @positiveColor;
              }
              .negative-rate {
                color: @negativeColor;
              }
              img {
                height: 16px;
                margin-left: 4px;
                vertical-align: middle;
              }
            }
          }
        }
        .item-header {
          height: @itemHeaderHeight;
        }
      }
    }
    .empty-list-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .empty-list-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-list-img {
          width: @emptyListImgWidth;
        }
        .empty-list-title {
          .title();
          margin: 24px 0 8px;
        }
        .empty-list-description {
          font-size: 14px;
          font-weight: 400;
          color: #878AA2;
          line-height: 20px;
        }
      }
    }
    .edit-box-wrap {
      position: absolute;
      right: 42px;
      transform: translateY(100%);
      width: @editBoxWrapWidth;
      background-color: @editBoxBgColor;
      border-radius: @editBoxBorderRadius;
      box-shadow: 0px 2px 8px 0px rgba(13,17,52,0.08);
      overflow: hidden;
      z-index: 100;
    }
  }
</style>

export default {
  methods: {
    getMarkDateRange(chartData) {
      const xAxisArr = chartData?.x_axis || this.chart_data.x_axis;
      const dateStart = this.filter.time[0];
      const dateRange = this.$tools.getMarkDateRange(xAxisArr, dateStart);
      return dateRange;
    },
    async markListRefreshHandler(xAxisFlag, drawChartFnList, chartData) {
      console.log('Mixin, markListRefreshHandler');
      console.log(chartData);
      const dateRange = this.getMarkDateRange(chartData);
      try {
        const markListResponse = await this.$store.dispatch('fetchMarkList', {
          dateRange,
          getMarkList: this.$api.getMarkList,
        });
        this.markListCallback(markListResponse);
        this.configMarkLineData(xAxisFlag, chartData);
        if (drawChartFnList) {
          drawChartFnList.forEach((fn) => {
            fn();
          });
        } else {
          this.drawChart();
        }
      } catch (error) {
        this.$Message.error(error.message);
      }
    },
    markListCallback(response) {
      let { marks } = response.data;
      if (marks) {
        console.log('marks:');
        console.log(marks);
        marks = marks.map((val) => {
          const date = new Date(val.date);
          // eslint-disable-next-line no-param-reassign
          val.date = date;
          return val;
        });
        this.$store.commit('setMarkList', {
          markList: marks,
        });
      }
    },
    configMarkLineData(flag, chartData) {
      const markList = this.$store.state.mark_list;
      console.log('mark_list in store');
      console.log(markList);
      const markLines = [];
      const xAxisArr = chartData?.x_axis || this.chart_data.x_axis;
      const dateRange = this.filter.time;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of markList) {
        const temp = {
          text: item.content,
          xAxis: this.$tools.getMarkDate(xAxisArr, item.date, dateRange, flag),
        };
        markLines.push(temp);
      }
      if (chartData) {
        // eslint-disable-next-line no-param-reassign
        chartData.mark_lines = markLines;
      } else {
        this.chart_data.mark_lines = markLines;
      }
    },
  },
};

export default {
  data() {
    return {
      filter: {
        time: [],
        country: [],
        country_list_exclude: 0,
        network: [],
        campaign: [],
      },
      country_list: [],
      network_list: [],
      campaign_list: [],
    };
  },
  methods: {
    initTimeFilter() {
      const today = new Date();
      const endDate = new Date(today.getTime() - 3600 * 1000 * 24 * 1);
      const startDate = new Date(endDate.getTime() - 3600 * 1000 * 24 * 7);
      this.filter.time = [startDate, endDate];
    },
    fetchCountryList() {
      const params = {};
      this.$api.getKeepCountryList(this.project_id, { params })
        .then((response) => {
          const { countries } = response.data;
          this.country_list = [...countries];
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    fetchNetworkList() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
      };
      this.$api.getNetworkList(this.project_id, params)
        .then((response) => {
          const { networks } = response.data;
          this.network_list = [...networks];
          this.network_list.forEach((val) => {
            // eslint-disable-next-line no-param-reassign
            val.label = val.network;
            // eslint-disable-next-line no-param-reassign
            val.value = val.network;
          });
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    fetchCampaignList() {
      const params = {
        date_start: this.$tools.dateFormat(this.filter.time[0]),
        date_end: this.$tools.dateFormat(this.filter.time[1]),
        network_list: this.filter.network,
      };
      this.$api.getCampaignList(this.project_id, params)
        .then((response) => {
          const { result, campaigns } = response.data;
          if (result === 'success') {
            campaigns.forEach((val) => {
              if (val.campaign === null) {
                // eslint-disable-next-line no-param-reassign
                val.campaign = 'null';
              }
            });
            this.campaign_list = [...campaigns];
            this.campaign_list.forEach((val) => {
              // eslint-disable-next-line no-param-reassign
              val.label = val.campaign;
              // eslint-disable-next-line no-param-reassign
              val.value = val.campaign;
            });
          }
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    fetchFilterList() {
      this.fetchCountryList();
      this.fetchNetworkList();
      this.fetchCampaignList();
    },
    /**
     * country in filter changes
     * @param checkGroup {Array}
     */
    countryChangeHandler(checkedGroup) {
      console.table(checkedGroup);
      this.filter.country = [...checkedGroup];
    },
    /**
     * country option in filter changes
     * @param checkedOption
     */
    countryOptionChangeHandler(checkedOption) {
      console.log(`checkedOption: ${checkedOption}`);
      this.filter.country_exclude = checkedOption;
    },
    networkChangeHandler(checkGroup) {
      this.filter.network = [...checkGroup];
    },
    campaignChangeHandler(checkGroup) {
      this.filter.campaign = [...checkGroup];
    },
  },
};

<template>
  <div class="header">
    <div class="header-title-wrap left-box">
      <img class="return-icon"
           src="../assets/image/ic_return@2x.png"
           alt="Return icon"
           v-if="back"
           @click="routerBack">
      <h1 class="header-title">{{ header_title }}</h1>
      <slot name="test-filter"></slot>
    </div>
    <slot>
      <div class="right-box">
        <span class="mark-wrap">
          <Mark
            @on-create-succeed="markCreateSucceedHandler"
            @on-edit-succeed="markEditSucceedHandler"
            @on-delete-succeed="markDeleteSucceedHandler"></Mark>
        </span>
        <img class="header-filter-img" src="../assets/image/ic_filter.png" alt="Filter Icon">
      </div>
    </slot>
  </div>
</template>

<script>
import Mark from './Mark.vue';

export default {
  name: 'ContentHeader',
  components: { Mark },
  props: {
    header_title: String,
    back: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    markCreateSucceedHandler() {
      this.$emit('on-mark-create-succeed');
    },
    markEditSucceedHandler() {
      this.$emit('on-mark-edit-succeed');
    },
    markDeleteSucceedHandler() {
      this.$emit('on-mark-delete-succeed');
    },
    routerBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
  @import '../style/util.less';
  @headerTitleMargin: 0 12px 0 0;
  @headerFilterImageHeight: 20px;
  @headerLineHeight: 24px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-title-wrap {
      line-height: @headerLineHeight;
      img, div, h1 {
        vertical-align: middle;
      }
      .return-icon {
        height: 24px;
        margin-right: 12px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
          transform: translateX(-4px);
        }
      }
      .header-title {
        display: inline-block;
        margin: @headerTitleMargin;
        .title();
      }
    }
    .right-box {
      line-height: 20px;
      & > * {
        vertical-align: middle;
      }
      .mark-wrap {
        margin-right: 12px;
        cursor: pointer;
      }
      .header-filter-img {
        height: @headerFilterImageHeight;
      }
    }
  }
</style>

<template>
  <Modal
      class="modal"
      :value="modal_visible"
      :closable="false"
      width="400"
      @on-ok="okHandler"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
    </div>
    <div class="main">
<!--      <div class="input-item">-->
<!--        <p class="input-title">项目ID</p>-->
<!--        <Input class="input-text" v-model="project.project_id" placeholder="请输入"/>-->
<!--      </div>-->
      <div class="input-item">
        <p class="input-title">项目ID</p>
        <Select v-if="select_visible"
                class="input-select"
                v-model="project.project_id"
                :disabled="!is_creation">
          <Option v-for="item in project_id_list"
                  :key="item.project_id"
                  :value="item.project_id"
                  :label="`${item.project_id} (${item.project_name})`">
          </Option>
        </Select>
      </div>
      <div class="input-item">
        <p class="input-title">项目名称</p>
        <Input class="input-text" v-model="project.project_name" placeholder="请输入"/>
      </div>
      <div class="input-item">
        <p class="input-title">项目LOGO</p>
        <div class="file-box"
             ref="file_box"
             @click="triggerFile">
          <div v-if="!project.project_logo_url" class="message">
            <img src="../../assets/image/ic_add_project@2x.png" alt="Add project">
            <span>点击或拖拽</span>
          </div>
          <img v-if="project.project_logo_url"
               class="logo-image"
               :src="project.project_logo_url"
               alt="Logo url">
        </div>
        <!-- The problem of type converting between file and path string -->
        <input type="file"
               accept="image/*"
               style="display: none"
               ref="input_file"
               @change="imageFileChangeHandler($event)"/>
      </div>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>

import dragUpload from '../../utils/drag';
import toolFunctions from '../../utils/toolFunctions';

export default {
  name: 'CreateProjectModal',
  props: {
    modal_visible: Boolean,
    title: {
      type: String,
      default: '添加项目',
    },
    item: Object,
    project_id_list: Array,
  },
  data() {
    return {
      project: {
        project_id: null,
        project_name: null,
        project_logo: null,
        project_logo_url: null,
      },
      old_project: {
        project_id: null,
        project_name: null,
        project_logo: null,
        project_logo_url: null,
      },
      is_creation: true,
      image_url: null,
      select_visible: true,
    };
  },
  methods: {
    // Lack input type
    triggerFile() {
      const inputFile = this.$refs.input_file;
      inputFile.click();
    },
    imageFileChangeHandler(evt) {
      console.log(evt.target.value);
      console.log(typeof evt.target.value);
      const file = this.$refs.input_file.files[0];
      console.dir(file);
      this.project.project_logo = file;
      this.project.project_logo_url = toolFunctions.imageFileToURL(this.project.project_logo);
    },
    judgeNull() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(this.project)) {
        const value = this.project[key];
        if ((value === null || value === '') && key !== 'project_logo') {
          return true;
        }
      }
      return false;
    },
    okHandler() {
      if (this.judgeNull()) {
        this.$Message.warning('输入不能为空');
        return;
      }
      const obj = {
        ...this.project,
      };
      delete obj.project_logo_url;
      const form = new FormData();
      form.append('project_id', obj.project_id);
      form.append('project_name', obj.project_name);
      form.append('project_logo', obj.project_logo);
      if (this.is_creation) {
        this.$emit('create', form);
      } else {
        // Need to judge whether the project object is changed or not
        // eslint-disable-next-line no-lonely-if
        if (this.$tools.isModified(this.project, this.old_project)) {
          this.$emit('modify', form, obj.project_id);
        } else {
          this.$Message.warning('没有更改');
        }
      }
      this.cancelHandler();
    },
    cancelHandler() {
      Object.assign(this.$data, this.$options.data());
      this.$emit('cancel');
    },
  },
  computed: {
    header_title() {
      return this.title;
    },
  },
  watch: {
    modal_visible(newVal) {
      if (newVal) {
        // show
        this.is_creation = this.$tools.isNullObj(this.item);
        if (!this.is_creation) {
          // this.old_project = { ...this.item };
          // this.project = { ...this.item };
          // eslint-disable-next-line no-restricted-syntax
          for (const key of Object.keys(this.old_project)) {
            this.old_project[key] = this.item[key];
            this.project[key] = this.item[key];
          }
          this.old_project.project_logo_url = `https://dc-bi-center.herokuapp.com/${this.old_project.project_logo_url}`;
          this.project.project_logo_url = `https://dc-bi-center.herokuapp.com/${this.project.project_logo_url}`;
        }
      }
    },
  },
  mounted() {
    // Arrow function doesn't have this param of its own
    const fn = (file, url) => {
      this.project.project_logo = file;
      this.project.project_logo_url = url;
    };
    const fileBox = this.$refs.file_box;
    dragUpload(fileBox, fn);
  },
};
</script>

<style lang="less">
  @import "../../style/modal.less";
  @inputHeight: 36px;
  @inputBgColor: #FAFBFF;
  @inputBorder: 1px solid #D5D7E6;
  @inputPadding: 8px 16px;
  @inputFontSize: 14px;
  @inputFontWeight: 400;
  @inputColor: #202444;
  @inputLineHeight: 20px;
  /* .ivu-input need modification */
  .modal {
    .ivu-input, .ivu-select-selection {
      height: @inputHeight;
      background-color: @inputBgColor;
      border: @inputBorder;
      padding: @inputPadding;
      font-size: @inputFontSize;
      font-weight: @inputFontWeight;
      color: @inputColor;
      line-height: @inputLineHeight;
    }
    .ivu-select-selection {
      padding: 0 @inputPadding;
      .ivu-select-placeholder {
        height: @inputHeight;
        line-height: @inputHeight;
      }
    }
  }
</style>
<style scoped lang="less">
  @import url('../../style/global.less');
  @paddingSide: 32px;
  @headerVerticalPadding: 20px;
  @headerPadding: @headerVerticalPadding @paddingSide;
  @footerVerticalPadding: 24px;
  @footerPadding: @footerVerticalPadding @paddingSide;
  @inputTitleMarginBottom: 6px;
  @inputTextMarginBottom: 25px;
  @fileBoxWidth: 96px;
  @fileBoxHeight: @fileBoxWidth;
  @fileBoxBorder: 1px dashed #D5D7E6;
  @fileBoxBgColor: #FAFBFF;
  @fileBoxRadius: @borderRadius;
  @messageTextMarginBottom: 9px;
  .title {
    font-size: 12px;
    font-weight: 400;
    color: #545778;
    line-height: 17px;
  }
  .message-text {
    font-size: 14px;
    font-weight: 400;
    color: #BBBCC9;
    line-height: 20px;
  }
  .modal {
    .header {
      padding: @headerPadding;
      .header-title {
        font-size: 20px;
        font-weight: 600;
        color: #202444;
        line-height: 28px;
      }
    }
    .main {
      padding: 0 @paddingSide;
      .input-item {
        display: flex;
        flex-direction: column;
        .input-title {
          .title();
          margin-bottom: @inputTitleMarginBottom;
        }
        .input-text, .input-select {
          margin-bottom: @inputTextMarginBottom;
        }
        .file-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: @fileBoxWidth;
          height: @fileBoxHeight;
          border: @fileBoxBorder;
          border-radius: @fileBoxRadius;
          background-color: @fileBoxBgColor;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          &:hover {
            border-color: rgba(75, 92, 240, 1);
            background: #EBEFFF;
          }
          .message {
            text-align: center;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              display: block;
              .message-text();
            }
            span:first-child {
              font-size: 30px;
              margin-bottom: @messageTextMarginBottom;
            }
          }
          .logo-image {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }
    }
    .footer {
      padding: @footerPadding;
    }
  }
</style>

<template>
  <Modal
      class="edit-mark-modal"
      :value="modal_visible"
      :closable="false"
      width="400"
      @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
    </div>
    <div v-if="modal_visible" class="main">
      <div class="search-box">
        <Input
          search
          placeholder="搜索"
          v-model="search_content"
          @on-enter="searchMark"/>
      </div>
      <div class="mark-list-box">
        <h2 class="list-title">{{ list_title }}</h2>
        <div class="empty-list-wrap" v-show="!item_edit_mode && display_mark_list.length === 0">
          <div class="empty-list-container">
            <Icon class="empty-icon" size="36" type="ios-folder-open-outline" color="#e1e1e1"/>
            <p class="empty-text">列表为空~</p>
          </div>
        </div>
        <ul v-show="!item_edit_mode && display_mark_list.length !== 0" class="mark-list">
          <li
            class="mark-item"
            v-for="item in display_mark_list"
            :key="item.id"
            @click="selectMarkItem(item)">
            <div class="left-box">
              <span class="date">{{ $tools.dateFormat(item.date) }}</span>
              <span class="content">{{ item.content }}</span>
            </div>
            <div class="right-box">
              <Icon type="ios-arrow-forward" size="24"/>
            </div>
          </li>
        </ul>
        <div v-show="item_edit_mode" class="mark-edit-box">
          <mark-input
            :mark="selected_mark"></mark-input>
          <div class="btn-box">
            <div class="left-box">
              <Button
                class="btn"
                type="error"
                :disabled="edit_button_loading || delete_button_loading"
                @click="deleteHandler">删除</Button>
            </div>
            <div class="right-box">
              <Button
                class="btn"
                type="default"
                :disabled="edit_button_loading || delete_button_loading"
                @click="backToMarkList">取消</Button>
<!--              <Button-->
<!--                class="btn"-->
<!--                type="primary"-->
<!--                @click="saveHandler">保存</Button>-->
              <LoadingButton
                :show_loading="edit_button_loading"
                :button_loading="edit_button_loading || delete_button_loading"
                type="primary"
                text="保存"
                @on-click="saveHandler"
                ></LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" slot="footer">
      <div>
        <Button
          class="footer-btn"
          type="default"
          :disabled="edit_button_loading || delete_button_loading"
          @click="cancelHandler">关闭</Button>
      </div>
    </div>
    <confirm-modal
      :modal_visible="confirm_modal_visible"
      :confirm_message="confirm_message"
      @confirm="deleteConfirmHandler"
      @cancel="deleteCancelHandler"></confirm-modal>
  </Modal>
</template>

<script>
import MarkInput from '../MarkInput.vue';
import ConfirmModal from './ConfirmModal.vue';
import LoadingButton from '../LoadingButton.vue';

export default {
  name: 'EditMarkModal',
  props: {
    modal_visible: Boolean,
  },
  components: { LoadingButton, ConfirmModal, MarkInput },
  data() {
    return {
      delete_button_loading: false,
      edit_button_loading: false,
      header_title: '编辑所有标注',
      list_title: '所有标注',
      confirm_modal_visible: false,
      confirm_message: '确认删除该标注吗？',
      item_edit_mode: false,
      search_content: '',
      mark_list: [],
      display_mark_list: [],
      selected_mark: {
        id: null,
        date: null,
        type: null,
        content: null,
        visible_for_everyone: false,
      },
      old_selected_mark: null,
    };
  },
  methods: {
    searchMark() {
      const searchContent = this.search_content;
      if (searchContent === '') {
        this.display_mark_list = [...this.mark_list];
      } else {
        this.display_mark_list = this.mark_list.filter((val) => {
          const { date, content } = val;
          const formatDate = this.$tools.dateFormat(date);
          if (formatDate === searchContent) return true;
          if (content.indexOf(searchContent) !== -1) return true;
          return false;
        });
      }
    },
    selectMarkItem(item) {
      this.selected_mark = item;
      this.old_selected_mark = { ...item };
      this.item_edit_mode = true;
    },
    deleteHandler() {
      this.confirm_modal_visible = true;
    },
    deleteConfirmHandler() {
      this.delete_button_loading = true;
      const params = {
        user_id: this.user_id,
      };
      this.$api.deleteMark(this.project_id, this.selected_mark.id, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('删除成功');
            this.$emit('on-delete');
            this.backToMarkList();
            return Promise.resolve();
          }
          return Promise.reject(new Error('删除失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          this.delete_button_loading = false;
        });
    },
    deleteCancelHandler() {
      this.confirm_modal_visible = false;
    },
    backToMarkList() {
      this.selected_mark = {
        id: null,
        date: null,
        type: null,
        content: null,
        visible_for_everyone: false,
      };
      this.old_selected_mark = null;
      this.item_edit_mode = false;
    },
    check() {
      const mark = this.selected_mark;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(mark).filter((item) => item !== 'visible_for_everyone')) {
        const val = mark[key];
        if (!val) return false;
      }
      return true;
    },
    saveHandler() {
      if (!this.check()) {
        this.$Message.warning('输入不能为空');
        return;
      }
      const mark = { ...this.selected_mark };
      const oldMark = { ...this.old_selected_mark };
      if (!this.$tools.isModified(mark, oldMark)) {
        this.$Message.warning('无更改');
        return;
      }
      mark.date = this.$tools.dateFormat(mark.date);
      const params = {
        mark,
        user_id: this.user_id,
      };
      this.edit_button_loading = true;
      // Send request
      this.$api.editMark(this.project_id, mark.id, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('标注修改成功');
            this.$emit('on-modify');
            this.backToMarkList();
            return Promise.resolve();
          }
          return Promise.reject(new Error('标注修改失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          this.edit_button_loading = false;
        });
    },
    cancelHandler() {
      this.$emit('on-cancel');
    },
  },
  watch: {
    '$store.state.mark_list': function (newVal) {
      this.mark_list = [...newVal];
      this.display_mark_list = [...newVal];
      // eslint-disable-next-line prefer-destructuring
      // this.selected_mark = newVal[0];
      // this.old_selected_mark = { ...newVal[0] };
    },
  },
  computed: {
    user_id() {
      return this.$store.state.user_id;
    },
    project_id() {
      return this.$route.params.project_id;
    },
  },
  created() {},
};
</script>
<style lang="less">
  @inputHeight: 36px;
  .edit-mark-modal {
    .main {
      .search-box {
        .ivu-input {
          height: @inputHeight;
        }
        .ivu-input-icon {
          line-height: @inputHeight;
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .edit-mark-modal {
    .header {
      padding: 20px 32px;
      .header-title {
        font-size: 20px;
        font-weight: 600;
        color: #202444;
        line-height: 28px;
      }
    }
    .main {
      padding: 0px 32px 20px;
      .search-box {
        margin-bottom: 12px;
      }
      .mark-list-box {
        border-radius: 4px;
        background-color: #f8f8f8;
        .list-title {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
        .empty-list-wrap {
          position: relative;
          height: 72px;
          .empty-list-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            .empty-icon {
              margin-bottom: 6px;
            }
            .empty-text {
              font-size: 14px;
              font-weight: 400;
              color: #c1c1c1;
            }
          }
        }
        .mark-list {
          .mark-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 6px 12px;
            cursor: pointer;
            .left-box {
              flex-basis: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .date, .content {
                margin-right: 8px;
                font-size: 16px;
                font-weight: 400;
                color: #545778;
                line-height: 24px;
              }
            }
            .right-box {}
            &:hover {
              background-color: #f1f1f1;
            }
          }
        }
        .mark-edit-box {
          padding: 6px 12px;
          .btn-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: right;
            .right-box {
              .btn:first-child {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .footer {
      padding: 0px 32px 20px;
    }
  }
</style>

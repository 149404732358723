<template>
  <div class="heat-map-filter-box">
    <div class="select-box"
         v-if="filter_visible.dimension">
      <Select class="dimension-select"
              placeholder="维度划分 - 网络"
              v-model="filter.dimension"
              :disabled="dimension_disabled"
              @on-change="dimensionChangeHandler">
        <Option v-for="item in options"
                :key="item.value"
                :value="item.value"
                :label="item.label"/>
      </Select>
    </div>
    <div class="select-box"
         :class="{'single-select': !filter_visible.dimension}"
         v-if="filter_visible.show_number">
      <Checkbox
        v-model="filter.show_number"
        size="large"
        @on-change="showNumberChangeHandler">显示人数</Checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatMapFilterBox',
  props: {
    filter: Object,
    filter_visible: {
      type: Object,
      default() {
        return {
          dimension: true,
          show_number: true,
        };
      },
    },
    dimension_disabled: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      options: [
        {
          value: 'network',
          label: '维度划分 - 网络',
        },
        {
          value: 'campaign',
          label: '维度划分 - 广告系列',
        },
        {
          value: 'app_version',
          label: '维度划分 - 版本号',
        },
        {
          value: 'medium',
          label: '维度划分 - 媒介',
        },
        {
          value: 'os_version',
          label: '维度划分 - 操作系统',
        },
      ],
    };
  },
  methods: {
    dimensionChangeHandler(newVal) {
      this.$emit('on-dimension-change', newVal);
    },
    showNumberChangeHandler(newVal) {
      this.$emit('on-show-number-change', newVal);
    },
  },
};
</script>

<style scoped lang="less">
  .heat-map-filter-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .select-box {
      margin: 0 2% 12px 0;
    }
    .select-box:first-child {
      width: 160px;
      margin-right: 0;
    }
    .single-select {
      width: auto!important;
    }
  }
</style>

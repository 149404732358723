<template>
  <div class="sidebar">
    <div class="header-wrap">
      <img v-show="is_expanded"
           class="logo"
           :src="expanded_logo"
           alt="BI Logo"
           @click="returnToProjectList">
      <img v-show="!is_expanded"
           class="unexpanded-logo"
           :src="unexpanded_logo"
           alt="BI Logo"
           @click="returnToProjectList">
    </div>
    <div class="sidebar-menu-wrap">
      <router-view name="sidebar"/>
    </div>
    <div class="sidebar-bottom-wrap">
      <img :class="{'expanded-icon': is_expanded, 'unexpanded-icon': !is_expanded}"
           src="../../../assets/image/ic_sidebar_expand@2x.png"
           alt="Expand"
           @click="stretch">
      <img class="config-icon"
           v-show="is_expanded && false"
           src="../../../assets/image/icon-config.png"
           alt="Configuration">
    </div>
  </div>
</template>

<script>
import isExpandedMixin from './isExpandedMixin';
import expandedLogo from '../../../assets/image/logo@2x.png';
import unexpandedLogo from '../../../assets/svg/logo_bi.svg';

export default {
  name: 'Sidebar',
  mixins: [isExpandedMixin],
  data() {
    return {
      expanded_logo: expandedLogo,
      unexpanded_logo: unexpandedLogo,
    };
  },
  methods: {
    returnToProjectList() {
      const route = {
        path: '/',
      };
      this.$router.push(route);
    },
    stretch() {
      const isExpanded = !this.$store.state.sidebar_is_expanded;
      this.$store.commit('setSidebarIsExpanded', {
        sidebar_is_expanded: isExpanded,
      });
    },
  },
};
</script>

<style scoped lang="less">
  @sidebarWidth: 100%;
  @sidebarHeight: 100%;
  @headerWrapHeight: 48px;
  @headerWrapLineHeight: 48px;
  @logoHeight: 32px;
  @menuWrapMarginTop: 4px;
  @bottomHeight: 40px;
  @bottomPadding: 0 16px;
  @border: 1px solid #201E29;
  .sidebar {
    position: relative;
    width: @sidebarWidth;
    height: @sidebarHeight;
    .header-wrap {
      height: @headerWrapHeight;
      line-height: @headerWrapLineHeight;
      box-shadow: 0px -1px 0px 0px rgba(32,30,41,1);
      border-bottom: @border;
      text-align: center;
      .logo {
        height: @logoHeight;
        vertical-align: middle;
        cursor: pointer;
      }
      .unexpanded-logo {
        width: 24px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
    .sidebar-menu-wrap {
      margin-top: @menuWrapMarginTop;
    }
    .sidebar-bottom-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: @bottomHeight;
      padding: @bottomPadding;
      border-top: @border;
      img {
        cursor: pointer;
        transform-origin: center;
        /*transition: 0.3s ease-in-out;*/
      }
      .expanded-icon, .unexpanded-icon, .config-icon {
        width: 16px;
        height: 16px;
      }
      .expand-icon {}
      .unexpanded-icon {
        transform: rotate(180deg);
      }
      .config-icon {
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
  }
</style>

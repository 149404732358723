<template>
  <div class="home">
    <div class="sidebar-wrap"
         :class="{'unexpanded-sidebar-wrap': !is_expanded}">
      <sidebar></sidebar>
    </div>
    <div class="main-wrap"
         :class="{'unexpanded-main-wrap': !is_expanded}">
      <div class="top-wrap">
        <top></top>
      </div>
      <div class="content-wrap">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './sidebar/Sidebar.vue';
import Top from './top/Top.vue';
import isExpandedMixin from './sidebar/isExpandedMixin';

export default {
  name: 'Home',
  components: { Top, Sidebar },
  mixins: [isExpandedMixin],
};
</script>
<style lang="less">
  @homeWidth: 100%;
  @homeHeight: 100%;
  @homeBgColor: rgba(247, 249, 252, 1);
  @sidebarWidth: 207px;
  @unexpandedSidebarWidth: 60px;
  @sidebarHeight: @homeHeight;
  @sidebarBgColor: #32333A;
  @mainWidth: calc(~'100% - @{sidebarWidth}');
  @mainHeight: @homeHeight;
  @unexpandedMainWidth: calc(~'100% - @{unexpandedSidebarWidth}');
  @topHeight: 48px;
  @topBgColor: #ffffff;
  @contentHeight: calc(~'100% - @{topHeight}');
  @contentPadding: 0 17px 0 0;
  .home {
    display: flex;
    flex-direction: row;
    width: @homeWidth;
    min-width: 1240px;
    height: @homeHeight;
    background-color: @homeBgColor;
    .sidebar-wrap {
      flex: 0 0 auto;
      width: @sidebarWidth;
      height: @sidebarHeight;
      background-color: @sidebarBgColor;
      box-shadow: 2px 0px 6px 0px rgba(13,17,52,0.35);
      transition: width 0.2s ease-in-out;
    }
    .unexpanded-sidebar-wrap {
      width: @unexpandedSidebarWidth;
    }
    .main-wrap {
      flex: 0 0 auto;
      width: @mainWidth;
      height: @mainHeight;
      transition: width 0.2s ease-in-out;
      .top-wrap {
        position: relative;
        height: @topHeight;
        background-color: @topBgColor;
        box-shadow: 0px 1px 4px 0px rgba(13,17,52,0.12);
        z-index: 12;
      }
      .content-wrap {
        width: 100%;
        height: @contentHeight;
        overflow: auto;
      }
    }
    .unexpanded-main-wrap {
      width: @unexpandedMainWidth;
    }
  }
</style>
